import React, { useState, useEffect } from "react"
import { connect, useDispatch } from "react-redux";
import { Row, Col } from "reactstrap";
import Select from "react-select";
import moment from "moment";

import ModalFilter from "./ModalFilter";
import Table from "../../../components/table";
import Button from "../../../components/buttons/Button";
import Card from "../../../components/cards/card";
import CardHeader from "../../../components/cards/cardHeader";
import ProductionCard from "../../../components/cards/ProductionCard";
import DatePicker from "../../../components/forms/DateRangePicker";
import { withTrans } from "../../../i18n/withTrans";
import { numberWithCommas, reactSelectCustomStyles } from "../../../utils/Constants";

import MasterLoungeService from "../../../store/actions/master_data/lounge";
import ReportService from "../../../store/actions/report/";

const ReportCommission = ({ t, pending, user, airports }) => {
  const dispatch = useDispatch();
  const [dataLounges, setDataLounges] = useState([]);
  const [searchLounge, setSearchLounge] = useState("");
  const [dataReport, setdataReport] = useState([]);
  const [totalData, setTotalData] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [totalReservation, setTotalReservation] = useState(0);
  const [totalReservationJoumpa, setTotalReservationJoumpa] = useState(0);
  const [totalReservationLounge, setTotalReservationLounge] = useState(0);
  const [totalPax, setTotalPax] = useState(0);
  const [totalCommission, setTotalCommission] = useState(0);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [paramFilter, setParamFilter] = useState({
    end_date: moment().format("YYYY-MM-DD"),
    start_date: moment().format("YYYY-MM-DD"),
    airport_id: "",
    lounge_id: "",
    booking_type: "",
    report_type: true,
  });
  const [filterCommissionSummary, setFilterCommissionSumary] = useState({
    end_date: moment().format("YYYY-MM-DD"),
    start_date: moment().format("YYYY-MM-DD"),
    airport_id: "",
  });

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchLounge,
      };
      dispatch(MasterLoungeService.get(param, resolve));
    }).then((res) => {
      if (res) {
        setDataLounges(
          res?.data?.map((item) => ({
            label: item?.name,
            value: item?.id
          }))
        )
      }
    });
  }, [searchLounge]);

  const handleFilterModal = () => {
    setShowModalFilter(true);
  };

  const onApplyFilter = (param) => {
    setParamFilter({
      airport_id: param.airport_id,
      lounge_id: param.lounge_id,
      start_date: param.start_date,
      end_date: param.end_date,
      booking_type: param.booking_type,
      report_type: param.report_type,
    });
  };

  const handleRefresh = () => {
    new Promise((resolve) => {
      const param = {
        ...paramFilter,
        page: page === 0 ? 1 : page ?? 1,
        length: 10,
      };
      dispatch(ReportService.getCommissionLounge(param, resolve))
    }).then((res) => {
      setdataReport(res?.data);
      setTotalData(res?.totalCount);
      setMaxPage(Math.ceil(res?.totalCount / 10));
    });
  };

  useEffect(() => {
    handleRefresh();
  }, [page, paramFilter]);

  useEffect(() => {
    setPage((prev) => (prev === null ? null : 1));
  }, [paramFilter]);

  const handleSummary = () => {
    new Promise((resolve) => {
      const param = {
        airport_id: filterCommissionSummary?.airport_id,
        start_date: filterCommissionSummary?.start_date,
        end_date: filterCommissionSummary?.end_date,
      };
      dispatch(ReportService.getResumeCommissionLounge(param, resolve));
    }).then((res) => {
      const data = res.data;
      if (data) {
        setTotalReservation(
          data?.filter((item) => item.field === "total_reservation")?.length > 0
            ? data?.filter((item) => item.field === "total_reservation")[0]?.value
            : 0
        );
        setTotalReservationJoumpa(
          data?.filter((item) => item.field === "total_reservation_add_on_joumpa")?.length > 0
            ? data?.filter((item) => item.field === "total_reservation_add_on_joumpa")[0]?.value
            : 0
        );
        setTotalReservationLounge(
          data?.filter((item) => item.field === "total_reservation_lounge")?.length > 0
            ? data?.filter((item) => item.field === "total_reservation_lounge")[0]?.value
            : 0
        );
        setTotalPax(
          data?.filter((item) => item.field === "total_pax")?.length > 0
            ? data?.filter((item) => item.field === "total_pax")[0]?.value
            : 0
        );
        setTotalCommission(
          data?.filter((item) => item.field === "total_commission")?.length > 0
            ? `Rp ${numberWithCommas(
              data?.filter((item) => item.field === "total_commission")[0]?.value
            )}`
            : 'Rp 0'
        );
      }
    });
  };

  useEffect(() => {
    handleSummary();
  }, [filterCommissionSummary]);

  const handleDownloadReport = () => {
    new Promise((resolve) => {
      const filterDownloadCommission = {
        ...paramFilter,
        export_excel: true,
      }
      setLoadingDownload(true);
      dispatch(ReportService.downloadCommissionLounge(filterDownloadCommission, resolve))
    }).then((res) => {
      setLoadingDownload(false);
    });
  };

  const column = [
    {
      Header: "NO",
      accessor: "no",
    },
    {
      Header: "ID BOOKING",
      accessor: "booking_id",
    },
    {
      Header: t("field.bookingTime").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.booking_time ? moment(original?.booking_time).format("DD/MM/YYYY, HH:mm") : '-';
      },
    },
    {
      Header: t("report.serviceTime").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.service_time ? moment(original?.service_time).format("DD/MM/YYYY, HH:mm") : '-';
      },
    },
    {
      Header: t("field.airport").toUpperCase(),
      accessor: "airport",
    },
    {
      Header: "LOUNGE",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.lounge ?? "-";
      },
    },
    {
      Header: t("field.bookingType").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.booking_type ?? "-";
      },
    },
    {
      Header: t("field.passenger").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.passenger ?? "-";
      },
    },
    {
      Header: "TOTAL PAX",
      accessor: "total_pax",
    },
    {
      Header: "BASIC RATE",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <>
            {"Rp " +
              (original?.basic_rate
                ? numberWithCommas(original?.basic_rate)
                : 0)}
          </>
        );
      },
      minWidth: 120,
    },
    {
      Header: "PUBLISH RATE",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <>
            {"Rp " +
              (original?.product_price
                ? numberWithCommas(original?.product_price)
                : 0)}
          </>
        );
      },
      minWidth: 120,
    },
    {
      Header: t("field.additionalService").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <>
            {"Rp " +
              (original?.joumpa_service_price
                ? numberWithCommas(original?.joumpa_service_price) 
                : 0)}
          </>
        );
      },
    },
    {
      Header: "GRAND TOTAL",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <>
            {"Rp " +
              (original?.grand_total
                ? numberWithCommas(original?.grand_total)
                : 0)}
          </>
        );
      },
      minWidth: 120,
    },
    {
      Header: t(t("field.commission")).toUpperCase(),
      accessor: "commission",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return <>{"Rp " + numberWithCommas(original?.commission)}</>;
      },
    },
  ];

  return (
    <>
      <Row className="justify-content-end">
        <Col md="3" className="pr-0">
          <Select
            isClearable={!!filterCommissionSummary?.airport_id}
            classNamePrefix="select"
            className="basic-single mr-3"
            isSearchable
            name="airport_id"
            styles={reactSelectCustomStyles}
            options={[
              {
                value: "",
                label: `${t("commons.all")} ${t("field.airport")}`,
              },
              ...airports,
            ]}
            value={
              airports?.filter((obj) => {
                return obj?.value === filterCommissionSummary?.airport_id;
              })[0] ?? {
                label: `${t("commons.all")} ${t("field.airport")}`,
                value: "",
              }
            }
            onChange={(item) => {
              setFilterCommissionSumary({
                ...filterCommissionSummary,
                airport_id: item?.value,
              });
            }}
          />
        </Col>
        <div className="nav-separator mb-3" />
        <Col md="3">
          <DatePicker
            name="start_date"
            handlechange={(startDate, endDate) => {
              setFilterCommissionSumary({
                ...filterCommissionSummary,
                start_date: startDate === "" || startDate === undefined
                  ? "" 
                  : moment(startDate).format("YYYY-MM-DD"),
                end_date: endDate === "" || endDate === undefined
                  ? "" 
                  : moment(endDate).format("YYYY-MM-DD"),
              });
            }}
            containerPosition="auto"
            notReadOnly
            format="DD MMMM YYYY"
            startDate={filterCommissionSummary?.start_date}
            endDate={filterCommissionSummary?.end_date}
          />
        </Col>
      </Row>
      <Card rounded className="col-md mb-4">
        <CardHeader isDashboard title={`${t("menuItem.report")} ${t(t("field.commission"))}`} />
        <Row className="py-3 px-4">
          <Col md="4">
            <ProductionCard
              color="hijau"
              title={`Total ${t("field.reservation")} Lounge`}
              total={totalReservationLounge}
              icon="done"
              pending={pending}
            />
          </Col>
          <Col md="4">
            <ProductionCard
              color="hijau"
              title={`Total ${t("field.reservation")} Joumpa`}
              total={totalReservationJoumpa}
              icon="done"
              pending={pending}
            />
          </Col>
          <Col md="4">
            <ProductionCard
              color="hijau"
              title={`Total ${t("field.reservation")}`}
              total={totalReservation}
              icon="done"
              pending={pending}
            />
          </Col>
        </Row>
        <Row className="py-3 px-4">
          <Col md="4">
            <ProductionCard
              color="hijau"
              title="Total Pax"
              total={totalPax}
              icon="done"
              pending={pending}
            />
          </Col>
          <Col md="4">
            <ProductionCard
              color="hijau"
              title={`Total ${t("field.commission")}`}
              total={totalCommission !== "Rp undefined" ? totalCommission : "Rp 0"}
              icon="done"
              pending={pending}
            />
          </Col>
        </Row>
      </Card>
      <hr />
      <div className="d-flex my-4 justify-content-end">
        <Button
          title="Filter"
          size="sm"
          rounded={true}
          variant="button-light btn-lg"
          onClick={handleFilterModal}
        />
        <div className="nav-separator mr-3 ml-3" />
        <Button
          title={(
            <>
              {loadingDownload && (
                <span className="spinner-border spinner-border-sm mr-2"></span>
              )}
              {t("report.downloadReport")}
            </>
          )}
          leftIcon={!loadingDownload ? "download" : null}
          rounded={true}
          variant="button-light btn-lg"
          onClick={handleDownloadReport}
        />
      </div>
      <Table
        columns={column.filter((item) => item.show !== false)}
        data={dataReport ?? []}
        pagination={{ page, totalData, maxPage, toggle: setPage }}
      />
      
      <ModalFilter
        show={showModalFilter}
        toggle={() => setShowModalFilter(false)}
        filter={paramFilter}
        user={user}
        airport={airports}
        lounges={dataLounges}
        setSearchLounge={(value) => setSearchLounge(value)}
        toggleFilter={() => {
          setParamFilter({
            end_date: moment().format("YYYY-MM-DD"),
            start_date: moment().format("YYYY-MM-DD"),
            airport_id: "",
            lounge_id: "",
            booking_type: "",
            report_type: true,
          });
        }}
        onApplyFilter={onApplyFilter}
      />
    </>
  )
}

const mapStateToProps = ({ report: { pending, data } }) => {
  return { pending, data };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(ReportCommission)
);
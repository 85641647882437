import { Formik, Form } from "formik";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { withTrans } from "../../i18n/withTrans";
import Select2 from "../../components/forms/Select2";
import ButtonModal from "../../components/modal/ButtonModal";
import DynamicButton from "../../components/forms/DynamicButton";
import DatePicker from "../../components/forms/DateRangePicker";
import moment from "moment";
import debounce from "lodash.debounce";

function ModalFilter({
  t,
  show,
  airport,
  lounges,
  toggle,
  filter,
  toggleFilter,
  isDelete,
  onApplyFilter,
  setSearchLounge,
}) {

  const debouncedSearchLounge = debounce((value) => {
    if (value !== "") setSearchLounge(value);
  }, 500);

  const handleReset = (formik) => {
    formik.setFieldValue("start_date", moment().format("YYYY-MM-DD"));
    formik.setFieldValue("end_date", moment().format("YYYY-MM-DD"));
    formik.setFieldValue("booking_start_date", moment().format("YYYY-MM-DD"));
    formik.setFieldValue("booking_end_date", moment().format("YYYY-MM-DD"));
    formik.setFieldValue("lounge_id", "");
    formik.setFieldValue("airport_id", "");
    formik.setFieldValue("booking_type", "");
    toggleFilter({
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
      booking_start_date: moment().format("YYYY-MM-DD"),
      booking_end_date: moment().format("YYYY-MM-DD"),
      lounge_id: "",
      airport_id: "",
      booking_type: "",
    });
    toggle();
  };

  return (
    <>
      <Modal isOpen={show} modalTransition={{ timeout: 200 }}>
        <ModalHeader className="text-extra-bold">
          {" "}
          Filter{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <ModalBody className="my-0 mx-3">
          <DynamicButton titleLeft={t("field.chooseFilter")} iconLeft="info" />
          <Formik
            initialValues={{
              start_date: filter?.start_date ?? "",
              end_date: filter?.end_date ?? "",
              booking_start_date: filter?.booking_start_date ?? "",
              booking_end_date: filter?.booking_end_date ?? "",
              airport_id: filter?.airport_id ?? "",
              lounge_id: filter?.lounge_id ?? "",
              booking_type: filter?.booking_type ?? "",
            }}
            enableReinitialize
            onSubmit={(values) => {
              onApplyFilter({
                airport_id: values.airport_id,
                start_date:
                  values.start_date === "" || values.start_date === undefined
                    ? ""
                    : moment(values.start_date).format("YYYY-MM-DD"),
                end_date:
                  values.end_date === "" || values.end_date === undefined
                    ? ""
                    : moment(values.end_date).format("YYYY-MM-DD"),
                booking_start_date:
                  values.booking_start_date === "" || values.booking_start_date === undefined
                    ? ""
                    : moment(values.booking_start_date).format("YYYY-MM-DD"),
                booking_end_date:
                  values.booking_end_date === "" || values.booking_end_date === undefined
                    ? ""
                    : moment(values.booking_end_date).format("YYYY-MM-DD"),
                lounge_id: values.lounge_id,
                booking_type: values.booking_type,
              });
              toggle();
            }}
          >
            {(props) => (
              <Form>
                <DatePicker
                  title={t("report.serviceTime")}
                  name="start_date"
                  handlechange={(startDate, endDate) => {
                    props.setFieldValue("start_date", startDate);
                    props.setFieldValue("end_date", endDate);
                  }}
                  format="DD MMMM YYYY"
                  startDate={filter ? filter?.start_date : props.values?.start_date}
                  endDate={filter ? filter?.end_date : props.values?.end_date}
                  className="mx-auto"
                  errors={props.errors}
                  touched={props.touched}
                />
                <DatePicker
                  title={t("field.bookingTime")}
                  name="booking_start_date"
                  handlechange={(startDate, endDate) => {
                    props.setFieldValue("booking_start_date", startDate);
                    props.setFieldValue("booking_end_date", endDate);
                  }}
                  format="DD MMMM YYYY"
                  startDate={filter ? filter?.booking_start_date : props.values?.booking_start_date}
                  endDate={filter ? filter?.booking_end_date : props.values?.booking_end_date}
                  className="mx-auto"
                  errors={props.errors}
                  touched={props.touched}
                />
                <Select2
                  title={t("field.airport")}
                  name="airport_id"
                  options={[
                    {
                      value: "",
                      label: `${t("commons.all")} ${t("field.airport")}`,
                    },
                    ...airport,
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.airport_id}
                  onChange={(name, value) => {
                    props.setFieldValue("airport_id", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <Select2
                  title={t("field.lounge")}
                  name="lounge_id"
                  options={[
                    {
                      value: "",
                      label: `${t("commons.all")} ${t("field.lounge")}`,
                    },
                    ...lounges,
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.lounge_id}
                  onInputChange={(keyword) => {
                    debouncedSearchLounge(keyword);
                  }}
                  onChange={(name, value) => {
                    if (!value) setSearchLounge("");
                    props.setFieldValue("lounge_id", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <Select2
                  title={t("field.bookingType")}
                  name="booking_type"
                  options={[
                    {
                      value: "",
                      label: `${t("commons.all")} ${t(
                        "field.bookingType"
                      )}`,
                    },
                    {
                      value: "mobile",
                      label: t("field.mobileApp"),
                    },
                    {
                      value: "web-public",
                      label: t("field.webPublic"),
                    },
                    {
                      value: "web-admin",
                      label: t("field.webAdmin"),
                    },
                    {
                      value: "api",
                      label: "API",
                    },
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.booking_type}
                  onChange={(name, value) => {
                    props.setFieldValue("booking_type", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
               
                <div className="mt-4 mb-3">
                  <ButtonModal
                    toggle={toggle}
                    reset={() => {
                      setSearchLounge("")
                      handleReset(props)
                    }}
                    deleted={isDelete}
                    confirmTitle={t("field.apply")}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
}

export default withTrans(ModalFilter);

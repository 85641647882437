import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Col,
  Row
} from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import Input from "../../../components/forms/Input";
import ButtonModal from "../../../components/modal/ButtonModal";
import DynamicForm from "../../../components/forms/DynamicForm";
import Select2Multi from "../../../components/forms/Select2Multi";
import { withTrans } from "../../../i18n/withTrans";
import InputPrepend from "../../../components/forms/InputPrepend";
import Checkbox from "../../../components/forms/Checkbox";
import { exceptionSymbolNumbering, flightTypeList, routeTypeList } from "../../../utils/Constants";
import { toastError } from "../../../components/commons/toast";

const MasterDataModal = ({
  t,
  show,
  toggle,
  action,
  data,
  confirm,
  airport_list,
  data_post,
  data_put,
  error_message
}) => {
  const formikRef = useRef();
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const title =
    (data ? t("commons.edit") : t("commons.add")) + " " + t("field.lounge");

  const initial = data
    ? {
        id: data.id,
        name: data.name,
        airport_ids: data.airport_list?.map((item) => item?.id),
        route_type: data.route_type === "all"
          ? routeTypeList(t)?.map((item) => item?.value)
          : [data?.route_type],
        flight_type: data.flight_type === parseInt(flightTypeList(t)?.map((item) => item?.value).join(""))
          ? flightTypeList(t)?.map((item) => item?.value)
          : [data?.flight_type],
        price_adult: data.price_adult,
        price_child: data.price_child,
        price_infant: data.price_infant,
        basic_price_adult: data.basic_price_adult,
        basic_price_child: data.basic_price_child,
        basic_price_infant: data.basic_price_infant,
        is_include_ppn: data.is_include_ppn ? ["1"] : null,
        ppn_percentage: data.ppn_percentage
      }
    : {
        name: "",
        airport_ids: null,
        route_type: "",
        flight_type: "",
        price_adult: null,
        price_child: null,
        price_infant: null,
        basic_price_adult: null,
        basic_price_child: null,
        basic_price_infant: null,
        is_include_ppn: null,
        ppn_percentage: null
      };

  const validation = Yup.object().shape({
    name: Yup.string().required(`${t("field.lounge")} ${t("commons.required")}`).nullable(),
    airport_ids: Yup.array().required(`${t("field.airport")} ${t("commons.required")}`).nullable(),
    route_type: Yup.array().required(`${t("field.routeType")} ${t("commons.required")}`).nullable(),
    flight_type: Yup.array().required(`${t("field.flightType")} ${t("commons.required")}`).nullable(),
    price_adult: Yup.number()
      .min(1, `${t("field.price")} ${t("commons.required")}`)
      .required(`${t("field.price")} ${t("commons.required")}`)
      .nullable(),
    price_child: Yup.number()
      .min(1, `${t("field.price")} ${t("commons.required")}`)
      .required(`${t("field.price")} ${t("commons.required")}`)
      .nullable(),
    price_infant: Yup.number()
      .min(1, `${t("field.price")} ${t("commons.required")}`)
      .required(`${t("field.price")} ${t("commons.required")}`)
      .nullable(),
    basic_price_adult: Yup.number()
      .min(1, `${t("field.price")} ${t("commons.required")}`)
      .required(`${t("field.price")} ${t("commons.required")}`)
      .nullable(),
    basic_price_child: Yup.number()
      .min(1, `${t("field.price")} ${t("commons.required")}`)
      .required(`${t("field.price")} ${t("commons.required")}`)
      .nullable(),
    basic_price_infant: Yup.number()
      .min(1, `${t("field.price")} ${t("commons.required")}`)
      .required(`${t("field.price")} ${t("commons.required")}`)
      .nullable(),
  });

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    if (!error_message) return;

    setLoadingSubmit(false);
  }, [error_message]);

  useEffect(() => {
    if (data_post?.data?.status === 200) {
      setLoadingSubmit(false);
    }
  }, [data_post]);

  useEffect(() => {
    if (data_put?.data?.status === 200) {
      setLoadingSubmit(false);
    }
  }, [data_put]);

  const priceNotValid = (values) => {
    if (
      Number(values.basic_price_adult) > Number(values.price_adult) ||
      Number(values.basic_price_child) > Number(values.price_child) ||
      Number(values.basic_price_child) > Number(values.price_child)
    ) {
      toastError(t("masterData.loungePriceHandle"))
      return true
    }
    return false
  }

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {title}{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <Formik
          innerRef={formikRef}
          initialValues={initial}
          validationSchema={validation}
          onSubmit={async (values, { setStatus }) => {
            setStatus();
            const route_type = values?.route_type?.length === routeTypeList(t)?.length
              ? "all"
              : values?.route_type?.find(() => true);

            const flight_type = values?.flight_type?.length === flightTypeList(t)?.length
              ? parseInt(values?.flight_type?.sort((a, b) => a - b)?.join(""))
              : values?.flight_type?.find(() => true);

            const param  = {
              ...values,
              price_adult: values.price_adult || 0,
              price_child: values.price_child || 0,
              price_infant: values.price_infant || 0,
              basic_price_adult: values.basic_price_adult || 0,
              basic_price_child: values.basic_price_child || 0,
              basic_price_infant: values.basic_price_infant || 0,
              ppn_percentage: values.ppn_percentage || 0,
              is_include_ppn: values.is_include_ppn == 1 ? true : false,
              route_type : route_type,
              flight_type : flight_type
            }
            
            if (!priceNotValid(values)) {
              setLoadingSubmit(true);
              action(param);
              await delay(3000);
            }
          }}
        >
          {(props) => (
            <Form>
              <ModalBody>
                <Container>
                  <Input
                    name="name"
                    errors={props.errors}
                    touched={props.touched}
                    {...props.getFieldProps("name")}
                    title={t("field.lounge")}
                    type="text"
                  />
                  <Select2Multi
                    title={t("field.airport")}
                    name="airport_ids"
                    options={airport_list}
                    errors={props?.errors}
                    touched={props?.touched}
                    value={props?.values?.airport_ids}
                    onChange={(name, value) => {
                      let data = value;
                      if (!data.length > 0) {
                        data = "";
                      }
                      props?.setFieldValue("airport_ids", data);
                    }}
                    onBlur={props?.setFieldTouched}
                  />
                  <Select2Multi
                    title={t("field.routeType")}
                    name="route_type"
                    options={routeTypeList(t)}
                    errors={props?.errors}
                    touched={props?.touched}
                    value={props?.values?.route_type}
                    onChange={(name, value) => {
                      let data = value;
                      if (!data.length > 0) {
                        data = "";
                      }
                      props?.setFieldValue("route_type", data);
                    }}
                    onBlur={props?.setFieldTouched}
                  />
                  <Select2Multi
                    title={t("field.flightType")}
                    name="flight_type"
                    options={flightTypeList(t)}
                    errors={props?.errors}
                    touched={props?.touched}
                    value={props?.values?.flight_type}
                    onChange={(name, value) => {
                      let data = value;
                      if (!data.length > 0) {
                        data = "";
                      }
                      props?.setFieldValue("flight_type", data);
                    }}
                    onBlur={props?.setFieldTouched}
                  />
                  <div className="form-group mt-3 mb-0">
                    <label className="form-label mb-1">
                      Publish Rate
                    </label>
                  </div>
                  <Row>
                    <Col md={4} className="pr-0">
                      <div className="form-group mb-0">
                        <label>
                          {`${t("field.price")} ${t("commons.adult")}`}
                        </label>
                      </div>
                    </Col>
                    <Col md={4} className="pl-0">
                      <div className="form-group mb-0">
                        <label>
                          {`${t("field.price")} ${t("commons.child")}`}
                        </label>
                      </div>
                    </Col>
                    <Col md={4} className="pl-0">
                      <div className="form-group mb-0">
                        <label>
                          {`${t("field.price")} ${t("commons.infantLabel")}`}
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <DynamicForm
                    notAlignedCenter
                    withoutDelete
                    pbRow="0"
                    input={`price_adult`}
                    nameCenter={`price_child`}
                    name={`price_infant`}
                    placeholderLeft={`${t("field.price")} ${t("commons.adult")}`}
                    placeholderCenter={`${t("field.price")} ${t("commons.child")}`}
                    placeholder={`${t("field.price")} ${t("commons.infantLabel")}`}
                    typeLeft="number"
                    type="number"
                    typeCenter="number"
                    currencyLeft
                    currencyCenter
                    currency
                    formik={props}
                    size={{ title: 4, center: 4, right: 4, }}
                  />
                  <div className="form-group mb-0">
                    <label className="form-label mb-1">
                      Basic Rate
                    </label>
                  </div>
                  <Row>
                    <Col md={4} className="pr-0">
                      <div className="form-group mb-0">
                        <label>
                          {`${t("field.price")} ${t("commons.adult")}`}
                        </label>
                      </div>
                    </Col>
                    <Col md={4} className="pl-0">
                      <div className="form-group mb-0">
                        <label>
                          {`${t("field.price")} ${t("commons.child")}`}
                        </label>
                      </div>
                    </Col>
                    <Col md={4} className="pl-0">
                      <div className="form-group mb-0">
                        <label>
                          {`${t("field.price")} ${t("commons.infantLabel")}`}
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <DynamicForm
                    notAlignedCenter
                    withoutDelete
                    input={`basic_price_adult`}
                    nameCenter={`basic_price_child`}
                    name={`basic_price_infant`}
                    placeholderLeft={`${t("field.price")} ${t("commons.adult")}`}
                    placeholderCenter={`${t("field.price")} ${t("commons.child")}`}
                    placeholder={`${t("field.price")} ${t("commons.infantLabel")}`}
                    typeLeft="number"
                    type="number"
                    typeCenter="number"
                    currencyLeft
                    currencyCenter
                    currency
                    formik={props}
                    size={{ title: 4, center: 4, right: 4, }}
                  />
                  <Checkbox
                    style={{ marginTop: "30px" }}
                    name="is_include_ppn"
                    checked={props?.values?.is_include_ppn}
                    onChange={props?.handleChange}
                    value={1}
                    label={t("product.includes_ppn")}
                  />
                  <InputPrepend
                    mt="2"
                    name="ppn_percentage"
                    title="PPN %"
                    type="number"
                    min={0}
                    value={props?.values?.ppn_percentage}
                    onKeyDown={(e) =>
                      exceptionSymbolNumbering.includes(e.key) && e.preventDefault()
                    }
                    onChange={(data) => {
                      const val = data.target.value;
                      props?.setFieldValue(
                        "ppn_percentage",
                        val
                      );
                    }}
                  />
                </Container>
              </ModalBody>
              <ModalFooter>
                <ButtonModal
                  toggle={toggle}
                  confirm={confirm}
                  confirmTitle={t("commons.save")}
                  disabledConfirm={loadingSubmit}
                  icon
                />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ lounge: { data_post, data_put, error_message } }) => {
  return { data_post, data_put, error_message };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(MasterDataModal));

import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import MenuItem from "@mui/material/MenuItem";

import Table from "../../../components/table";
import HeaderMenu from "../../../components/commons/menu/HeaderMenu";
import Button from "../../../components/buttons/Button";
import SearchButton from "../../../components/buttons/SearchButton";
import DetailButton from "../../../components/table/detailButton";
import Loader from "../../../components/commons/Loader";
import ConfirmationModal from "../../../components/modal/ConfirmModal";
import MasterDataModal from "./MasterDataModal";
import LabelPlus from "../../../components/table/labelPlus";
import { withTrans } from "../../../i18n/withTrans";

// ACTION REDUX
import LoungeService from "../../../store/actions/master_data/lounge";
import AirportsService from "../../../store/actions/master_data/airport";
import ModalFilter from "./modalFilter";
import { flightTypeList, routeTypeList } from "../../../utils/Constants";
import { toastError } from "../../../components/commons/toast";
import { getErrorMessage } from "../../../utils/Helper";

function Index({ t, pending, pending_post, pending_put }) {
  const lang = localStorage.getItem('joumpa_language');
  const dispatch = useDispatch();
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [dataALounges, setDataLounges] = useState([]);
  const [dataLoungesRow, setDataLoungesRow] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [totalData, setTotalData] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [dataAirports, setDataAirports] = useState([]);
  const [paramFilter, setParamFilter] = useState({
    airport_id: "",
    route_type: "",
    flight_type: ""
  });

  const showEditModal = (data) => {
    setEditModal(!editModal);
    setDataLoungesRow(data ? data : []);
  };

  const showAddModal = () => {
    setAddModal(!addModal);
  };

  const showDeleteModal = (data) => {
    setDeleteModal(!deleteModal);
    setDataLoungesRow(data ? data : []);
  };

  const handleEdit = (param) => {
    const callback = () => {
      handleRefresh();
      setEditModal(!editModal);
    };

    new Promise((resolve, reject) => {
      if (param) {
        dispatch(LoungeService.put(param, resolve, reject, callback));
      }
    })
    .catch((err) => {
      toastError(
        getErrorMessage(lang === 'en-US' ? 'en' : lang, err?.data)
      );
    });
  };

  const handleAdd = (param) => {
    const callback = () => {
      handleRefresh();
      setAddModal(!addModal);
    };

    new Promise((resolve, reject) => {
      if (param) {
        dispatch(LoungeService.post(param, resolve, reject, callback));
      }
    })
    .catch((err) => {
      toastError(
        getErrorMessage(lang === 'en-US' ? 'en' : lang, err?.data)
      );
    });
  };

  const handleDelete = () => {
    const callback = () => {
      handleRefresh();
      setPage(1);
      setDeleteModal(!deleteModal);
    };

    new Promise((reject) => {
      if (dataLoungesRow) {
        const id = dataLoungesRow?.id;
        dispatch(LoungeService.deleted(id, reject, callback, dataLoungesRow?.name));
      }
    })
    .catch((err) => {
      toastError(
        getErrorMessage(lang === 'en-US' ? 'en' : lang, err?.data)
      );
    });
  };

  const columns = [
    {
      Header: "NO",
      accessor: "no",
    },
    {
      Header: "LOUNGE",
      accessor: "name",
      minWidth: 200
    },
    {
      Header: t("field.airport").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const idRow = original.id;
        const airport_list = original.airport_list.map((airport) => ({
          id: airport?.id,
          name: `${airport?.code}-${airport?.city}-${airport?.name}`,
        }));
        return <LabelPlus data={airport_list} idRow={idRow} />
      },
    },
    {
      Header: t("field.routeType").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const routes = original?.route_type === "all"
          ? routeTypeList(t)?.map((item) => ({ name : item?.label }))
          : [{ name: t(`commons.${original?.route_type}`) }]
        return <LabelPlus data={routes} idRow={original?.id} />
      },
    },
    {
      Header: t("field.flightType").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const flights = flightTypeList(t)
          .filter((item) => (
            original.flight_type === parseInt(flightTypeList(t)?.map((item) => item?.value).join("")) ||
            item.value === original.flight_type
          ))
          .map((item) => ({ name: item.label }));

        return <LabelPlus data={flights} idRow={original?.id} />;
      },
    },
    {
      Header: "",
      accessor: "actions",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <DetailButton>
            <MenuItem onClick={() => showEditModal(original)}>
              <span style={{ fontSize: 14 }}>{t("commons.edit")}</span>
            </MenuItem>
            <MenuItem onClick={() => showDeleteModal(original)}>
              <span style={{ fontSize: 14 }}>{t("commons.delete")}</span>
            </MenuItem>
          </DetailButton>
        );
      },
    },
  ];

  const handleSearch = (value) => {
    setSearchText(value.length > 0 ? value : "");
  };

  const handleFilterModal = () => {
    setShowModalFilter(true);
  };

  const onApplyFilter = (param) => {
    setParamFilter({
      airport_id: param.airport_id,
      route_type: param.route_type,
      flight_type: param.flight_type,
    })
    setShowModalFilter(false);
  };

  const handleRefresh = () => {
    new Promise((resolve) => {
      let param = {
        page: page === 0 ? 1 : page ?? 1,
        length: 10,
        search_text: searchText,
        airport_id: paramFilter?.airport_id,
        route_type: paramFilter?.route_type,
        flight_type: paramFilter?.flight_type
      };
      dispatch(LoungeService.get(param, resolve));
    }).then((res) => {
      setDataLounges(res?.data);
      setTotalData(res?.totalCount);
      setMaxPage(Math.ceil(res?.totalCount / 10));
    });
  };

  useEffect(() => {
    handleRefresh();
  }, [page, searchText, paramFilter]);

  useEffect(() => {
    setPage((prev) => (prev === null ? null : 1));
  }, [searchText, paramFilter]);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 99999,
        is_service: true
      };
      dispatch(AirportsService.get(param, resolve));
    }).then((res) => {
      setDataAirports(
        res?.data?.map((item) => (
          { value: item.id,
            label: `${item?.code}-${item?.city}-${item?.name}`
          }
        ))
      );
    });
  }, []);

  return (
    <>
      {pending && <Loader sideContent loading={pending} />}
      <HeaderMenu title="Lounge IASH">
        <SearchButton
          placeholder={t("commons.enterKeyword")}
          toggle={(value) => handleSearch(value)}
          style={{ marginRight: 20 }}
        />
        <Button
          title="Filter"
          size="sm"
          rounded={true}
          variant="button-light btn-lg ml-3"
          onClick={handleFilterModal}
        />
        <div className="nav-separator mr-3" />
        <Button
          title={t("commons.add") + " Lounge"}
          leftIcon="add"
          rounded={true}
          variant="primary"
          onClick={showAddModal}
        />
      </HeaderMenu>
      <div className="content-container-side">
        <div className="overflow">
          <Table
            columns={columns.filter(item => item.show !== false)}
            data={dataALounges ?? []}
            pagination={{ page, totalData, maxPage, toggle: setPage }}
          />
        </div>
      </div>

      <ModalFilter
        show={showModalFilter}
        toggle={() => setShowModalFilter(false)}
        airport={dataAirports}
        filter={paramFilter}
        toggleFilter={(props) => {
          setParamFilter({
            airport_id: props.airport_id,
            route_type: props.route_type,
            flight_type: props.flight_type,
          });
        }}
        onApplyFilter={onApplyFilter}
      />

      <MasterDataModal
        show={addModal}
        toggle={showAddModal}
        action={handleAdd}
        pending={pending_post}
        airport_list={dataAirports}
      />

      <MasterDataModal
        show={editModal}
        toggle={showEditModal}
        action={handleEdit}
        data={dataLoungesRow}
        pending={pending_put}
        airport_list={dataAirports}
      />

      <ConfirmationModal
        show={deleteModal}
        toggle={showDeleteModal}
        confirm={handleDelete}
        data={dataLoungesRow}
        icon="info"
        param={
          dataLoungesRow?.name?.length > 40
            ? dataLoungesRow?.name?.substring(0, 40) + "..."
            : dataLoungesRow?.name
        }
        isDelete
      />
    </>
  );
}

const mapStateToProps = ({ lounge: { pending } }) => {
  return { pending };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(Index));

import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux";
import moment from "moment";

import ModalFilter from "./ModalFilter";
import Table from "../../../components/table";
import TableStatus from "../../../components/table/status";
import Button from "../../../components/buttons/Button";
import { withTrans } from "../../../i18n/withTrans";

import ReportService from "../../../store/actions/report/";

const ReportPax = ({ t, user, airports, corporates, products }) => {
  const dispatch = useDispatch();
  const [dataReport, setdataReport] = useState([]);
  const [totalData, setTotalData] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [haveAirport, setHaveAirport] = useState(
    user.role_code === "admin_bandara"
      ? true
      : user.role_code === "supervisor"
      ? true
      : user.role_code === "sales_bandara"
      ? true
      : user.role_code === "finance_bandara"
      ? true
      : false
  );
  const [paramFilter, setParamFilter] = useState({
    end_date: moment().format("YYYY-MM-DD"),
    start_date: moment().format("YYYY-MM-DD"),
    airport_id:
      user.role_code === "admin_bandara" ||
      user.role_code === "supervisor"
        ? user.airport_id
        : "",
    product_id: "",
    corporate_id: "",
    service_type: "",
    payment_type: "",
  });

  const handleFilterModal = () => {
    setShowModalFilter(true);
  };

  const onApplyFilter = (param) => {
    setParamFilter({
      airport_id: param.airport_id,
      service_type: param.service_type,
      payment_type: param.payment_type,
      start_date: param.start_date,
      end_date: param.end_date,
      corporate_id: param.corporate_id,
      product_id: param.product_id,
    });
  };

  const handleRefresh = () => {
    new Promise((resolve) => {
      const param = {
        ...paramFilter,
        service_type: paramFilter.service_type ?? "",
        page: page === 0 ? 1 : page ?? 1,
        length: 10,
      };
      dispatch(ReportService.getCustomer(param, resolve));
    }).then((res) => {
      setdataReport(res?.data);
      setTotalData(res?.totalCount);
      setMaxPage(Math.ceil(res?.totalCount / 10));
    });
  };

  useEffect(() => {
    handleRefresh();
  }, [page, paramFilter]);

  useEffect(() => {
    setPage((prev) => (prev === null ? null : 1));
  }, [paramFilter]);

  const handleDownloadReport = () => {
    new Promise((resolve) => {
      setLoadingDownload(true);
      dispatch(ReportService.downloadCustomer(paramFilter, resolve))
    }).then((res) => {
      setLoadingDownload(false);
    });
  };

  const numberWithCommas = (x) => {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return 0;
    }
  };

  
  const column = [
    {
      Header: "NO",
      accessor: "no",
    },
    {
      Header: t("report.bookingDate").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.booking_date ? moment(original?.booking_date).format("DD/MM/YYYY, HH:mm") : '-';
      },
    },
    {
      Header: t("report.serviceTime").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <>
            {original?.date && original?.time
              ? `${moment(original?.date).format("DD/MM/YYYY")} ${
                  original?.time
                }`
              : "-"}
          </>
        );
      },
    },
    {
      Header: "ID BOOKING",
      accessor: "booking_id",
    },
    {
      Header: t("field.passenger").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.name ? original?.name : "-";
      },
    },
    {
      Header: t("field.airport").toUpperCase(),
      accessor: "code",
    },
    {
      Header: `${t("field.airport").toUpperCase()} ${t("field.cross_selling").toUpperCase()}`,
      textAlign: 'center',
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.airport_cross_selling ?? "-";
      },
    },
    {
      Header: t("field.flightNumber").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.flight !== null ? original?.flight : "-";
      },
    },
    {
      Header: t("field.route").toUpperCase(),
      minWidth: 150,
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.route !== null ? original?.route : "-";
      },
    },
    {
      Header: t("field.product").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.product_name_eng ? original?.product_name_eng : "-";
      },
    },
    {
      Header: t("field.corporateName").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (original?.corporate_name !== null
          ? original.corporate_name
          : "-")
      },
    },
    {
      Header: "PAX",
      accessor: "total_passenger",
    },
    {
      Header: t("booking.paymentType").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.payment_type ?? "-";
      },
    },
    {
      Header: t("field.additionalService").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <>
            {"Rp " +
              (original?.additional_service ? numberWithCommas(original?.additional_service) : 0)}
          </>
        );
      },
    },
    {
      Header: "SUBTOTAL",
      minWidth: 150,
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <>
            {"Rp " +
              (original?.sub_total ? numberWithCommas(original?.sub_total) : 0)}
          </>
        );
      },
    },
    {
      Header: "REMARK",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.remarks_evidence !== null
          ? original?.remarks_evidence
          : "-";
      },
    },
    {
      Header: `${t("field.payment").toUpperCase()}`,
      Cell: ({ row }) => {
        const { original } = row;
        let { is_paid } = original;
        return (
          <TableStatus
            status={
              is_paid === false
                ? t("status.unpaid")
                : original?.corporate_group_type === 'Regular'
                  ? t("status.paid")
                  : t("status.invoicePayment")
            }
            color={
              is_paid === false
                ? "danger"
                : "primary"
            }
          />
        );
      },
      minWidth: 110,
    },
  ];

  return (
    <>
      <div className="d-flex mb-4 justify-content-end">
        <Button
          title="Filter"
          size="sm"
          rounded={true}
          variant="button-light btn-lg"
          onClick={handleFilterModal}
        />
        <div className="nav-separator mr-3 ml-3" />
        <Button
          title={(
            <>
              {loadingDownload && (
                <span className="spinner-border spinner-border-sm mr-2"></span>
              )}
              {t("report.downloadReport")}
            </>
          )}
          leftIcon={!loadingDownload ? "download" : null}
          rounded={true}
          variant="button-light btn-lg"
          onClick={handleDownloadReport}
        />
      </div>
      <Table
        columns={column.filter((item) => item.show !== false)}
        data={dataReport ?? []}
        pagination={{ page, totalData, maxPage, toggle: setPage }}
      />
      
      <ModalFilter
        user={user}
        haveAirport={haveAirport}
        show={showModalFilter}
        toggle={() => setShowModalFilter(false)}
        filter={paramFilter}
        airport={airports}
        product={products}
        corporate={corporates}
        toggleFilter={() => {
          setParamFilter({});
          handleRefresh();
        }}
        onApplyFilter={onApplyFilter}
      />
    </>
  )
}

export default withTrans(ReportPax)
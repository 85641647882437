import React from "react";
import { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";

import HeaderMenu from "../../components/commons/menu/HeaderMenu";
import DetailButton from "../../components/table/detailButton";
import Loader from "../../components/commons/Loader";
import Button from "../../components/buttons/Button";
import Card from "../../components/cards/card";
import SearchButton from "../../components/buttons/SearchButton";
import Table from "../../components/table";
import TableStatus from "../../components/table/status";
import CancelReasonModal from "../../components/modal";
import ConfirmationModal from "../../components/modal/ConfirmModal";
import ModalFilter from "./modal/modalFilter";
import RescheduleBookingModal from "./modal/RescheduleBookingModal";
import { toastSuccess, toastError } from "../../components/commons/toast";
import { getUser } from "../../utils/User";
import { history } from "../../utils/History";
import { withTrans } from "../../i18n/withTrans";

// Service
import ReservationLounge from "../../store/actions/reservation_lounge";
import AirportService from "../../store/actions/master_data/airport";
import MasterLoungeService from "../../store/actions/master_data/lounge";
import { paymentStatusLounge, Role, RoleIash } from "../../utils/Constants";

const LoungeReservation = ({ match, t, pending, error, data_delete }) => {
  const dispatch = useDispatch();
  const currentUser = getUser();
  const lang = localStorage.getItem('joumpa_language');
  const currentUrl = match?.url?.substring(1);

  const [dataBooking, setDataBooking] = useState([]);
  const [dataAirport, setDataAirport] = useState([]);
  const [dataLounges, setDataLounges] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchLounge, setSearchLounge] = useState("");
  const [totalData, setTotalData] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [cancelReservationModal, setCancelReservationModal] = useState(false);
  const [cancelReasonModal, setCancelReasonModal] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [doneReservationModal, setDoneReservationModal] = useState(false);
  const [rescheduleModal, setRescheduleModal] = useState(false);
  const [dataRescheduleBooking, setDataRescheduleBooking] = useState([]);
  const [dataBookingRow, setDataBookingRow] = useState([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [paramFilter, setParamFilter] = useState({
    start_date: "",
    end_date: "",
    service_start_date: "",
    service_end_date: "",
    payment_status: "",
    status: "",
    booking_type: "",
    airport_id: "",
    lounge_id: "",
  });

  useEffect(() => {
    const justLogin = localStorage.getItem("just_login");

    if (justLogin) {
      toastSuccess(
        `${lang === 'id' 
          ? `Selamat Anda berhasil login sebagai ${justLogin}` 
          : `Well done! you have logged in as ${justLogin}`}`
      );

      localStorage.removeItem("just_login");
    }
  }, []);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 9999,
        is_service: true,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      let list_airports = [];
      if (res) {
        list_airports = res?.data?.map((airport) => ({
          value: airport?.id,
          label: `${airport?.code}-${airport?.city}-${airport?.name} ${
            airport?.uniform === 'Indonesia' ? "" : "-" + airport?.uniform
          }`,
        }));
      }
      setDataAirport(list_airports);
    });
  }, []);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchLounge,
      };
      dispatch(MasterLoungeService.get(param, resolve));
    }).then((res) => {
      if (res) {
        setDataLounges(
          res?.data?.map((item) => ({
            label: item?.name,
            value: item?.id
          }))
        )
      }
    });
  }, [searchLounge]);

  useEffect(() => {
    if (error) {
      setLoadingSubmit(false);
    }
  }, [error]);

  useEffect(() => {
    if (data_delete?.data?.status === 200) {
      setLoadingSubmit(false);
    }
  }, [data_delete]);

  const handleSearch = (value) => {
    setSearchText(value.length > 0 ? value : "");
  };
  
  const handleRefresh = () => {
    new Promise((resolve) => {
      let param = {
        page: page === 0 ? 1 : page ?? 1,
        length: 10,
        id: null,
        search_text: searchText,
        airport_id:
          paramFilter?.airport_id !== "" ? paramFilter?.airport_id : "",
        lounge_id: paramFilter?.lounge_id !== "" ? paramFilter?.lounge_id : "",
        payment_status:
          paramFilter?.payment_status !== "" ? paramFilter?.payment_status : "",
        status:
          paramFilter?.status !== "" ? paramFilter?.status : "",
        booking_type:
          paramFilter?.booking_type !== "" ? paramFilter?.booking_type : "",
        start_date:
          paramFilter?.start_date !== ""
            ? moment(paramFilter?.start_date).format("YYYY-MM-DD")
            : "",
        end_date:
          paramFilter?.end_date !== ""
            ? moment(paramFilter?.end_date).format("YYYY-MM-DD")
            : "",
        service_start_date:
          paramFilter?.service_start_date !== ""
            ? moment(paramFilter?.service_start_date).format("YYYY-MM-DD")
            : "",
        service_end_date:
          paramFilter?.service_end_date !== ""
            ? moment(paramFilter?.service_end_date).format("YYYY-MM-DD")
            : "",
      };
      dispatch(ReservationLounge.get(param, resolve));
    }).then((res) => {
      if (res) {
        setDataBooking(res?.data ?? []);
        setTotalData(res?.totalCount);
        setMaxPage(Math.ceil(res?.totalCount / 10));
      }
    });
  };

  const onApplyFilter = (param) => {
    setParamFilter({
      airport_id: param.airport_id,
      lounge_id: param.lounge_id,
      payment_status: param?.payment_status,
      status: param?.status,
      booking_type: param?.booking_type,
      start_date: param?.start_date,
      end_date: param?.end_date,
      service_start_date: param?.service_start_date,
      service_end_date: param?.service_end_date
    })
    setShowModalFilter(false);
  };

  const handleFilterModal = () => {
    setShowModalFilter(true);
  };

  useEffect(() => {
    handleRefresh();
  }, [page, searchText, paramFilter, currentUrl]);

  useEffect(() => {
    setPage((prev) => (prev === null ? null : 1));
  }, [searchText, paramFilter]);

  const showDoneReservationModal = (data) => {
    setDoneReservationModal(!doneReservationModal);
    setDataBookingRow(data ? data : []);
  };

  const handleDoneReservation = () => {
    const callback = () => {
      handleRefresh();
      setDoneReservationModal(!doneReservationModal);
    };

    if (!dataBookingRow) {
      return;
    }

    setLoadingSubmit(true);
    const id = dataBookingRow?.id;

    new Promise((resolve) => {
      dispatch(
        ReservationLounge.done(
          { order_id: id },
          resolve,
          callback
        )
      );
    }).then((res) => {
      setLoadingSubmit(false);
    });
  };

  const showCancelReservationModal = (data) => {
    setCancelReservationModal(!cancelReservationModal);
    setCancelReason("");
    setDataBookingRow(data ? data : []);
  };

  const handleCancelReservation = () => {
    const callback = () => {
      handleRefresh();
      setCancelReservationModal(!cancelReservationModal);
      setCancelReason("");
    };

    if (!dataBookingRow) {
      return;
    }

    setLoadingSubmit(true);
    const id = dataBookingRow?.id;

    if (cancelReason === "") {
      toastError(`${t('commons.empty_data_message')} ${t("field.reasonCancellation")}`);
      setLoadingSubmit(false);
    } else {
      new Promise((resolve) => {
        dispatch(
          ReservationLounge.del(
            { order_id: id, cancel_reason: cancelReason },
            resolve,
            callback
          )
        );
      }).then((res) => {
        setLoadingSubmit(false);
      });
    }
  };

  const showRescheduleModal = (data) => {
    setRescheduleModal(!rescheduleModal);
    setDataRescheduleBooking(data ? data : []);
  };

  const handleRescheduleBooking = (param) => {
    const callback = () => {
      handleRefresh();
      setRescheduleModal(!rescheduleModal);
    };
    dispatch(ReservationLounge.rescheduleBooking(param, callback));
  };

  const columns = [
    {
      Header: "#ID",
      accessor: "unique_transaction_id",
    },
    {
      Header: t("field.bookingTime").toUpperCase(),
      Cell: ({ row }) => {
        const { original } = row;
        let { booking_date } = original;
        let date = moment(booking_date)?.format("DD/MM/yyyy");
        let time = moment(booking_date)?.format("HH:mm");
        return date && time ? `${date}, ${time}` : '-';
      },
    },
    {
      Header: t("report.serviceTime").toUpperCase(),
      Cell: ({ row }) => {
        const { original } = row;
        let date = moment(original?.date)?.format("DD/MM/yyyy");
        let time = original?.time;
        return date && time ? `${date}, ${time}` : '-';
      },
    },
    {
      Header: t("field.customer").toUpperCase(),
      Cell: ({ row }) => {
        const { original } = row;
        let customer_name =
          original.customer_data === null
            ? original?.list_order_passengers?.length > 0
              ? original?.list_order_passengers[0]?.name
              : "-"
            : original.customer_data?.customer_name;

        return `${customer_name}`;
      },
    },
    {
      Header: t("field.bookingType").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const booking_type = 
          original?.order_from !== null ? 
            original?.order_from === 'mobile'
              ? 'Mobile App'
              : original?.order_from === 'web-public'
              ? 'Web Public'
              : 'Web Admin'
            : '-'
        return booking_type;
      },
    },
    {
      Header: t("field.flight").toUpperCase(),
      Cell: ({ row }) => {
        const { original } = row;
        const { airplane_data, type } = original;
        if(original?.type === 1) {
          return t("field.arrival") + "-" + airplane_data?.airplane_code + airplane_data?.airplane_number;
        } else if(original?.type === 2) {
          return t("field.departure") + "-" + airplane_data?.airplane_code + airplane_data?.airplane_number;
        }
      },
      minWidth: 150,
    },
    {
      Header: t("field.lounge").toUpperCase(),
      Cell: ({ row }) => {
        const { original } = row;
        const { lounge_data } = original;
        return lounge_data?.name;
      },
      minWidth: 200,
    },
    {
      Header: t("field.airport").toUpperCase(),
      Cell: ({ row }) => {
        const { original } = row;
        const { airport_from_data, airport_to_data, type } = original;
        const airportData = type === 2
          ? `${airport_from_data?.airport_code}-${airport_from_data?.airport_city}-${airport_from_data?.airport_name}`
          : `${airport_to_data?.airport_code}-${airport_to_data?.airport_city}-${airport_to_data?.airport_name}`
        return airportData;
      },
      minWidth: 250,
    },
    {
      Header: t("field.paymentStatus").toUpperCase(),
      Cell: ({ row }) => {
        const { original } = row;
        let { payment_status, payment_status_eng } = original;
        return (
          <TableStatus
            status={
              lang === 'id'
                ? payment_status
                : payment_status_eng
            }
            color={
              payment_status_eng === paymentStatusLounge.Unpaid
                ? "danger"
                : "primary"
            }
          />
        );
      },
    },
    {
      Header: "STATUS",
      Cell: ({ row }) => {
        const { original } = row;
        let { status, status_eng } = original;
        return (
          <TableStatus
            status={
              lang === 'id'
                ? status
                : status_eng
            }
            color={
              status_eng === paymentStatusLounge.New
                ? "danger"
                : status_eng === paymentStatusLounge.Done
                ? "primary"
                : "secondary"
            }
          />
        );
      },
      minWidth: 140,
    },
    {
      Header: "",
      accessor: "id",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <DetailButton>
            <MenuItem
              onClick={() => history.push(`/${currentUrl}/detail/${original?.id}`)}
            >
              <span className="normal-title">{t("commons.detail")}</span>
            </MenuItem>
            {(currentUser.user.role_code === Role.Central_admin ||
              Object.values(RoleIash).includes(currentUser.user.role_code)) &&
              original?.payment_status_eng === paymentStatusLounge.Paid &&
              original?.status_eng === paymentStatusLounge.New && (
              <MenuItem onClick={() => showDoneReservationModal(original)}>
                <span className="normal-title">
                  {t("commons.doneReservation")}
                </span>
              </MenuItem>
            )}
            {currentUser.user.role_code === Role.Central_admin &&
              original?.payment_status_eng === paymentStatusLounge.Unpaid &&
              original?.status_eng === paymentStatusLounge.New && (
              <MenuItem onClick={() => showCancelReservationModal(original)}>
                <span className="normal-title">
                  {t("commons.cancelReservation")}
                </span>
              </MenuItem>
            )}
            {original?.status_eng === paymentStatusLounge.Cancelled && (
              <MenuItem onClick={() => {
                setCancelReason(original?.cancel_reason);
                setCancelReasonModal(!cancelReasonModal);
              }}>
                <span className="normal-title">
                  {t("field.detailCancellation")}
                </span>
              </MenuItem>
            )}
            {currentUser.user.role_code === Role.Central_admin &&
              original?.status_eng === paymentStatusLounge.New && (
              <MenuItem
                onClick={() => showRescheduleModal(original)}
              >
                <span className="normal-title">Reschedule</span>
              </MenuItem>
            )}
          </DetailButton>
        );
      },
    },
  ];

  return (
    <>
      {pending && <Loader fullScreen loading={pending} />}
      <HeaderMenu
        title={t("menuItem.loungeReservation")}
      >
        <SearchButton
          placeholder={t("commons.enterKeyword")}
          toggle={(value) => handleSearch(value)}
        />
        <Button
          title="Filter"
          size="sm"
          rounded={true}
          variant="button-light btn-lg ml-3"
          onClick={handleFilterModal}
        />
        {currentUser.user.role_code !== RoleIash.Cashier_Iash && (
          <>
            <div className="nav-separator mr-3 ml-3" />
            <Button
              title={t("field.newReservation")}
              leftIcon="add"
              rounded={true}
              variant="primary"
              onClick={() => history.push(`${currentUrl}/new-reservation`)}
            />
          </>
        )}
      </HeaderMenu>
      <div className="content-container-side">
        <div className="overflow">
          <Table
            columns={columns.filter((item) => item.show !== false)}
            data={dataBooking ?? []}
            pagination={{ page, totalData, maxPage, toggle: setPage }}
          />
        </div>
      </div>
      <ConfirmationModal
        show={doneReservationModal}
        toggle={showDoneReservationModal}
        confirm={handleDoneReservation}
        messageData={`` + dataBookingRow?.unique_transaction_id}
        icon="info"
        message={`${t("commons.doneBookingConfirm")} `}
        isDelete
        disabledConfirm={loadingSubmit}
      />
      <ConfirmationModal
        show={cancelReservationModal}
        toggle={showCancelReservationModal}
        confirm={handleCancelReservation}
        messageData={`` + dataBookingRow?.unique_transaction_id}
        reason={
          <div className="form-group w-100">
            <label className="form-label">
              {t("field.reasonCancellation")}
            </label>
            <textarea
              className="form-control rounded-5 pl-2"
              value={cancelReason}
              placeholder={`${t("commons.input")} ${t("field.reasonCancellation")}`}
              rows={3}
              onChange={(e) => setCancelReason(e?.target?.value)}
            />
          </div>
        }
        icon="info"
        message={`${t("commons.areYouSureCancel")} ${t("menuItem.loungeReservation")} `}
        isDelete
        disabledConfirm={loadingSubmit}
      />
      <CancelReasonModal
        show={cancelReasonModal}
        toggle={() => setCancelReasonModal(!cancelReasonModal)}
        title={t("field.detailCancellation")}
        content={
          <Card className="w-100 rounded p-4">
            <div className="d-flex">
              <i
                className="material-icons-outlined icon-modal mt-0 mr-3"
              >
                info
              </i>
              <div className="flex flex-column">
                <p className="mb-1">
                  <strong>{t("field.reasonCancellation")}</strong>
                </p>
                <p>
                  {cancelReason}
                </p>
              </div>
            </div>
          </Card>
        }
      />
      <ModalFilter
        show={showModalFilter}
        toggle={() => setShowModalFilter(false)}
        airport={dataAirport}
        lounges={dataLounges}
        user={currentUser?.user?.role_code}
        setSearchLounge={(value) => setSearchLounge(value)}
        filter={paramFilter}
        toggleFilter={(props) => {
          setParamFilter({
            payment_status: props.payment_status,
            status: props.status,
            booking_type: props.booking_type,
            start_date: props.start_date,
            end_date: props.end_date,
            service_start_date: props.service_start_date,
            service_end_date: props.service_end_date,
            airport_id: props.airport_id,
            lounge_id: props.lounge_id,
          });
        }}
        onApplyFilter={onApplyFilter}
      />
      <RescheduleBookingModal
        show={rescheduleModal}
        toggle={showRescheduleModal}
        data={dataRescheduleBooking}
        action={handleRescheduleBooking}
      />
    </>
  );
};

const mapStateToProps = ({ booking: { error, pending, data, data_delete } }) => {
  return { error, pending, data, data_delete };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(LoungeReservation));

import React, { useState, useRef, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ButtonModal from "../../../components/modal/ButtonModal";
import { withTrans } from "../../../i18n/withTrans";
import moment from "moment";
import { Divider } from "@mui/material";

function ModalUsage({ show, toggle, title, data, t }) {
  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 200 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {title}{" "}
          <span className="close clickable" onClick={() => toggle()}>
            &times;
          </span>
        </ModalHeader>
        <ModalBody className="my-0 mx-2">
          {data?.length > 0 ? (
            data?.map((item, index) => (
              <>
                <div className="row mt-4">
                  <div className="col-md-12">
                    Booking ID : <strong> {item?.unique_transaction_id} </strong> 
                  </div>
                </div>
                <div className="row mt-2 mb-4">
                  <div className="col-md-7 justify-content-between">
                    <strong>
                      {item.customer_name ||
                        (item.list_order_passengers?.length > 0
                          ? item.list_order_passengers[0].name
                          : "")}
                    </strong>
                    <span>{item?.lounge_data ? ` - ${item?.lounge_data?.name}` : ""}</span>
                  </div>
                  <div className="col-md-5 text-right">
                    <span>
                      {moment(item.booking_date).format("DD/MM/YYYY, HH:mm")}
                    </span>
                  </div>
                </div>
                <Divider orientation="horizontal" />
              </>
            ))
          ) : (
            <div className="text-center">{t("commons.dataNotFound")}</div>
          )}
        </ModalBody>
        <ModalFooter>
          <ButtonModal
            toggle={() => toggle()}
            hideConfirm
            cancelTitle={t("commons.close")}
          />
        </ModalFooter>
      </Modal>
    </>
  );
}

const mapStateToProps = ({ fileManagement }) => {
  return { fileManagement };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(ModalUsage)
);

import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import {
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";

import { Formik, Form } from "formik";
import { withTrans } from "../../i18n/withTrans";
import * as Yup from "yup";
import Select2 from "../../components/forms/Select2";
import ButtonModal from "../../components/modal/ButtonModal";
import Input from "../../components/forms/Input";
import DynamicButton from "../../components/forms/DynamicButton";
import moment from "moment";

const ModalData = ({
    t,
    title,
    show,
    toggle,
    action,
    data,
    data_post,
    data_put,
    error_message
}) => {
    const formikRef = useRef();
    const [loadingSubmit, setLoadingSubmit] = useState(false);

    const validation = Yup.object().shape({
        start_date: Yup.string().required(`${t("promo.startDate")} ${t("commons.required")}`).nullable(),
        end_date: Yup.string().required(`${t("promo.endDate")} ${t("commons.required")}`).nullable(),
        type: Yup.string().required(t("commons.required")).nullable(),
        price: Yup.number().required(`${t("field.price")} ${t("commons.required")}`).nullable(),
    });

    const handleClose = () => {
        toggle();
    };

    const rawPrice = (val) => {
        if (val) {
            return Number(val.toString().replace(/[^0-9\.-]+/g, ""));
        }
        return val
    }

    useEffect(() => {
        if (!error_message) return;

        setLoadingSubmit(false);
    }, [error_message]);

    useEffect(() => {
        if (data_post?.data?.status === 200) {
            setLoadingSubmit(false);
        }
    }, [data_post]);

    useEffect(() => {
        if (data_put?.data?.status === 200) {
            setLoadingSubmit(false);
        }
    }, [data_put]);

    return (
        <Modal
            isOpen={show}
            modalTransition={{ timeout: 700 }}
            backdropTransition={{ timeout: 1300 }}
        >
            <ModalHeader className="text-extra-bold pt-5">
                {" "}
                {title}{" "}
                <span className="close clickable" onClick={() => handleClose()}>
                    &times;
                </span>
            </ModalHeader>
            <ModalBody style={{ margin: "0px 20px" }}>
                <Formik
                    enableReinitialize
                    innerRef={formikRef}
                    initialValues={{
                        id: data?.id ?? null,
                        start_date: data?.start_date ? moment(data?.start_date).format("YYYY-MM-DD") : "",
                        end_date: data?.end_date ? moment(data?.end_date).format("YYYY-MM-DD") : "",
                        type: data?.type ?? "",
                        price: data?.price ?? "",
                    }}
                    validationSchema={validation}
                    onSubmit={(values) => {
                        let val = {
                            id: values?.id ?? null,
                            start_date: moment(values.start_date).format("YYYY-MM-DD HH:mm:ss"),
                            end_date: moment(values.end_date).format("YYYY-MM-DD HH:mm:ss"),
                            type: values.type,
                            price: rawPrice(values.price)
                        };
                        setLoadingSubmit(true);
                        action(val);
                    }}
                >
                    {(props) => (
                        <Form style={{ marginTop: 30 }}>
                            <Input
                                title={t("promo.startDate")}
                                name="start_date"
                                type="date"
                                errors={props.errors}
                                touched={props.touched}
                                value={props.values?.start_date}
                                {...props?.getFieldProps("start_date")}
                            />
                            <Input
                                title={t("promo.endDate")}
                                name="end_date"
                                type="date"
                                min={props.values?.start_date}
                                errors={props.errors}
                                touched={props.touched}
                                value={props.values?.end_date}
                                {...props?.getFieldProps("end_date")}
                            />
                            <Select2
                                title={`${t('commons.type')}`}
                                name="type"
                                options={[
                                    {
                                        value: 'Individual',
                                        label: 'Individual',
                                    },
                                    {
                                        value: 'Group',
                                        label: 'Group (Charter, Hajj, Umrah)',
                                    },
                                    {
                                        value: 'Other',
                                        label: `Group ${t('masterData.others')}`,
                                    },
                                    {
                                        value: 'Lounge',
                                        label: `${t('field.reservation')} Lounge`,
                                    },
                                ]}
                                errors={props.errors}
                                touched={props.touched}
                                value={props.values?.type}
                                placeholder={t('commons.type')}
                                onChange={(name, value) => {
                                    props.setFieldValue("type", value);
                                    props.setFieldValue("price", "");
                                }}
                                onBlur={props.setFieldTouched}
                            />
                            {
                                props.values.type !== null && props.values.type !== undefined && props.values.type !== "" && (
                                    <React.Fragment>
                                        <DynamicButton
                                            titleLeft={
                                                props.values.type === "Group"
                                                    ? t("masterData.groupInfo")
                                                : props.values.type === "Other"
                                                    ? t("masterData.otherInfo")
                                                : props.values.type === "Lounge"
                                                    ? t("masterData.loungeCommissionInfo")
                                                : t("masterData.individualInfo")
                                            }
                                            iconLeft="info"
                                        />
                                    </React.Fragment>
                                )
                            }
                            {
                                props.values.type !== "" && (
                                    <Input
                                        currency
                                        name="price"
                                        errors={props.errors}
                                        touched={props.touched}
                                        value={props.values.price ?? 0}
                                        {...props.getFieldProps("price")}
                                        title={t("field.price")}
                                        placeholder={t("field.price")}
                                        type="text"
                                        toggleCurrency={props}
                                    />
                                )
                            }
                            <div style={{ marginTop: 50, marginBottom: 30 }}>
                                <ButtonModal
                                    toggle={toggle}
                                    confirmTitle={t("commons.save")}
                                    disabledConfirm={loadingSubmit}
                                />
                            </div>

                        </Form>
                    )}
                </Formik>
            </ModalBody>

        </Modal>
    )
}

const mapStateToProps = ({ commission: { data_post, data_put, error_message } }) => {
    return { data_post, data_put, error_message };
};

const mapDispatchToProps = () => {
    return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(ModalData));


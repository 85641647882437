import React from "react";
import { getUser } from "../../../utils/User";
import { useState, useEffect } from "react";
import { withTrans } from "../../../i18n/withTrans";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import MenuItem from "@mui/material/MenuItem";

import ModalFilter from "./modalFilter";
import RegularModal from "./FormPromoModal";
import DetailPromoModal from "./DetailPromoModal";
import Table from "../../../components/table";
import Button from "../../../components/buttons/Button";
import Loader from "../../../components/commons/Loader";
import TableStatus from "../../../components/table/status";
import DetailButton from "../../../components/table/detailButton";
import SearchButton from "../../../components/buttons/SearchButton";
import HeaderMenu from "../../../components/commons/menu/HeaderMenu";
import ConfirmationModal from "../../../components/modal/ConfirmModal";
import LabelPlus from "../../../components/table/labelPlus";

// Service
import PromoService from "../../../store/actions/promo";
import AirportService from "../../../store/actions/master_data/airport";
import ProductService from "../../../store/actions/master_data/product";

const Promo = ({ t, pending }) => {
  const dispatch = useDispatch();
  const currentUser = getUser();

  const [dataAirport, setDataAirport] = useState([]);
  const [dataProduct, setDataProduct] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [dataPromo, setDataPromo] = useState([]);
  const [dataPromoRow, setDataPromoRow] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [totalData, setTotalData] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [paramFilter, setParamFilter] = useState({
    airport_id:
      currentUser?.user.role_code === "admin_bandara" ||
      currentUser?.user.role_code === "supervisor" 
        ? currentUser?.user.airport_id
        : "",
    product_id: "",
    status: "",
    type: "",
    start_date: "",
    end_date: "",
  });

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 9999,
        is_service: true,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      setDataAirport(res?.data);
    });
  }, []);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 9999,
      };
      dispatch(ProductService.get(param, resolve));
    }).then((res) => {
      setDataProduct(res?.data);
    });
  }, []);

  const handleSearch = (value) => {
    setSearchText(value.length > 0 ? value : "");
  };

  const showAddModal = () => {
    setAddModal(!addModal);
  };

  const handleAdd = (param) => {
    const callback = () => {
      handleRefresh();
      setAddModal(!addModal);
    };
    dispatch(PromoService.post(param, callback));
  };

  const handleEdit = (param) => {
    param.airport_ids = param.airport_ids.split(",");
    param.list_details.map((item, index) => {
      if (item.product_name === "Meet and Greet") {
        item.price = param.meet_and_greet.toString().includes("Rp")
          ? Number(param.meet_and_greet.replace(/[^0-9\.-]+/g, ""))
          : param.meet_and_greet;
      } else if (item.product_name === "Fast Track") {
        item.price = param.fast_track.toString().includes("Rp")
          ? Number(param.fast_track.replace(/[^0-9\.-]+/g, ""))
          : param.fast_track;
      } else if (item.product_name === "Transfer") {
        item.price = param.transfer.toString().includes("Rp")
          ? Number(param.transfer.replace(/[^0-9\.-]+/g, ""))
          : param.transfer;
      } else if (item.product_name === "Check In and Baggage") {
        item.price = param.checkin_and_baggage.toString().includes("Rp")
          ? Number(param.checkin_and_baggage.replace(/[^0-9\.-]+/g, ""))
          : param.checkin_and_baggage;
      }
    });

    const callback = () => {
      handleRefresh();
      setEditModal(!editModal);
    };
    dispatch(PromoService.put(param, callback));
  };

  const showDeleteModal = (data) => {
    setDeleteModal(!deleteModal);
    setDataPromoRow(data ? data : []);
  };

  const showDetailModal = (data) => {
    setDetailModal(!detailModal);
    setDataPromoRow(data ? data : []);
  };

  const handleDelete = (id) => {
    const callback = () => {
      handleRefresh();
      setPage(1);
      setDeleteModal(!deleteModal);
    };
    if (dataPromoRow) {
      const id = dataPromoRow?.id;
      dispatch(PromoService.del(id, callback));
    }
  };

  const columns = [
    {
      Header: "No",
      accessor: "no",
    },
    {
      Header: t("promo.promoName").toUpperCase(),
      accessor: "name",
    },
    {
      Header: t("promo.promoCode").toUpperCase(),
      accessor: "code",
    },
    {
      Header: t("promo.promoType").toUpperCase(),
      accessor: "type",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const type =
          original?.type === "blast"
            ? original?.type?.charAt(0).toUpperCase() + original?.type?.slice(1)
            : original?.type === "cross-selling"
            ? t("field.cross_selling")
            : t("promo.special");
        return `${type}`;
      },
    },
    {
      Header: t("field.airport").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const idRow = original.id;
        return original?.list_airports_detail?.length 
          ? <LabelPlus data={original?.list_airports_detail} idRow={idRow} /> 
          : "-";
      },minWidth: 250
    },
    {
      Header: t("promo.typePeriodPromo").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.is_service_date_period 
          ? t("report.serviceDate")
          : t("report.bookingDate");
      },
    },
    {
      Header: t("promo.startDate").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const date = moment(original?.period_start).format("DD/MM/YYYY");
        return `${date}`;
      },
    },
    {
      Header: t("promo.endDate").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const date = moment(original?.period_end).format("DD/MM/YYYY");
        return `${date}`;
      },
    },
    {
      Header: "STATUS",
      Cell: ({ row }) => {
        const { original } = row;
        let { valid } = original;
        return (
          <TableStatus
            status={valid === true ? t("promo.active") : t("promo.nonactive")}
            color={valid === true ? "primary" : "warning"}
          />
        );
      },
    },
    {
      Header: "",
      accessor: "id",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <DetailButton>
            <MenuItem onClick={() => showDetailModal(original)}>
              <span style={{ fontSize: 14 }}>{t("commons.detail")}</span>
            </MenuItem>
            {(currentUser?.user?.role_code !== "contact_center" &&
              currentUser?.user?.role_code !== "lead_contact_center" &&
              currentUser?.user?.role_code !== "supervisor") &&
            (
              <MenuItem onClick={() => showDeleteModal(original)}>
                <span style={{ fontSize: 14 }}>{t("commons.delete")}</span>
              </MenuItem>
            )}
          </DetailButton>
        );
      },
    },
  ];

  const handleRefresh = () => {
    new Promise((resolve) => {
      let param = {
        page: page === 0 ? 1 : page ?? 1,
        length: 10,
        search_text: searchText,
        manage_promo: true,
        airport_id: paramFilter?.airport_id !== "" ? paramFilter?.airport_id : null,
        product_id: paramFilter?.product_id !== "" ? paramFilter?.product_id : null,
        valid: paramFilter?.status !== "" ? paramFilter?.status : "",
        type: paramFilter?.type !== "" ? paramFilter?.type : "",
        start_date:
          paramFilter?.start_date !== ""
            ? moment(paramFilter?.start_date).format("YYYY-MM-DD")
            : "",
        end_date:
          paramFilter?.end_date !== ""
            ? moment(paramFilter?.end_date).format("YYYY-MM-DD")
            : "",
      };
      dispatch(PromoService.get(param, resolve));
    }).then((res) => {
      setDataPromo(res?.data);
      setTotalData(res?.totalCount);
      setMaxPage(Math.ceil(res?.totalCount / 10));
    });
  };

  const onApplyFilter = (param) => {
    setParamFilter({
      airport_id: param.airport_id,
      product_id: param.product_id,
      end_date: param.end_date ? moment(param.end_date).format("YYYY-MM-DD") : "",
      start_date: param.start_date ? moment(param.start_date).format("YYYY-MM-DD") : "",
      status: param.status,
      type: param.type,
    });
    setShowModalFilter(false);
  };

  const handleFilterModal = () => {
    setShowModalFilter(true);
  };

  useEffect(() => {
    handleRefresh();
  }, [page, searchText, paramFilter]);

  useEffect(() => {
    setPage((prev) => (prev === null ? null : 1));
  }, [searchText, paramFilter]);

  return (
    <>
      {pending && <Loader fullScreen loading={pending} />}
      <HeaderMenu title="Promo">
        <SearchButton
          placeholder={t("commons.enterKeyword")}
          toggle={(value) => handleSearch(value)}
        />
        <Button
          title="Filter"
          size="sm"
          rounded={true}
          variant="button-light btn-lg ml-3"
          onClick={handleFilterModal}
        />
        <div className="nav-separator mr-3 ml-3" />

        {(currentUser?.user?.role_code !== "contact_center" &&
          currentUser?.user?.role_code !== "lead_contact_center" &&
          currentUser?.user?.role_code !== "supervisor") &&
        (
          <Button
            title={`${t("commons.add")} Promo`}
            leftIcon="add"
            rounded={true}
            variant="primary"
            onClick={showAddModal}
          />
        )}
      </HeaderMenu>
      <div className="content-container-side">
        <div className="overflow">
          <Table
            columns={columns}
            data={dataPromo ?? []}
            pagination={{ page, totalData, maxPage, toggle: setPage }}
          />
        </div>
      </div>
      <ConfirmationModal
        show={deleteModal}
        toggle={showDeleteModal}
        confirm={handleDelete}
        messageData={`` + dataPromoRow?.name}
        icon="info"
        message={t("commons.areYouSureDelete")}
        isDelete
      />
      <DetailPromoModal
        show={detailModal}
        toggle={showDetailModal}
        data={dataPromoRow}
        dataAirport={dataAirport}
        dataProduct={dataProduct}
        detailModal={setDetailModal}
        handleEdit={handleEdit}
        refresh={handleRefresh}
      />
      <RegularModal
        show={addModal}
        toggle={showAddModal}
        action={handleAdd}
        refresh={handleRefresh}
        dataAirport={dataAirport}
        dataProduct={dataProduct}
      />
      <ModalFilter
        show={showModalFilter}
        toggle={() => setShowModalFilter(false)}
        dataAirport={dataAirport}
        dataProduct={dataProduct}
        filter={paramFilter}
        toggleFilter={(props) => {
          setParamFilter({
            status: props.status,
            type: props.type,
            start_date: props.start_date,
            end_date: props.end_date,
            airport_id: props.airport_id,
            product_id: props.product_id,
          });
        }}
        onApplyFilter={onApplyFilter}
      />
    </>
  );
};

const mapStateToProps = ({ promo: { pending, data } }) => {
  return { pending, data };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(Promo));

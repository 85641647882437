import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Row, Col } from "reactstrap";

import Card from "../../../components/cards/card";
import CardHeader from "../../../components/cards/cardHeader";
import CardBody from "../../../components/cards/cardBody";
import Select2 from "../../../components/forms/Select2";
import Input from "../../../components/forms/Input";
import { withTrans } from "../../../i18n/withTrans";
import { exceptionSymbolNumbering, numberWithCommas } from "../../../utils/Constants";
import { getErrorMessage, getLengthPassAdult, getLengthPassChild, getLengthPassInfant } from "../../../utils/Helper";

import AirportService from "../../../store/actions/master_data/airport";
import Booking from "../../../store/actions/booking";
import { toastError } from "../../../components/commons/toast";

const AdtServiceReservation = ({ formik, button, t }) => {
  const dispatch = useDispatch();
  const lang = localStorage.getItem('joumpa_language');

  const [dataTransports, setDataTransports] = useState([]);
  const [dataCars, setDataCars] = useState([]);
  const [showTransports, setShowTransports] = useState(false);
  const [showAssistant, setShowAssistant] = useState(false);
  
  function toggleTransportation() {
    setShowTransports((showTransports) => !showTransports);
  }

  function toggleAssistant() {
    setShowAssistant((showAssistant) => !showAssistant);
  }

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 9999,
        id: formik?.values?.flight_type === 1
          ? formik?.values?.airport_id_from
          : formik?.values?.airport_id_to,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      if (!res?.data?.length) return;

      setDataTransports(res?.data[0]?.transports);

      if (formik?.values?.region && formik?.values.orderExtras.transport.id !== "") {
        const idTransport = formik?.values.orderExtras.transport.id;
        const index = res?.data[0]?.transports?.map((e) => e.id).indexOf(idTransport);
        const cars = res?.data[0]?.transports[index]?.prices.map((item) => ({
          label: (
            <>
              <div className="d-flex justify-content-between">
                <div className="normal-title">{item.car_name}</div>
                <div className="normal-title text-extra-bold">
                  {`${item?.capacity} ${t("field.person")}`}
                </div>
                <div className="normal-title text-extra-bold">
                  {item?.price == null ? "Rp 0" : "Rp " + numberWithCommas(item.price)}
                </div>
              </div>
            </>
          ),
          value: item.id,
          name: item.car_name,
          price: item.price,
        }));
        setDataCars(cars);
      }
    });
  }, []);

  const region_list = dataTransports.map((item) => {
    return item.region_list.split(",").map((el) => ({
      label: el,
      value: el,
    }))
  }).flat();

  const findAvailabilityJoumpaAssistant = (selectedProductName, selectedProductType) => {
    let values = formik.values;
    const adult_total = getLengthPassAdult(values?.passenger_list);
    const child_total = getLengthPassChild(values?.passenger_list);
    const infant_total = getLengthPassInfant(values?.passenger_list);

    new Promise((resolve, reject) => {
      if (
        values.airport_id_from &&
        values.airport_id_to &&
        values?.flight_type_code &&
        values?.date &&
        selectedProductType
      ) {
        let param = {
          airport_service_id: values?.flight_type === 1 ? values?.airport_id_from : values?.airport_id_to,
          flight_type: values?.flight_type_code,
          service_date: values?.date,
          product_name: selectedProductType,
          adult_total: values?.passenger_list?.length ? adult_total : null,
          child_total: values?.passenger_list?.length ? child_total : null,
          infant_total: values?.passenger_list?.length ? infant_total : null,
        };
        dispatch(Booking.findAvailPrice(param, resolve, reject));
      }
    }).then((res) => {
      if (!res) return;

      if (res?.data) {
        const product_list = res?.data?.period_list[0]?.product_list[0];

        formik.setFieldValue(
          "period_id",
          res?.data?.period_list[0]?.id
        );
        formik.setFieldValue(
          "product_list", 
          product_list
        );
        formik.setFieldValue(
          "product_list_id", 
          product_list?.id
        );

        if (product_list?.group_prices?.length) {
          formik.setFieldValue(
            "group_price_id",
            product_list?.group_prices[0]?.id
          );
          formik.setFieldValue("price_id", null);
        } else {
          formik.setFieldValue(
            "price_id",
            product_list?.prices?.id
          );
          formik.setFieldValue("group_price_id", null);
        }

        formik.setFieldValue("available_service", selectedProductName);
        formik.setFieldValue("product_type", selectedProductType);
      }
    }).catch((err) => {
      toastError(
        getErrorMessage(lang === 'en-US' ? 'en' : lang, err?.data)
      );
    });
  };

  const handleProductSelected = (item) => {
    let productType = formik.values.product_type;
    let selectedType = item?.type;
    let selectedName = item?.name;

    if (productType === selectedType) {
      formik.setFieldValue("available_service", null);
      formik.setFieldValue("product_type", null);
      formik.setFieldValue("orderExtras.transport", null);
      formik.setFieldValue("car_type", null);
      formik.setFieldValue("region", null);
      formik.setFieldValue("total_unit", null);
      return;
    }

    findAvailabilityJoumpaAssistant(selectedName, selectedType);
  };

  return (
    <Row>
      <Col md={9}>
        <Card className="rounded">
          <CardHeader title={t("field.additionalService")} />
          <CardBody>
            <div className={`wrapper-detail-card d-flex flex-column p-3 ${formik?.values?.isGoShow ? 'disabled' : ''}`}>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex flex-column">
                  <div className="text-extra-bold">
                    Joumpa {t("field.assistant")}
                  </div>
                  <div className="my-2 text-medium">
                    {t("booking.assistantDescription")}
                  </div>
                </div>
                {!formik?.values?.isGoShow && (
                  <div className='my-2 clickable' onClick={toggleAssistant}>
                    <span className="material-icons-round">
                      expand_more
                    </span>
                  </div>
                )}
              </div>
              {showAssistant && (
                <div className="p-3 pt-0">
                {formik?.values?.list_available_services?.map((item, index) => {
                  return (
                    <React.Fragment key={`service-${index}`}>
                      <div
                        className="clickable"
                        onClick={() => handleProductSelected(item)}
                      >
                        <Card rounded>
                          <CardBody
                            isActive={item?.type === formik?.values.product_type}
                          >
                            <div>
                              <span
                                className={
                                  item?.type === formik?.values.product_type
                                    ? "card-header"
                                    : "card-header-inactive"
                                }
                              >
                                <span
                                  className="material-icons-round button-icon-round"
                                >
                                  {item?.type === formik?.values.product_type
                                    ? "done"
                                    : "info"}
                                </span>
                                <div>
                                  {item?.label}
                                  <div className="card-text">
                                    {t(
                                      `product.${
                                        item?.type === 3
                                          ? "detailFTDesc"
                                          : item?.type === 4
                                          ? "detailTransferDesc"
                                          : "detailMnGDesc"
                                      }`
                                    )}
                                  </div>
                                </div>
                              </span>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </React.Fragment>
                  );
                })}
                </div>
              )}
              <div className="d-flex mt-auto normal-text align-items-center">
                <span className="material-icons mr-2" style={{ width: '24px' }}>
                  info_outlined
                </span>
                {t("commons.order_assistant_message")}
              </div>
            </div>
            {formik?.values?.product_type ? (
              <div className="wrapper-detail-card p-3">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex flex-column">
                    <div className="text-extra-bold">
                      {t("field.transportation")}
                    </div>
                    <div className="my-2 text-medium">
                      {t("booking.transportatonDescription")}
                    </div>
                  </div>
                  <div className="my-2 clickable" onClick={toggleTransportation}>
                    <span className="material-icons-round">
                      expand_more
                    </span>
                  </div>
                </div>
                {showTransports && (
                  <div className="p-3 pt-0">
                    <Select2
                      clearable={
                        !!formik.values?.region && !formik.values?.car_type
                      }
                      name="region"
                      errors={formik.errors}
                      title={t("field.region")}
                      touched={formik.touched}
                      options={region_list}
                      value={formik.values?.region}
                      onChange={(name, value) => {
                        formik.setFieldValue("region", value);
                        const index = dataTransports?.findIndex((item) => {
                          return item?.region_list?.split(",")?.some((region) => {
                            return region === value;
                          });
                        });
                        if (index > -1) {
                          const transport = {
                            name: value ? region_list[index].label : "",
                            id: value ? dataTransports[index].id : "",
                          };
                          formik.setFieldValue(
                            "orderExtras.transport",
                            transport
                          );
                          const cars = value
                            ? dataTransports[index].prices.map((item) => ({
                                label: (
                                  <>
                                    <div className="d-flex justify-content-between">
                                      <div className="normal-title">
                                        {item.car_name}
                                      </div>
                                      <div className="normal-title text-extra-bold">
                                        {`${item?.capacity} ${t("field.person")}`}
                                      </div>
                                      <div className="normal-title text-extra-bold">
                                        {item?.price == null
                                          ? "Rp 0"
                                          : "Rp " + numberWithCommas(item.price)}
                                      </div>
                                    </div>
                                  </>
                                ),
                                value: item.id,
                                name: item.car_name,
                                capacity: item.capacity,
                                price: item.price,
                              }))
                            : "";
                          setDataCars(cars);
                        }
                      }}
                      onBlur={formik.setFieldTouched}
                    />
                    <Select2
                      clearable={!!formik.values?.car_type}
                      name="car_type"
                      errors={formik.errors}
                      title={t("field.carType")}
                      touched={formik.touched}
                      options={dataCars}
                      value={formik.values?.car_type}
                      onChange={(name, value) => {
                        const index = dataCars
                          ?.map(function (e) {
                            return e.value;
                          })
                          .indexOf(value);
                        const car = {
                          name: value ? dataCars[index].name : "",
                          price: value ? dataCars[index].price : "",
                          id: value ? dataCars[index].value : "",
                          capacity: value ? dataCars[index].capacity : "",
                        };
                        formik.setFieldValue("orderExtras.car", car);
                        formik.setFieldValue("car_type", value);
                      }}
                      onBlur={formik.setFieldTouched}
                    />
                    <Input
                      title={t("field.totalUnit")}
                      name="total_unit"
                      type="number"
                      min={0}
                      errors={formik.errors}
                      touched={formik.touched}
                      {...formik.getFieldProps("total_unit")}
                      onKeyDown={(e) =>
                        exceptionSymbolNumbering.includes(e.key) && e.preventDefault()
                      }
                    />
                  </div>
                )}
              </div>
            ) : <></>}
          </CardBody>
        </Card>
        {button()}
      </Col>
    </Row>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(AdtServiceReservation)
);

import React from "react";
import { useState, useEffect } from "react";
import { withTrans } from "../../../i18n/withTrans";
import { connect, useDispatch } from "react-redux";

import HeaderMenu from "../../../components/commons/menu/HeaderMenu";
import DetailButton from "../../../components/table/detailButton";
import MenuItem from "@mui/material/MenuItem";
import Loader from "../../../components/commons/Loader";
import Button from "../../../components/buttons/Button";
import SearchButton from "../../../components/buttons/SearchButton";
import Table from "../../../components/table";
import LabelPlus from "../../../components/table/labelPlus";

// Service
import UserService from "../../../store/actions/master_data/user_lounge";
import UserModal from "./FormUserModal";
import ConfirmationModal from "../../../components/modal/ConfirmModal";
import SelectFilter from "../../../components/forms/SelectFilter";
import { IashRoleList } from "../../../utils/Constants";

const Index = ({ t, pending }) => {
  const dispatch = useDispatch();

  const [formModal, setFormModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [dataUser, setDataUser] = useState([]);
  const [dataUserRow, setDataUserRow] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [role, setRole] = useState("");
  const [totalData, setTotalData] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const handleSearch = (value) => {
    setSearchText(value.length > 0 ? value : "");
  };

  const handleFilterStatus = (value) => {
    if (value) {
      setRole(value?.value);
    }
  };

  const showFormModal = (data) => {
    setFormModal(!formModal);
    setDataUserRow(data);
  };

  const handleEdit = (param) => {
    const callback = () => {
      handleRefresh();
      setFormModal(!formModal);
    };
    dispatch(UserService.put(param, callback));
  };

  const handleAdd = (param) => {
    const callback = () => {
      handleRefresh();
      setFormModal(!formModal);
    };
    dispatch(UserService.post(param, callback));
  };

  const showDeleteModal = (data) => {
    setDeleteModal(!deleteModal);
    setDataUserRow(data ? data : []);
  };

  const handleDelete = () => {
    const callback = () => {
      setPage(1);
      handleRefresh();
      setDeleteModal(!deleteModal);
    };
    if (dataUserRow) {
      const id = dataUserRow?.id;
      const name = dataUserRow?.name;
      dispatch(UserService.del(id, callback, name));
    }
  };

  const columns = [
    {
      Header: "NO.",
      accessor: "no",
    },
    {
      Header: t("field.name").toUpperCase(),
      accessor: "name",
    },
    {
      Header: "EMAIL",
      accessor: "email",
    },
    {
      Header: "ROLE",
      accessor: "role_name",
    },
    {
      Header: t("field.airport").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const idRow = original.id;
        return original?.airport_list?.length 
          ? <LabelPlus data={original?.airport_list} idRow={`airport${idRow}`} /> 
          : "-";
      },
      minWidth: 250
    },
    {
      Header: "",
      accessor: "id",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <DetailButton>
            <MenuItem onClick={() => showFormModal(original)}>
              <span className="normal-title">{t("commons.edit")}</span>
            </MenuItem>
            <MenuItem onClick={() => showDeleteModal(original)}>
              <span className="normal-title">{t("commons.delete")}</span>
            </MenuItem>
          </DetailButton>
        );
      },
    },
  ];

  const handleRefresh = () => {
    new Promise((resolve) => {
      let param = {
        page: page === 0 ? 1 : page ?? 1,
        length: 10,
        role_code: role,
        search_text: searchText,
      };
      dispatch(UserService.get(param, resolve));
    }).then((res) => {
      setDataUser(res?.data);
      setTotalData(res?.totalCount);
      setMaxPage(Math.ceil(res?.totalCount / 10));
    });
  };

  useEffect(() => {
    handleRefresh();
  }, [page, searchText, role]);

  useEffect(() => {
    setPage((prev) => (prev === null ? null : 1));
  }, [searchText, role]);

  return (
    <>
      {pending && <Loader sideContent loading={pending} />}
      <HeaderMenu title={`${t("field.user")} IASH`}>
        <SearchButton
          placeholder={t("commons.enterKeyword")}
          toggle={(value) => handleSearch(value)}
        />
        <SelectFilter
          options={[
            {
              value: "",
              label: `${t("commons.all")} Role`,
            },
            ...IashRoleList
          ]}
          placeholder="Role"
          value={role}
          onChange={(value) => handleFilterStatus(value)}
          toggle={(value) => handleFilterStatus(value)}
        />
        <div className="nav-separator mr-3 ml-3" />
        <Button
          title={t("commons.add") + " " + t("field.user")}
          leftIcon="add"
          rounded={true}
          variant="primary"
          onClick={() => showFormModal(null)}
        />
      </HeaderMenu>
      <div className="content-container-side">
        <div className="overflow">
          <Table
            columns={columns?.filter((item) => item.show !== false)}
            data={dataUser ?? []}
            pagination={{ page, totalData, maxPage, toggle: setPage }}
          />
        </div>
      </div>

      <UserModal
        show={formModal}
        toggle={showFormModal}
        handleAdd={handleAdd}
        handleEdit={handleEdit}
        data={dataUserRow}
      />

      <ConfirmationModal
        show={deleteModal}
        toggle={showDeleteModal}
        confirm={handleDelete}
        param={`` + dataUserRow?.name}
        icon="info"
        isDelete
      />
    </>
  );
};

const mapStateToProps = ({ user_lounge: { pending, data } }) => {
  return { pending, data };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(Index));

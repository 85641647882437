import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Row, Col } from "reactstrap";
import { Divider } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import ReactTooltip from "react-tooltip";
import moment from "moment";

import { withTrans } from "../../i18n/withTrans";
import { getUser } from "../../utils/User";
import { history } from "../../utils/History";
import { numberWithCommas, payment_gateway, paymentStatusLounge, paymentTypes, promoLoungeType, Role, RoleIash, themeColor } from "../../utils/Constants";
import { getContactInfoBooking, getLengthPassAdult, getLengthPassChild, getLengthPassInfant } from "../../utils/Helper";

import Loader from "../../components/commons/Loader";
import Card from "../../components/cards/card";
import CardFlush from "../../components/cards/cardFlush";
import DetailButton from "../../components/table/detailButton";
import Table from "../../components/table";
import ConfirmationModal from "../../components/modal/ConfirmModal";
import PaymentProofModal from "../../components/modal";
import SummaryCheckoutPrice from "../../components/summaryCheckoutPrice";
import EditPassengerModal from "./modal/ModalEditPassenger";
import EditBookerModal from "./modal/ModalEditBooker";
import IconButton from "../../components/buttons/IconButton";
import UploadPaymentModal from "./modal/UploadPaymentModal";

import ReservationLounge from "../../store/actions/reservation_lounge";

const DetailReservation = ({ data, error, t, pending, match }) => {
  const dispatch = useDispatch();
  const currentUrl = match?.url?.split("/")?.[1];
  const lang = localStorage.getItem('joumpa_language');
  const currentUser = getUser();

  const [dataDetail, setDataDetail] = useState("");
  const [dataDetailJoumpa, setDataDetailJoumpa] = useState([]);
  const [dataPassengerAdult, setDataPassengerAdult] = useState(0);
  const [dataPassengerChild, setDataPassengerChild] = useState(0);
  const [dataPassengerInfant, setDataPassengerInfant] = useState(0);
  const [showPriceService, setShowPriceService] = useState(false);
  const [showLounge, setShowLounge] = useState(false);
  const [dataPassengerRow, setDataPassengerRow] = useState([]);
  const [editBookerModal, setEditBookerModal] = useState(false);
  const [editPassengerModal, setEditPassengerModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [paymentProof, setPaymentProof] = useState(false);
  const [uploadPaymentProof, setUploadPaymentProof] = useState(false);

  const handleRefresh = () => {
    new Promise((resolve) => {
      const param = {
        length: 1,
        page: 1,
        id: match?.params?.id,
      };
      dispatch(ReservationLounge.get(param, resolve));
    }).then((res) => {
      setDataDetail(res?.data[0]);
      setDataPassengerAdult(getLengthPassAdult(res?.data[0]?.list_order_passengers));
      setDataPassengerChild(getLengthPassChild(res?.data[0]?.list_order_passengers));
      setDataPassengerInfant(getLengthPassInfant(res?.data[0]?.list_order_passengers));
      setDataDetailJoumpa(res?.data[0]?.joumpa_service_data);
    });
  };

  useEffect(() => {
    handleRefresh();
  }, []);

  const toggleLounge = () => {
    setShowLounge(!showLounge);
  };

  const togglePriceService = () => {
    setShowPriceService(!showPriceService);
  };

  const formatPhoneNumber = (phone, countryCode) => {
    if (!phone || phone.includes("undefined") || phone === countryCode) {
      return { phone: "", countryCode: "" };
    }
  
    let cleanedPhone = phone.replace("+", "").replace(countryCode, "");
    if (cleanedPhone.startsWith("0")) {
      cleanedPhone = cleanedPhone.substring(1);
    }
  
    return {
      phone: cleanedPhone,
      countryCode: `+${countryCode}`,
    };
  };

  const showEditPassengerModal = (data) => {
    setEditPassengerModal(!editPassengerModal);
    setDataPassengerRow(data ? data : []);
  };

  const handleEditPassenger = (param) => {
    const callback = () => {
      setEditPassengerModal(!editPassengerModal);
      handleRefresh();
    };

    const { phone, countryCode } = formatPhoneNumber(param.phoneNumber, param.countryCode);

    param.phoneNumber = phone;
    param.countryCode = countryCode;

    if (param) {
      dispatch(ReservationLounge.editPassenger(param, callback));
    }
  };

  const handleEditBooker = (param) => {
    const callback = () => {
      setEditBookerModal(!editBookerModal);
      handleRefresh();
    };

    const { phone, countryCode } = formatPhoneNumber(param.bookers_phone, param.bookers_country_code);

    param.bookers_phone = phone;
    param.bookers_country_code = countryCode;

    if (param) {
      dispatch(ReservationLounge.editBooker(param, callback));
    }
  };

  const showPaymentProof = () => {
    setPaymentProof(!paymentProof);
  };

  const showUploadPaymentModal = () => {
    setUploadPaymentProof(!uploadPaymentProof);
  };

  const deletePayment = () => {
    const callback = () => {
      setDeleteModal(!deleteModal);
      handleRefresh();
    };

    dispatch(ReservationLounge.delPayment({ id: match?.params?.id }, callback));
  };

  const columnPassengers = [
    {
      Header: "No",
      Cell: ({ row }) => {
        return row?.index + 1;
      },
    },
    {
      Header: t("field.passenger"),
      Cell: ({ row }) => {
        const { original } = row;
        return original?.name ?? "-";
      },
    },
    {
      Header: t("field.nationality"),
      Cell: ({ row }) => {
        const { original } = row;
        return original?.passenger_nationality ?? "-";
      },
    },
    {
      Header: t("field.email"),
      Cell: ({ row }) => {
        const { original } = row;
        return original?.email ?? "-";
      },
    },
    {
      Header: t("field.phone"),
      Cell: ({ row }) => {
        const { original } = row;
        return original?.phone_number && original?.country_code 
          ? `${original?.country_code}${original?.phone_number}` 
          : original?.phone_number && !original?.country_code 
          ? original?.phone_number
          : "-";
      },
    },
    {
      Header: "",
      accessor: "actions",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <DetailButton>
            <MenuItem onClick={() => showEditPassengerModal(original)}>
              <span className="normal-title">{t("commons.edit")}</span>
            </MenuItem>
          </DetailButton>
        );
      },
      show: currentUser.user.role_code === Role.Central_admin,
    },
  ];

  return (
    <div className="menu-container">
      {pending && <Loader fullScreen loading={pending} />}
      <div className="grid-header">
        <div className="side-menu">
          <div className="menu-title text-extra-bold">
            <div className="side-menu-back-icon ">
              <span
                className="material-icons clickable"
                onClick={() => history.push(`/${currentUrl}`)}
              >
                arrow_back
              </span>
            </div>
            {`Detail ${t("field.booking")}`}
          </div>
          <div className="pt-0 pl-2 pr-3 pb-3">
            <Card className="rounded">
              <CardFlush
                sideMenu
                title={t("field.lounge")}
                desc={dataDetail?.lounge_data?.name}
                leftIcon="check_circle"
              />
              <CardFlush
                sideMenu
                title={t("field.bookingId")}
                desc={dataDetail?.unique_transaction_id}
                leftIcon="confirmation_number"
              />
              {dataDetail?.add_on_joumpa && (
                <CardFlush
                  sideMenu
                  title={`Joumpa ${t("field.assistant")} ${t("field.bookingId")}`}
                  desc={dataDetailJoumpa?.unique_transaction_id}
                  leftIcon="confirmation_number"
                />
              )}
              <CardFlush
                sideMenu
                title={t("field.flightType")}
                desc={dataDetail?.type === 2 ? t("field.departure") : t("field.arrival")}
                leftIcon="flight"
              />
              {dataDetail?.adder_data?.adder_name && (
                <CardFlush
                  sideMenu
                  title={t("commons.addedBy")}
                  desc={dataDetail?.adder_data?.adder_name}
                  leftIcon="group_add"
                  last
                />
              )}
            </Card>
            <Card className="rounded">
              <CardFlush
                sideMenu
                last
                title={"Status"}
                desc={
                  lang === 'id'
                  ? dataDetail?.status
                  : dataDetail?.status_eng
                }
                leftIcon={dataDetail?.status === paymentStatusLounge.Done ? "done" : "info"}
                iconColor={
                  dataDetail?.status_eng === paymentStatusLounge.New
                    ? themeColor.danger
                    : dataDetail?.status_eng === paymentStatusLounge.Done
                    ? themeColor.primary
                    : themeColor.secondary
                }
              />
            </Card>
          </div>
        </div>
        <div>
          <p className="super-title text-extra-bold pt-5 pb-3 px-4">
            {t("commons.details")}
          </p>
          <div className="pl-4 pr-3">
            <div className="wrapper-detail-card">
              {
                (data = [
                  {
                    label: t("field.customer"),
                    desc: dataDetail?.customer_data
                      ? dataDetail?.customer_data?.customer_name
                      : (dataDetail?.list_order_passengers?.length && dataDetail?.list_order_passengers[0]?.name),
                  },
                  {
                    label: t("field.contact"),
                    desc: getContactInfoBooking(dataDetail),
                  },
                  {
                    label: t("field.bookingTime"),
                    desc: `${moment(dataDetail?.booking_date).format(
                      "DD/MM/YYYY"
                    )}, ${moment(dataDetail?.booking_date).format("HH:mm")}`,
                  },
                ].map((item, i) => (
                  <CardFlush
                    list
                    label={item.label}
                    desc={item.desc}
                    size={{ label: 3, desc: 8 }}
                  />
                )))
              }
              <CardFlush
                list
                label={t("field.airline")}
                desc={`${dataDetail?.airplane_data?.airplane_name} / ${dataDetail?.airplane_data?.airplane_code}${dataDetail?.airplane_data?.airplane_number}`}
                size={{ label: 3, desc: 8 }}
              />
              <CardFlush
                list
                label={t("field.origin")}
                desc={`${dataDetail?.airport_from_data?.airport_code} - ${
                  dataDetail?.airport_from_data?.airport_city
                } - ${dataDetail?.airport_from_data?.airport_name}  ${
                  dataDetail?.airport_from_data?.airport_country !== "Indonesia" &&
                  dataDetail?.airport_from_data?.airport_country !== undefined
                    ? "-" + dataDetail?.airport_from_data?.airport_country
                    : ""
                }`}
                size={{ label: 3, desc: 8 }}
              />
              <CardFlush
                list
                label={t("field.destination")}
                desc={`${dataDetail?.airport_to_data?.airport_code} - ${
                  dataDetail?.airport_to_data?.airport_city
                } - ${dataDetail?.airport_to_data?.airport_name} ${
                  dataDetail?.airport_to_data?.airport_country !== "Indonesia" &&
                  dataDetail?.airport_to_data?.airport_country !== undefined
                    ? "-" + dataDetail?.airport_to_data?.airport_country
                    : ""
                }`}
                size={{ label: 3, desc: 8 }}
              />
              <CardFlush
                list
                label={dataDetail?.type === 2 ? t("field.departureTime") : t("field.arrivalTime")}
                desc={`${moment(dataDetail?.date).format("DD/MM/YYYY")}, ${
                  dataDetail?.time
                }`}
                size={{ label: 3, desc: 8 }}
              />
              <CardFlush
                list
                label={t("field.note")}
                desc={dataDetail?.passenger_notes ?? "-"}
                size={{ label: 3, desc: 9 }}
                last
              />
            </div>
            <div className="wrapper-detail-card">
              <div className="text-extra-bold container-card opacity-5">
                {t("field.additionalService")}
              </div>
              <Divider orientation="horizontal" />
              <div className="px-3">
                {dataDetail?.add_on_joumpa ? (
                  <CardFlush
                    addtServiceReservLounge
                    isJoumpaAsst={dataDetail?.add_on_joumpa}
                    isTransport={dataDetailJoumpa?.transport_name && dataDetailJoumpa?.transport_total_unit}
                    productJoumpaAsst={dataDetailJoumpa?.product_name_eng}
                    nameTransport={
                      dataDetailJoumpa?.transport_name && dataDetailJoumpa?.transport_total_unit
                        ? dataDetailJoumpa?.transport_region !== null
                          ? dataDetailJoumpa?.transport_region +
                            " - " +
                            dataDetailJoumpa?.transport_name +
                            " ( " +
                            (dataDetailJoumpa?.transport_total_unit
                              ? dataDetailJoumpa?.transport_total_unit
                              : "1") +
                            " unit)"
                          : dataDetailJoumpa?.transport_name +
                            " ( " +
                            (dataDetailJoumpa?.transport_total_unit
                              ? dataDetailJoumpa?.transport_total_unit
                              : "1") +
                            " unit)"
                        : t("commons.none")
                    }
                  />
                ) : (
                  <CardFlush
                    addtServiceReservLounge
                    isJoumpaAsst={false}
                    isTransport={false}
                    productJoumpaAsst={t("commons.none")}
                    nameTransport={t("commons.none")}
                  />
                )}
              </div>
            </div>
            <div className="wrapper-detail-card">
              <div className="text-extra-bold container-card opacity-5">
                {t("field.passenger")}
              </div>
              <div className="px-3">
                <Table
                  columns={columnPassengers.filter((item) => item.show !== false)}
                  data={dataDetail?.list_order_passengers ?? []}
                  minHeight="100%"
                />
              </div>
              <Divider orientation="horizontal" />
              <div
                className="d-flex align-items-center mt-4 px-4"
              >
                <p
                  className="normal-title text-extra-bold opacity-5"
                >
                  Total PAX
                </p>
                <div className="ml-auto">
                  <p className="normal-title text-bold">
                    {`${getLengthPassAdult(dataDetail?.list_order_passengers)} ${t("commons.adult")}, ${
                      getLengthPassChild(dataDetail?.list_order_passengers)} ${t("commons.child")}, ${
                      getLengthPassInfant(dataDetail?.list_order_passengers)} ${t("commons.infantLabel")}`}
                  </p>
                </div>
              </div>
            </div>
            <div className="wrapper-detail-card">
              <div className="text-extra-bold container-card opacity-5">
                <Row>
                  <Col md={3}>{t("field.booker")}</Col>
                </Row>
              </div>
              <Divider orientation="horizontal" />
              <Row className="px-4 py-3 normal-title">
                <Col
                  md={3}
                  className="text-extra-bold"
                >
                  {t("field.booker")}
                </Col>
                <Col
                  md={2}
                  className="text-extra-bold"
                >
                  {t("field.nationality")}
                </Col>
                <Col
                  md={3}
                  className="text-extra-bold"
                >
                  {t("field.email")}
                </Col>
                <Col
                  md={3}
                  className="text-extra-bold"
                >
                  {t("field.phone")}
                </Col>
                <Col md={1}></Col>
              </Row>
              <Divider orientation="horizontal" />
              <Row className="px-4 py-3 normal-title">
                <Col md={3}>{dataDetail?.booker_data?.bookers_name ?? "-"}</Col>
                <Col md={2}>{dataDetail?.booker_data?.bookers_nationality ?? "-"}</Col>
                <Col md={3}>{dataDetail?.booker_data?.bookers_email ?? "-"}</Col>
                <Col md={3}>
                  {dataDetail?.booker_data?.bookers_phone && dataDetail?.booker_data?.bookers_country_code
                    ? `${dataDetail?.booker_data?.bookers_country_code}${dataDetail?.booker_data?.bookers_phone}` 
                    : dataDetail?.booker_data?.bookers_phone && !dataDetail?.booker_data?.bookers_country_code
                    ? dataDetail?.booker_data?.bookers_phone
                    : "-"}
                </Col>
                <Col md={1}>
                  {currentUser.user.role_code === Role.Central_admin && (
                    <DetailButton>
                      <MenuItem
                        onClick={() => setEditBookerModal(!editBookerModal)}
                      >
                        <span className="small-text">
                          {t("commons.edit")}
                        </span>
                      </MenuItem>
                    </DetailButton>
                  )}
                </Col>
              </Row>
            </div>
            <div className="wrapper-detail-card">
              <div className="text-extra-bold container-card opacity-5">
                {t("field.detailPrice")}
              </div>
              <Divider orientation="horizontal" />
              <CardFlush
                list
                label={t("field.grandTotal")}
                desc={
                  dataDetail?.total_price
                    ? `Rp ${numberWithCommas(dataDetail.total_price)}`
                    : "Rp 0"
                }
                size={{ label: 6, desc: 6 }}
                descBold
                descEnd
                last
              />
              <div className="card-body border-top py-3 pl-4">
                <div className="d-flex justify-content-between">
                  <div className="normal-title text-extra-bold">
                    {t("field.lounge")}
                  </div>
                  <div
                    onClick={toggleLounge}
                    className="d-flex align-items-center clickable"
                  >
                    <span className="normal-title text-extra-bold mr-2">
                      Rp {numberWithCommas(dataDetail?.price_service)}
                    </span>
                    <span className="material-icons-round left-icon mr-0">
                      expand_more
                    </span>
                  </div>
                </div>
                {showLounge && (
                  <>
                    {dataPassengerAdult > 0 && (
                        <SummaryCheckoutPrice
                          category={t("commons.adult")}
                          keyTooltip="taxLoungeAdult"
                          passengers={dataPassengerAdult}
                          price={dataDetail?.adult_price_original}
                          dataCheckout={{
                            ppnPrice:  dataDetail?.ppn_adult_price,
                            isIncludePpn: dataDetail?.is_include_ppn,
                            ppnPercentage: dataDetail?.ppn_percentage,
                          }}
                        />
                      )}
                    {dataPassengerChild > 0 && (
                        <SummaryCheckoutPrice
                          category={t("commons.child")}
                          keyTooltip="taxLoungeChild"
                          passengers={dataPassengerChild}
                          price={dataDetail?.child_price_original}
                          dataCheckout={{
                            ppnPrice:  dataDetail?.ppn_child_price,
                            isIncludePpn: dataDetail?.is_include_ppn,
                            ppnPercentage: dataDetail?.ppn_percentage,
                          }}
                        />
                      )}
                    {dataPassengerInfant > 0 && (
                        <SummaryCheckoutPrice
                          category={t("commons.infantLabel")}
                          keyTooltip="taxLoungeInfant"
                          passengers={dataPassengerInfant}
                          price={dataDetail?.infant_price_original}
                          dataCheckout={{
                            ppnPrice:  dataDetail?.ppn_infant_price,
                            isIncludePpn: dataDetail?.is_include_ppn,
                            ppnPercentage: dataDetail?.ppn_percentage,
                          }}
                        />
                      )}
                      {dataDetail?.lounge_promo_id && (
                        <>
                          <div>
                            <div className="normal-title text-extra-bold mt-2">
                              Promo
                            </div>
                            <div className="d-flex justify-content-between">
                              <div className="normal-text mt-1">
                                {dataDetail?.lounge_promo_name}
                                <span className="small-vertical-divider"></span>
                                <span className="text-primary">
                                  {dataDetail?.lounge_promo_type === promoLoungeType.Specific
                                    ? t("promo.special")
                                    : dataDetail?.lounge_promo_type}
                                </span>
                              </div>
                              <div className="text-right">
                                <div className="normal-text text-danger mb-1">
                                  {dataDetail?.price_cut
                                    ? `Rp ${numberWithCommas(dataDetail.price_cut)}`
                                    : "Rp 0"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                  </>
                )}
              </div>
              {dataDetail?.add_on_joumpa && (
                <>
                <div className="card-body border-top py-3 pl-4">
                  <div className="d-flex justify-content-between">
                    <div className="normal-title text-extra-bold">
                      {t("field.price")}{" Joumpa "}{t("field.assistant")}
                    </div>
                    <div
                      onClick={togglePriceService}
                      className="d-flex align-items-center clickable"
                    >
                      <span className="normal-title text-extra-bold mr-2">
                        Rp {numberWithCommas(dataDetailJoumpa?.total_price)}
                      </span>
                      <span className="material-icons-round left-icon mr-0">
                        expand_more
                      </span>
                    </div>
                  </div>
                  {showPriceService && (
                    <>
                      {dataPassengerAdult > 0 && (
                        <SummaryCheckoutPrice
                          category={t("commons.adult")}
                          keyTooltip="taxAdult"
                          passengers={dataPassengerAdult}
                          price={dataDetailJoumpa?.adult_price_original}
                          dataCheckout={{
                            ppnPrice:  dataDetailJoumpa?.ppn_adult_price,
                            concessionPrice: dataDetailJoumpa?.concession_adult_price,
                            isIncludePpn: dataDetailJoumpa?.is_include_ppn,
                            isIncludeConcession: dataDetailJoumpa?.is_include_concession,
                            ppnPercentage: dataDetailJoumpa?.ppn_percentage,
                            concessionPercentage: dataDetailJoumpa?.concession_percentage,
                          }}
                        />
                      )}
                      {dataPassengerChild > 0 && (
                         <SummaryCheckoutPrice
                          category={t("commons.child")}
                          keyTooltip="taxChild"
                          passengers={dataPassengerChild}
                          price={dataDetailJoumpa?.child_price_original}
                          dataCheckout={{
                            ppnPrice:  dataDetailJoumpa?.ppn_child_price,
                            concessionPrice: dataDetailJoumpa?.concession_child_price,
                            isIncludePpn: dataDetailJoumpa?.is_include_ppn,
                            isIncludeConcession: dataDetailJoumpa?.is_include_concession,
                            ppnPercentage: dataDetailJoumpa?.ppn_percentage,
                            concessionPercentage: dataDetailJoumpa?.concession_percentage,
                          }}
                        />
                      )}
                      {dataPassengerInfant > 0 && (
                        <SummaryCheckoutPrice
                         category={t("commons.infantLabel")}
                         keyTooltip="taxInfant"
                         passengers={dataPassengerInfant}
                         price={dataDetailJoumpa?.infant_price_original}
                         dataCheckout={{
                           ppnPrice:  dataDetailJoumpa?.ppn_infant_price,
                           concessionPrice: dataDetailJoumpa?.concession_infant_price,
                           isIncludePpn: dataDetailJoumpa?.is_include_ppn,
                           isIncludeConcession: dataDetailJoumpa?.is_include_concession,
                           ppnPercentage: dataDetailJoumpa?.ppn_percentage,
                           concessionPercentage: dataDetailJoumpa?.concession_percentage,
                         }}
                       />
                      )}
                      {dataDetailJoumpa?.transport_name && dataDetailJoumpa?.transport_total_unit && (
                        <div className="d-flex justify-content-between py-3">
                          <div className="d-flex align-items-center normal-title text-extra-bold">
                            {t("field.transportation")}
                            {dataDetailJoumpa?.transport_total_unit > 0 &&
                              dataDetailJoumpa?.is_include_ppn_transport && (
                                <>
                                  <span
                                    className="material-icons title"
                                    data-tip
                                    data-for="taxTransports"
                                  >
                                    info_outlined
                                  </span>
                                  <ReactTooltip
                                    id="taxTransports"
                                    place="top"
                                    effect="solid"
                                  >
                                    <p className="text-pre-line">
                                      {`${t("product.includes_ppn")} ${
                                        dataDetailJoumpa?.ppn_percentage_transport
                                      }%, Rp ${numberWithCommas(
                                        dataDetailJoumpa?.ppn_transport
                                      )}`}
                                    </p>
                                  </ReactTooltip>
                                </>
                              )}
                          </div>
                          <div className="normal-title ">
                            {dataDetailJoumpa?.transport_total_unit +
                              " unit  x Rp" +
                              numberWithCommas(dataDetailJoumpa?.transport_price)}
                          </div>
                        </div>
                      )}
                      {dataDetailJoumpa?.joumpa_promo_id && (
                        <>
                          <div>
                            <div className="normal-title text-extra-bold mt-2">
                              Promo
                            </div>
                            <div className="d-flex justify-content-between">
                              <div className="normal-text mt-1">
                                {dataDetailJoumpa?.joumpa_promo_name}
                                <span className="small-vertical-divider"></span>
                                <span className="text-primary">
                                  {dataDetailJoumpa?.joumpa_promo_type === promoLoungeType.Blast
                                    ? "Blast"
                                    : dataDetail?.joumpa_promo_type === promoLoungeType.Specific
                                    ? t("promo.special")
                                    : ""}
                                </span>
                              </div>
                              <div className="text-right">
                                <div className="normal-text text-danger mb-1">
                                  {dataDetailJoumpa?.price_cut
                                    ? `Rp ${numberWithCommas(dataDetailJoumpa.price_cut)}`
                                    : "Rp 0"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
                </>
              )}
            </div>
            <div className="wrapper-detail-card">
              <div className="text-extra-bold container-card">
                <Row>
                  <Col md={3} className="opacity-5">
                    {t("field.payment")}
                  </Col>
                  <Col md={9}>
                    <div className="d-flex justify-content-end">
                      <div
                        className="text-primary text-bold clickable normal-title"
                        onClick={dataDetail?.payment_proof && !dataDetail?.payment_order_id
                          ? showPaymentProof
                          : null
                        }
                      >
                        {dataDetail?.payment_proof && !dataDetail?.payment_order_id
                          ? t("booking.openPaymentProof")
                          : ""}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <Divider orientation="horizontal" />
              <CardFlush
                list
                label={t("booking.paymentType")}
                desc={
                  dataDetail?.payment_type
                    ? paymentTypes[dataDetail?.payment_type]
                    : "-"
                }
                size={{ label: 3, desc: 9 }}
                descEnd
              />
              {dataDetail?.payment_order_id && (
                <CardFlush
                  list
                  label="Order ID"
                  desc={dataDetail?.payment_order_id ?? "-"}
                  size={{ label: 3, desc: 9 }}
                  descEnd
                  last
                />
              )}
              {currentUser.user.role_code === Role.Central_admin &&
                dataDetail?.payment_type !== paymentTypes.isFree &&
                dataDetail?.payment_type !== payment_gateway &&
                dataDetail?.status_eng !== paymentStatusLounge.Cancelled && (
                <div className="d-flex align-items-center">
                  <IconButton
                    onClick={showUploadPaymentModal}
                    icon={dataDetail?.payment_proof === null ? "add" : "edit"}
                    title={`${dataDetail?.payment_proof === null ? "Add" : "Edit"} ${t("field.payment")}`}
                  />
                  {dataDetail?.payment_proof !== null && (
                    <>
                      <span className="small-vertical-divider"></span>
                      <IconButton
                        onClick={() => setDeleteModal(!deleteModal)}
                        icon="delete"
                        colorIcon="#d53a34"
                        title={`${t("commons.delete")} ${t("field.payment")}`}
                      />
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <EditPassengerModal
        show={editPassengerModal}
        toggle={showEditPassengerModal}
        action={handleEditPassenger}
        data={dataPassengerRow}
      />
      <EditBookerModal
        show={editBookerModal}
        toggle={() => setEditBookerModal(!editBookerModal)}
        action={handleEditBooker}
        data={dataDetail}
      />
      <PaymentProofModal
        show={paymentProof}
        toggle={() => setPaymentProof(!paymentProof)}
        title={t("booking.paymentProof")}
        content={
          <div>
            {process.env.REACT_APP_API_URL +
              dataDetail?.payment_proof?.substr(1, dataDetail?.payment_proof?.length) 
            ? (
              <img
                src={
                  process.env.REACT_APP_API_URL +
                  dataDetail?.payment_proof?.substr(1, dataDetail?.payment_proof?.length)
                }
                alt={t("booking.paymentProof")}
                className="img img-fluid img-object-cover w-100"
                width="100%"
              />
            ) : (
              <p>Dokumen tidak ditemukan</p>
            )}
          </div>
        }
      />
      <UploadPaymentModal
        show={uploadPaymentProof}
        toggle={() => {
          setUploadPaymentProof(!uploadPaymentProof);
          handleRefresh();
        }}
        payment_type={dataDetail?.payment_type ?? null}
        payment_proof={
          dataDetail?.payment_proof
            ? process.env.REACT_APP_API_URL +
              dataDetail?.payment_proof?.substr(
                1,
                dataDetail?.payment_proof?.length
              )
            : null
        }
        order_id={match?.params?.id}
      />
      <ConfirmationModal
        show={deleteModal}
        toggle={() => setDeleteModal(!deleteModal)}
        confirm={deletePayment}
        icon="info"
        message={`${t("commons.deleteConfirmation")} ${t("field.payment")} `}
        messageData={`#${dataDetail?.unique_transaction_id}`}
        isDelete
      />
    </div>
  );
};

const mapStateToProps = ({ reservation_lounge: { error, pending } }) => {
  return { error, pending };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(DetailReservation)
);

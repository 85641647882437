import { Formik, Form } from "formik";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { withTrans } from "../../../i18n/withTrans";
import Select2 from "../../../components/forms/Select2";
import Select2Multi from "../../../components/forms/Select2Multi";
import ButtonModal from "../../../components/modal/ButtonModal";
import DynamicButton from "../../../components/forms/DynamicButton";
import DatePicker from "../../../components/forms/DateRangePicker";
import moment from "moment";
import debounce from "lodash.debounce";
import { Role } from "../../../utils/Constants";

function ModalFilter({
  t,
  show,
  user,
  airport,
  lounges,
  toggle,
  filter,
  setSearchLounge,
  toggleFilter,
  isDelete,
  onApplyFilter,
}) {
  const debouncedSearchLounge = debounce((value) => {
    if (value !== "") setSearchLounge(value);
  }, 500);

  const handleReset = (formik) => {
    formik.setFieldValue("start_date", moment().format("YYYY-MM-DD"));
    formik.setFieldValue("end_date", moment().format("YYYY-MM-DD"));
    formik.setFieldValue("lounge_id", "");
    formik.setFieldValue("airport_id", "");
    formik.setFieldValue("booking_type", "");
    formik.setFieldValue("report_type", true);
    toggleFilter({
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
      lounge_id: "",
      airport_id: "",
      booking_type: "",
      report_type: true,
    });
    toggle();
  };

  return (
    <>
      <Modal isOpen={show} modalTransition={{ timeout: 200 }}>
        <ModalHeader className="text-extra-bold pt-5 pb-0">
          {" "}
          Filter{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <ModalBody className="my-0 mx-2">
          <DynamicButton titleLeft={t("field.chooseFilter")} iconLeft="info" />
          <Formik
            initialValues={{
              start_date: filter?.start_date ?? "",
              end_date: filter?.end_date ?? "",
              airport_id: filter?.airport_id ?? "",
              lounge_id: filter?.lounge_id ?? "",
              booking_type: filter?.booking_type ?? "",
              report_type: filter?.report_type ?? true,
            }}
            enableReinitialize
            onSubmit={(values) => {
              onApplyFilter({
                airport_id: values.airport_id,
                start_date:
                  values.start_date === "" || values.start_date === undefined
                    ? ""
                    : moment(values.start_date).format("YYYY-MM-DD"),
                end_date:
                  values.end_date === "" || values.end_date === undefined
                    ? ""
                    : moment(values.end_date).format("YYYY-MM-DD"),
                lounge_id: values.lounge_id,
                booking_type: values.booking_type,
                report_type: values.report_type,
              });
              toggle();
            }}
          >
            {(props) => (
              <Form>
                <DatePicker
                  title={t("report.serviceTime")}
                  name="start_date"
                  handlechange={(startDate, endDate) => {
                    props.setFieldValue("start_date", startDate);
                    props.setFieldValue("end_date", endDate);
                  }}
                  format="DD MMMM YYYY"
                  startDate={filter ? filter?.start_date : props.values?.start_date}
                  endDate={filter ? filter?.end_date : props.values?.end_date}
                  className="mx-auto"
                  errors={props.errors}
                  touched={props.touched}
                />
                <Select2
                  title={t("field.lounge")}
                  name="lounge_id"
                  options={[
                    {
                      value: "",
                      label: `${t("commons.all")} ${t("field.lounge")}`,
                    },
                    ...lounges,
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.lounge_id}
                  onInputChange={(keyword) => {
                    debouncedSearchLounge(keyword);
                  }}
                  onChange={(name, value) => {
                    if (!value) setSearchLounge("");
                    props.setFieldValue("lounge_id", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <Select2Multi
                  title={t("field.airport")}
                  placeholder={`${t("commons.all")} ${t("field.airport")}`}
                  name="airport_id"
                  options={airport}
                  className="mb-2"
                  errors={props?.errors}
                  touched={props?.touched}
                  value={props?.values?.airport_id}
                  onChange={(name, value) => {
                    const data = value;
                    props?.setFieldValue("airport_id", data);
                  }}
                  onBlur={props?.setFieldTouched}
                />
                <Select2
                  title={t("field.bookingType")}
                  name="booking_type"
                  options={[
                    {
                      value: "",
                      label: `${t("commons.all")} ${t(
                        "field.bookingType"
                      )}`,
                    },
                    {
                      value: "Mobile",
                      label: t("field.mobileApp"),
                    },
                    {
                      value: "Web Public",
                      label: t("field.webPublic"),
                    },
                    {
                      value: "Web Admin",
                      label: t("field.webAdmin"),
                    },
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.booking_type}
                  onChange={(name, value) => {
                    props.setFieldValue("booking_type", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                {user.role_code === Role.Superadmin && (
                  <Select2
                    title={t("report.reportType")}
                    name="report_type"
                    options={[
                      {
                        value: true,
                        label: t("commons.all"),
                      },{
                        value: false,
                        label: t("report.withoutFreeGrantTotal")
                      }
                    ]}
                    errors={props.errors}
                    touched={props.touched}
                    value={props.values?.report_type}
                    onChange={(name, value) => {
                      props.setFieldValue("report_type", value);
                    }}
                    onBlur={props.setFieldTouched}
                  />
                )}
               
                <div className="mt-3 mb-2">
                  <ButtonModal
                    toggle={toggle}
                    reset={() => {
                      setSearchLounge("")
                      handleReset(props)
                    }}
                    deleted={isDelete}
                    confirmTitle={t("field.apply")}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
}

export default withTrans(ModalFilter);

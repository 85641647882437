import { useState } from "react";

import SideMenu from "../../components/commons/menu/SideMenu";
import PromoJoumpa from "./promo_joumpa";
import PromoIash from "./promo_iash";
import { withTrans } from "../../i18n/withTrans";
import { getUser } from "../../utils/User";
import { Role } from "../../utils/Constants";

const Index = ({ t, location }) => {
    const currentUser = getUser();
    const [show, setShow] = useState(location?.state?.tab ?? "promo_joumpa");

    return (
        <div className="menu-container">
            {currentUser?.user.role_code === Role.Central_admin ? (
                <div className="grid-header">
                    <div className="side-menu">
                        <div className="menu-title text-extra-bold">
                            {t('promo.promoManagement')}
                        </div>
                        <SideMenu
                            title="Promo Joumpa"
                            subtitle={`Promo Joumpa ${t('field.assistant')}`}
                            toggle={() => setShow("promo_joumpa")}
                            active={show === "promo_joumpa"}
                        />
                        <SideMenu
                            title="Promo IASH"
                            subtitle="Promo Lounge IASH"
                            toggle={() => setShow("promo_iash")}
                            active={show === "promo_iash"}
                        />
                    </div>
                    {show === "promo_joumpa" && (
                        <>
                            <PromoJoumpa />
                        </>
                    )}
                    {show === "promo_iash" && (
                        <>
                            <PromoIash />
                        </>
                    )}
                </div>
            ) : (<PromoJoumpa />)}
        </div>
    );
};

export default withTrans(Index);

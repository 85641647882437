import { actionPending, actionSuccess, actionError } from "../actionTypes";

import { toastSuccess, toastError } from "../../../components/commons/toast";
import API from "../API";
import { handleResponse } from "../HandleRespone";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_REPORT_PENDING = "GET_REPORT_PENDING";
export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS";
export const GET_REPORT_ERROR = "GET_REPORT_ERROR";
export const GET_RKAP_TOTAL_PENDING = "GET_RKAP_TOTAL_PENDING";
export const GET_RKAP_TOTAL_SUCCESS = "GET_RKAP_TOTAL_SUCCESS";
export const GET_RKAP_TOTAL_ERROR = "GET_RKAP_TOTAL_ERROR";
export const POST_REPORT_PENDING = "POST_REPORT_PENDING";
export const POST_REPORT_SUCCESS = "POST_REPORT_SUCCESS";
export const POST_REPORT_ERROR = "POST_REPORT_ERROR";
export const PUT_REPORT_PENDING = "POST_REPORT_PENDING";
export const PUT_REPORT_SUCCESS = "POST_REPORT_SUCCESS";
export const PUT_REPORT_ERROR = "POST_REPORT_ERROR";
export const DELETE_REPORT_PENDING = "DELETE_REPORT_PENDING";
export const DELETE_REPORT_SUCCESS = "DELETE_REPORT_SUCCESS";
export const DELETE_REPORT_ERROR = "DELETE_REPORT_ERROR";
export const DOWNLOAD_REPORT_PENDING = "DOWNLOAD_REPORT_PENDING";
export const DOWNLOAD_REPORT_SUCCESS = "DOWNLOAD_REPORT_SUCCESS";
export const DOWNLOAD_REPORT_ERROR = "DOWNLOAD_REPORT_ERROR";

// URL: URL_{URL}
const SALES_REPORT_URL = `v1/report/sales`;
const ASSISTANT_REPORT_URL = `v1/report/assistant`;
const CORPORATE_REPORT_URL = `v1/report/agent`;
const CORPORATE_NONMOB_REPORT_URL = `v1/report/corporate-non-mob`;
const CUSTOMER_REPORT_URL = `v1/report/customer`;
const PRODUCTION_REPORT_URL = `v1/report/production-report`;
const CROSS_SELLING_REPORT_URL = `v1/report/cross-selling-report`;
const COMMISSION_REPORT_URL = `v1/report/commission`;
const RESUME_COMMISSION_REPORT_URL = `v1/report/commission/resume`;
const COMMISSION_LOUNGE_REPORT_URL = `v1/report-lounge/lounge-commission`;
const RESUME_COMMISSION_LOUNGE_REPORT_URL = `v1/report-lounge/lounge-commission/resume`;
const RKAP_PRODUCTION_URL = `/v1/report/rkap/production`;
const RKAP_INCOME_URL = `/v1/report/rkap/income`;
const RKAP_TOTAL_PRODUCTION_URL = `/v1/report/rkap/total-production`;
const RKAP_TOTAL_INCOME_URL = `/v1/report/rkap/total-income`;
const LOUNGE_REPORT_URL = `v1/report-lounge/production-lounge-report`;
const DOWNLOAD_SALES_URL = `v1/report/sales/export/excel`;
const DOWNLOAD_CORPORATE_URL = `v1/report/agent/export/excel`;
const DOWNLOAD_NON_MOB_URL = `v1/report/corporate-non-mob/export/excel`;
const DOWNLOAD_ASSISTANT_URL = `/v1/report/assistant/export/excel`;
const DOWNLOAD_CUSTOMER_URL = `/v1/report/customer/export/excel`;
const DOWNLOAD_PRODUCTION_URL = `/v1/report/production-report/export/excel`;
const DOWNLOAD_CROSS_SELLING_URL = `/v1/report/cross-selling-report/export/excel`;
const DOWNLOAD_COMMISSION_URL = `/v1/report/commission/export/excel`;
const DOWNLOAD_COMMISSION_LOUNGE_URL = `/v1/report-lounge/lounge-commission/export/excel`;
const DOWNLOAD_LOUNGE_REPORT_URL = `v1/report-lounge/production-lounge-report/export/excel`;
const IMPORT_RKAP_URL = `/v1/report/import-rkap`;
const DOWNLOAD_RKAP_URL = `/v1/report/rkap/export/excel`;
const API_REPORT_URL = '/v1/report/api-report';
const DOWNLOAD_API_REPORT_URL = `/v1/report/api-report/export/excel`;

const lang = localStorage.getItem('joumpa_language')

const getSales = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_REPORT_PENDING));
  API.get(SALES_REPORT_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_REPORT_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
          totals_passenger: item?.total_passenger + item?.plus_passenger
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_REPORT_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const getCorporate = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_REPORT_PENDING));
  let productIdString = "";
  if (param?.product_id?.length) {
    param?.product_id?.forEach(id => {
      productIdString = productIdString + "&product_id=" + id
    });
  } else {
    productIdString = productIdString + "&product_id="
  }

  API.get(
    CORPORATE_REPORT_URL +
      `?length=${param.length}` +
      `&page=${param.page}` +
      `&corporate_id=${param.corporate_id}` +
      `&start_date=${param.start_date}` +
      `&end_date=${param.end_date}` +
      `&airport_id=${param.airport_id}` +
      productIdString
  )
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_REPORT_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_REPORT_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const getCorporateNonMOB = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_REPORT_PENDING));
  API.get(CORPORATE_NONMOB_REPORT_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_REPORT_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_REPORT_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const getAssistant = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_REPORT_PENDING));
  API.get(ASSISTANT_REPORT_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_REPORT_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
          service_time: `${item?.date}`,
          totals_passenger: item?.total_passenger + item?.plus_passenger,
          corporate_group_type: item?.corporate_group_type ?? '-'
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_REPORT_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const getCustomer = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_REPORT_PENDING));
  API.get(CUSTOMER_REPORT_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_REPORT_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_REPORT_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const getProduction = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_REPORT_PENDING));
  API.get(PRODUCTION_REPORT_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_REPORT_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_REPORT_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const getCrossSelling = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_REPORT_PENDING));
  API.get(CROSS_SELLING_REPORT_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_REPORT_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_REPORT_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const getAPIReport = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_REPORT_PENDING));
  API.get(API_REPORT_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_REPORT_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page) * param?.length,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_REPORT_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const getCommission = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_REPORT_PENDING));
  let airportIdString = "";
  if (param?.airport_id?.length) {
    param?.airport_id?.forEach(id => {
      airportIdString = airportIdString + "&airport_id=" + id
    });
  } else {
    airportIdString = airportIdString + "&airport_id="
  }

  API.get(
    COMMISSION_REPORT_URL +
      `?length=${param.length}` +
      `&page=${param.page}` +
      `&corporate_id=${param.corporate_id}` +
      `&payment_type=${param.payment_type}` +
      `&start_date=${param.start_date}` +
      `&end_date=${param.end_date}` +
      `&product_id=${param.product_id}` +
      `&booking_type=${param.booking_type}` +
      `&has_zero_comm=${param.report_type}` +
      airportIdString
  )
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_REPORT_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_REPORT_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const getResumeCommission = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_REPORT_PENDING));
  API.get(RESUME_COMMISSION_REPORT_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_REPORT_SUCCESS, res));
      if (resolve) {
        resolve({
          data: res.data.data,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_REPORT_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const getCommissionLounge = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_REPORT_PENDING));
  let airportIdString = "";
  if (param?.airport_id?.length) {
    param?.airport_id?.forEach(id => {
      airportIdString = airportIdString + "&airport_id=" + id
    });
  } else {
    airportIdString = airportIdString + "&airport_id="
  }

  API.get(
    COMMISSION_LOUNGE_REPORT_URL +
      `?length=${param.length}` +
      `&page=${param.page}` +
      `&lounge_id=${param.lounge_id}` +
      `&start_date=${param.start_date}` +
      `&end_date=${param.end_date}` +
      `&booking_type=${param.booking_type}` +
      `&has_zero_comm=${param.report_type}` +
      airportIdString
  )
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_REPORT_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_REPORT_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const getResumeCommissionLounge = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_REPORT_PENDING));
  API.get(RESUME_COMMISSION_LOUNGE_REPORT_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_REPORT_SUCCESS, res));
      if (resolve) {
        resolve({
          data: res.data.data,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_REPORT_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const getRKAPProduction = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_REPORT_PENDING));
  API.get(RKAP_PRODUCTION_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_REPORT_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_REPORT_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const getRKAPIncome = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_REPORT_PENDING));
  API.get(RKAP_INCOME_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_REPORT_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_REPORT_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const getTotalProductionRKAP = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_RKAP_TOTAL_PENDING));
  API.get(RKAP_TOTAL_PRODUCTION_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_RKAP_TOTAL_SUCCESS, res));
      if (resolve) {
        resolve({
          data: res.data.data,
          page: param?.page - 1,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_RKAP_TOTAL_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const getTotalIncomeRKAP = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_RKAP_TOTAL_PENDING));
  API.get(RKAP_TOTAL_INCOME_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_RKAP_TOTAL_SUCCESS, res));
      if (resolve) {
        resolve({
          data: res.data.data,
          page: param?.page - 1,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_RKAP_TOTAL_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const getReportLounge = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_REPORT_PENDING));
  API.get(LOUNGE_REPORT_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_REPORT_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_REPORT_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const downloadAssistant = (param, resolve) => (dispatch) => {
  dispatch(actionPending(DOWNLOAD_REPORT_PENDING));
  API.get(DOWNLOAD_ASSISTANT_URL, {
    params: param,
    responseType: "blob",
  })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DOWNLOAD_REPORT_SUCCESS, res));
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Joumpa Assistant Report.xlsx`); //or any other extension
      document.body.appendChild(link);
      link.click();
      if (resolve) {
        resolve({
          data: res.data,
        });
      }
    })
    .catch((err) => {
      dispatch(actionError(DOWNLOAD_REPORT_ERROR));
      toastError(lang === 'id' ? "Gagal mengunduh data!" : "Download Unsuccessfull!");
    });
};

const downloadSales = (param, resolve) => (dispatch) => {
  dispatch(actionPending(DOWNLOAD_REPORT_PENDING));
  API.get(DOWNLOAD_SALES_URL, {
    params: param,
    responseType: "blob",
  })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DOWNLOAD_REPORT_SUCCESS, res));
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Joumpa Sales Report.xlsx`); //or any other extension
      document.body.appendChild(link);
      link.click();
      if (resolve) {
        resolve({
          data: res.data,
        });
      }
    })
    .catch((err) => {
      dispatch(actionError(DOWNLOAD_REPORT_ERROR));
      toastError(lang === 'id' ? "Gagal mengunduh data!" : "Download Unsuccessfull!");
    });
};

const downloadCorporate = (param, resolve) => (dispatch) => {
  dispatch(actionPending(DOWNLOAD_REPORT_PENDING));
  let productIds = "";
  if (param?.product_id?.length) {
    param?.product_id?.forEach(id => {
      productIds = productIds + "&product_id=" + id
    });
  } else {
    productIds = productIds + "&product_id="
  }
  
  API.get(
    DOWNLOAD_CORPORATE_URL +
      `?corporate_id=${param.corporate_id}` +
      `&start_date=${param.start_date}` +
      `&end_date=${param.end_date}` +
      `&airport_id=${param.airport_id}` +
      productIds, {
      responseType: "blob",
    }
  )
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DOWNLOAD_REPORT_SUCCESS, res));
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Joumpa Corporate Report.xlsx`); //or any other extension
      document.body.appendChild(link);
      link.click();
      if (resolve) {
        resolve({
          data: res.data,
        });
      }
    })
    .catch((err) => {
      dispatch(actionError(DOWNLOAD_REPORT_ERROR));
      toastError(lang === 'id' ? "Gagal mengunduh data!" : "Download Unsuccessfull!");
    });
};

const downloadCorporateNonMOB = (param, resolve) => (dispatch) => {
  dispatch(actionPending(DOWNLOAD_REPORT_PENDING));
  API.get(DOWNLOAD_NON_MOB_URL, {
    params: param,
    responseType: "blob",
  })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DOWNLOAD_REPORT_SUCCESS, res));
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Joumpa Agent Non-MOB Report.xlsx`); //or any other extension
      document.body.appendChild(link);
      link.click();
      if (resolve) {
        resolve({
          data: res.data,
        });
      }
    })
    .catch((err) => {
      dispatch(actionError(DOWNLOAD_REPORT_ERROR));
      toastError(lang === 'id' ? "Gagal mengunduh data!" : "Download Unsuccessfull!");
    });
};

const downloadCustomer = (param, resolve) => (dispatch) => {
  dispatch(actionPending(DOWNLOAD_REPORT_PENDING));
  API.get(DOWNLOAD_CUSTOMER_URL, {
    params: param,
    responseType: "blob",
  })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DOWNLOAD_REPORT_SUCCESS, res));
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Joumpa PAX Report.xlsx`); //or any other extension
      document.body.appendChild(link);
      link.click();
      if (resolve) {
        resolve({
          data: res.data,
        });
      }
    })
    .catch((err) => {
      dispatch(actionError(DOWNLOAD_REPORT_ERROR));
      toastError(lang === 'id' ? "Gagal mengunduh data!" : "Download Unsuccessfull!");
    });
};

const downloadProduction = (param, resolve) => (dispatch) => {
  dispatch(actionPending(DOWNLOAD_REPORT_PENDING));
  API.get(DOWNLOAD_PRODUCTION_URL, {
    params: param,
    responseType: "blob",
  })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DOWNLOAD_REPORT_SUCCESS, res));
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Joumpa Production Report.xlsx`); //or any other extension
      document.body.appendChild(link);
      link.click();
      if (resolve) {
        resolve({
          data: res.data,
        });
      }
    })
    .catch((err) => {
      dispatch(actionError(DOWNLOAD_REPORT_ERROR));
      toastError(lang === 'id' ? "Gagal mengunduh data!" : "Download Unsuccessfull!");
    });
};

const downloadCrossSelling = (param, resolve) => (dispatch) => {
  dispatch(actionPending(DOWNLOAD_REPORT_PENDING));
  API.get(DOWNLOAD_CROSS_SELLING_URL, {
    params: param,
    responseType: "blob",
  })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DOWNLOAD_REPORT_SUCCESS, res));
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Joumpa Cross Selling Report.xlsx`); //or any other extension
      document.body.appendChild(link);
      link.click();
      if (resolve) {
        resolve({
          data: res.data,
        });
      }
    })
    .catch((err) => {
      dispatch(actionError(DOWNLOAD_REPORT_ERROR));
      toastError(lang === 'id' ? "Gagal mengunduh data!" : "Download Unsuccessfull!");
    });
};

const downloadCommission = (param, resolve) => (dispatch) => {
  dispatch(actionPending(DOWNLOAD_REPORT_PENDING));
  let airportIds = "";
  if (param?.airport_id?.length) {
    param?.airport_id?.forEach(id => {
      airportIds = airportIds + "&airport_id=" + id
    });
  } else {
    airportIds = airportIds + "&airport_id="
  }
  
  API.get(
    DOWNLOAD_COMMISSION_URL +
      `?export_excel=true` +
      `&corporate_id=${param.corporate_id}` +
      `&payment_type=${param.payment_type}` +
      `&start_date=${param.start_date}` +
      `&end_date=${param.end_date}` +
      `&product_id=${param.product_id}` +
      `&booking_type=${param.booking_type}` +
      `&has_zero_comm=${param.report_type}` +
      airportIds, {
      responseType: "blob",
    }
  )
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DOWNLOAD_REPORT_SUCCESS, res));
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Joumpa Commission Report.xlsx`); //or any other extension
      document.body.appendChild(link);
      link.click();
      if (resolve) {
        resolve({
          data: res.data,
        });
      }
    })
    .catch((err) => {
      dispatch(actionError(DOWNLOAD_REPORT_ERROR));
      toastError(lang === 'id' ? "Gagal mengunduh data!" : "Download Unsuccessfull!");
    });
};

const downloadCommissionLounge = (param, resolve) => (dispatch) => {
  dispatch(actionPending(DOWNLOAD_REPORT_PENDING));
  let airportIds = "";
  if (param?.airport_id?.length) {
    param?.airport_id?.forEach(id => {
      airportIds = airportIds + "&airport_id=" + id
    });
  } else {
    airportIds = airportIds + "&airport_id="
  }
  
  API.get(
    DOWNLOAD_COMMISSION_LOUNGE_URL +
      `?export_excel=true` +
      `&lounge_id=${param.lounge_id}` +
      `&start_date=${param.start_date}` +
      `&end_date=${param.end_date}` +
      `&booking_type=${param.booking_type}` +
      `&has_zero_comm=${param.report_type}` +
      airportIds, {
      responseType: "blob",
    }
  )
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DOWNLOAD_REPORT_SUCCESS, res));
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Joumpa Commission Report.xlsx`); //or any other extension
      document.body.appendChild(link);
      link.click();
      if (resolve) {
        resolve({
          data: res.data,
        });
      }
    })
    .catch((err) => {
      dispatch(actionError(DOWNLOAD_REPORT_ERROR));
      toastError(lang === 'id' ? "Gagal mengunduh data!" : "Download Unsuccessfull!");
    });
};

const importRKAP = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(POST_REPORT_PENDING));
  API.post(IMPORT_RKAP_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_REPORT_SUCCESS, res));
      toastSuccess(lang === 'id' ? "Berhasil Import RKAP" : "Import RKAP Successful");
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_REPORT_ERROR, err?.response));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const downloadRKAP = (param, resolve) => (dispatch) => {
  dispatch(actionPending(DOWNLOAD_REPORT_PENDING));
  API.get(DOWNLOAD_RKAP_URL, {
    params: param,
    responseType: "blob",
  })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DOWNLOAD_REPORT_SUCCESS, res));
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Joumpa RKAP Report.xlsx`); //or any other extension
      document.body.appendChild(link);
      link.click();
      if (resolve) {
        resolve({
          data: res.data,
        });
      }
    })
    .catch((err) => {
      dispatch(actionError(DOWNLOAD_REPORT_ERROR));
      toastError(lang === 'id' ? "Gagal mengunduh data!" : "Download Unsuccessfull!");
    });
};

const downloadAPIReport = (param, resolve) => (dispatch) => {
  dispatch(actionPending(DOWNLOAD_REPORT_PENDING));
  API.get(DOWNLOAD_API_REPORT_URL, {
    params: param,
    responseType: "blob",
  })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DOWNLOAD_REPORT_SUCCESS, res));
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Joumpa API Report.xlsx`); //or any other extension
      document.body.appendChild(link);
      link.click();
      if (resolve) {
        resolve({
          data: res.data,
        });
      }
    })
    .catch((err) => {
      dispatch(actionError(DOWNLOAD_REPORT_ERROR));
      toastError(lang === 'id' ? "Gagal mengunduh data!" : "Download Unsuccessfull!");
    });
};

const downloadLounge = (param, resolve) => (dispatch) => {
  dispatch(actionPending(DOWNLOAD_REPORT_PENDING));
  API.get(DOWNLOAD_LOUNGE_REPORT_URL, {
    params: param,
    responseType: "blob",
  })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DOWNLOAD_REPORT_SUCCESS, res));
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Reservation Lounge Report.xlsx`); //or any other extension
      document.body.appendChild(link);
      link.click();
      if (resolve) {
        resolve({
          data: res.data,
        });
      }
    })
    .catch((err) => {
      dispatch(actionError(DOWNLOAD_REPORT_ERROR));
      toastError(lang === 'id' ? "Gagal mengunduh data!" : "Download Unsuccessfull!");
    });
};

const report = {
  getSales,
  getCorporate,
  getCorporateNonMOB,
  getAssistant,
  getCustomer,
  getProduction,
  getCommission,
  getCrossSelling,
  getAPIReport,
  getResumeCommission,
  getRKAPProduction,
  getRKAPIncome,
  getTotalProductionRKAP,
  getTotalIncomeRKAP,
  getCommissionLounge,
  getResumeCommissionLounge,
  getReportLounge,
  downloadAssistant,
  downloadCustomer,
  downloadSales,
  downloadCorporate,
  downloadCorporateNonMOB,
  downloadProduction,
  downloadCommission,
  downloadCommissionLounge,
  downloadCrossSelling,
  downloadRKAP,
  downloadAPIReport,
  importRKAP,
  downloadLounge
};
export default report;

import React from "react";
import { Formik, Form } from "formik";
import { withTrans } from "../../../i18n/withTrans";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { connect } from "react-redux";
import debounce from "lodash.debounce";
import Select2 from "../../../components/forms/Select2";
import ButtonModal from "../../../components/modal/ButtonModal";
import DatePicker from "../../../components/forms/DateRangePicker";
import { RoleIash } from "../../../utils/Constants";

function ModalFilter({
  t,
  show,
  toggle,
  airport,
  lounges,
  user,
  setSearchLounge,
  filter,
  toggleFilter,
  isDelete,
  onApplyFilter,
}) {
  const debouncedSearchLounge = debounce((value) => {
    if (value !== "") setSearchLounge(value);
  }, 500);

  const handleReset = (formik) => {
    formik.setFieldValue("status", "");
    formik.setFieldValue("payment_status", "");
    formik.setFieldValue("booking_type", "");
    formik.setFieldValue("start_date", "");
    formik.setFieldValue("end_date", "");
    formik.setFieldValue("service_start_date", "");
    formik.setFieldValue("service_end_date", "");
    formik.setFieldValue("airport_id", "");
    formik.setFieldValue("lounge_id", "");
    toggleFilter({
      status: "",
      payment_status: "",
      booking_type: "",
      start_date: "",
      end_date: "",
      service_start_date: "",
      service_end_date: "",
      airport_id: "",
      lounge_id: ""
    });
    toggle();
  };

  return (
    <>
      <Modal isOpen={show} modalTransition={{ timeout: 200 }}>
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          Filter{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <ModalBody className="mx-3">
          <Formik
            initialValues={{
              payment_status: filter?.payment_status ?? "",
              status: filter?.status ?? "",
              booking_type: filter?.booking_type ?? "",
              start_date: filter?.start_date ?? "",
              end_date: filter?.end_date ?? "",
              service_start_date: filter?.service_start_date ?? "",
              service_end_date: filter?.service_end_date ?? "",
              airport_id: filter?.airport_id ?? "",
              lounge_id: filter?.lounge_id ?? "",
            }}
            onSubmit={(values) => {
              onApplyFilter(
                {
                  airport_id: values.airport_id,
                  lounge_id: values.lounge_id,
                  payment_status: values.payment_status,
                  status: values.status,
                  booking_type: values.booking_type,
                  start_date: values.start_date,
                  end_date: values.end_date,
                  service_start_date: values.service_start_date,
                  service_end_date: values.service_end_date,
                }
              );
              toggle();
            }}
          >
            {(props) => (
              <Form>
                <DatePicker
                  title={t("field.bookingTime")}
                  name="start_date"
                  handlechange={(startDate, endDate) => {
                    props.setFieldValue("start_date", startDate);
                    props.setFieldValue("end_date", endDate);
                  }}
                  format="DD MMMM YYYY"
                  startDate={filter ? filter?.start_date : props.values?.start_date}
                  endDate={filter ? filter?.end_date : props.values?.end_date}
                  className="mx-auto"
                  errors={props.errors}
                  touched={props.touched}
                />
                <DatePicker
                  title={t("report.serviceTime")}
                  name="service_start_date"
                  handlechange={(startDate, endDate) => {
                    props.setFieldValue("service_start_date", startDate);
                    props.setFieldValue("service_end_date", endDate);
                  }}
                  format="DD MMMM YYYY"
                  startDate={filter ? filter?.service_start_date : props.values?.service_start_date}
                  endDate={filter ? filter?.service_end_date : props.values?.service_end_date}
                  className="mx-auto"
                  errors={props.errors}
                  touched={props.touched}
                />
                {user !== RoleIash.Cashier_Iash && (
                  <Select2
                    title={t("field.airport")}
                    name="airport_id"
                    options={[
                      {
                        value: "",
                        label: `${t("commons.all")} ${t("field.airport")}`,
                      },
                      ...airport,
                    ]}
                    errors={props.errors}
                    touched={props.touched}
                    value={props.values?.airport_id}
                    onChange={(name, value) => {
                      props.setFieldValue("airport_id", value);
                    }}
                    onBlur={props.setFieldTouched}
                    onFocus={() => setSearchLounge('')}
                  />
                )}
                <Select2
                  title={t("field.lounge")}
                  name="lounge_id"
                  options={[
                    {
                      value: "",
                      label: `${t("commons.all")} ${t("field.lounge")}`,
                    },
                    ...lounges,
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.lounge_id}
                  onInputChange={(keyword) => {
                    debouncedSearchLounge(keyword);
                  }}
                  onChange={(name, value) => {
                    if (!value) setSearchLounge("");
                    props.setFieldValue("lounge_id", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <Select2
                  title={t("field.paymentStatus")}
                  name="payment_status"
                  options={[
                    {
                      value: "",
                      label: `${t("commons.all")} ${t("field.paymentStatus")}`,
                    },
                    {
                      value: "paid",
                      label: t("status.paid"),
                    },
                    {
                      value: "unpaid",
                      label: t("status.unpaid"),
                    },
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.payment_status}
                  onChange={(name, value) => {
                    props.setFieldValue("payment_status", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <Select2
                  title="Status"
                  name="status"
                  options={[
                    {
                      value: "",
                      label: `${t("commons.all")} Status`,
                    },
                    {
                      value: -1,
                      label: t("status.cancelled"),
                    },
                    {
                      value: 0,
                      label: t("status.new"),
                    },
                    {
                      value: 1,
                      label: t("status.done"),
                    }
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.status}
                  onChange={(name, value) => {
                    props.setFieldValue("status", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <Select2
                  title={t("field.bookingType")}
                  name="booking_type"
                  options={[
                    {
                      value: "",
                      label: `${t("commons.all")} ${t(
                        "field.bookingType"
                      )}`,
                    },
                    {
                      value: "mobile",
                      label: t("field.mobileApp"),
                    },
                    {
                      value: "web-public",
                      label: t("field.webPublic"),
                    },
                    {
                      value: "web-admin",
                      label: t("field.webAdmin"),
                    },
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.booking_type}
                  onChange={(name, value) => {
                    props.setFieldValue("booking_type", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <div className="mt-5 mb-3">
                  <ButtonModal
                    toggle={toggle}
                    reset={() => {
                      setSearchLounge("")
                      handleReset(props)
                    }}
                    deleted={isDelete}
                    confirmTitle={t("field.apply")}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
}

const mapStateToProps = ({ masterDataRegion }) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(ModalFilter)
);

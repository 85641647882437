import { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { Row, Col } from "reactstrap";
import ReactTooltip from "react-tooltip";
import Compressor from "compressorjs";
import moment from "moment";

import Card from "../../../components/cards/card";
import CardHeader from "../../../components/cards/cardHeader";
import CardBody from "../../../components/cards/cardBody";
import CardFlush from "../../../components/cards/cardFlush";
import VoucherModal from "./modal/VoucherModal";
import Select2 from "../../../components/forms/Select2";
import { withTrans } from "../../../i18n/withTrans";
import { toastError } from "../../../components/commons/toast";
import { numberWithCommas, Role } from "../../../utils/Constants";
import { conversionToBytes, getLengthPassAdult, getLengthPassChild, getLengthPassInfant } from "../../../utils/Helper";

import ReservationLounge from "../../../store/actions/reservation_lounge";
import InputImage from "../../../components/forms/InputImage";

const Checkout = ({ button, formik, data, t, user }) => {
  const dispatch = useDispatch();
  const fileRef = useRef();
  const [voucherModal, setVoucherModal] = useState(false);
  const [joumpaService, setJoumpaService] = useState(0);
  const [grandTotalPrice, setGrandTotalPrice] = useState(0);
  const [dataCheckout, setDataCheckout] = useState(formik?.values);
  const [passengersAdult, setPassengersAdult] = useState(0);
  const [passengersChild, setPassengersChild] = useState(0);
  const [passengersInfant, setPassengersInfant] = useState(0);
  const [showPriceService, setShowPriceService] = useState(false);
  const [showLounge, setShowLounge] = useState(false);
  const [fileProp, setFileProp] = useState({
    title: t("booking.uploadPaymentProof"),
    subtitle: "Format .JPG or .PNG (Max. 2MB)",
  });

  useEffect(() => {
    getPriceFromApi();
  }, [data, formik?.values?.joumpa_promo_id, formik?.values?.lounge_promo_id]);

  const getPriceFromApi = () => {
    const values = formik?.values;

    new Promise((resolve) => {
      let param = {
        add_on_joumpa: values?.product_type ? true : false,
        airplane_name: values?.airplane_name || null,
        airplane_number: values?.airplane_number || null,
        airport_id_from: values?.airport_id_from || null,
        airport_id_to: values?.airport_id_to || null,
        lounge_id: values?.lounge || null,
        customer_id: null,
        date: values?.date || null,
        group_price_id: values?.group_price_id || null,
        passenger_list: values?.passenger_list || null,
        passenger_notes: values?.passenger_notes || null,
        payment_type: values?.payment_type || null,
        period_id: values?.period_id || null,
        price_id: values?.price_id || null,
        product_list_id: values?.product_list_id || null,
        product_name: values?.product_type || null,
        joumpa_promo_id: values?.joumpa_promo_id || null,
        lounge_promo_id: values?.lounge_promo_id || null,
        region: values?.region || null,
        time: values?.time || null,
        type: values?.flight_type + 1,
        transport_price_id: values?.orderExtras?.car?.id || null,
        transport_number: values?.total_unit || null,
      };

      if (values.passenger_list.length < 1) {
        toastError(
          `${t("commons.empty_data_message")} ${t("field.passenger")}!`
        );
        return;
      }
      dispatch(ReservationLounge.calculate_price(param, resolve));
    }).then((res) => {
      setDataCheckout({...formik?.values, ...res?.data});
      setGrandTotalPrice(res?.data?.total_price);
      setJoumpaService(res?.data?.joumpa_service);
      setPassengersAdult(getLengthPassAdult(formik?.values?.passenger_list));
      setPassengersChild(getLengthPassChild(formik?.values?.passenger_list));
      setPassengersInfant(getLengthPassInfant(formik?.values?.passenger_list));
      formik.setFieldValue("product_id", res?.data?.joumpa_service?.product_id);
    });
  };

  const handleDeleteVoucher = (formik) => {
    formik?.setFieldValue("joumpa_promo_id", null);
    formik?.setFieldValue("lounge_promo_id", null);
    formik?.setFieldValue("applied_promo_lounge", null);
    formik?.setFieldValue("applied_promo_joumpa", null);
  };

  const openFileDialog = () => {
    fileRef.current.click();
  };

  const imageChange = (e) => {
    if (!e.target.files && !e.target.files.length > 0) return;
    
    if (Number(e.target.files[0].size) < conversionToBytes(2)) {
      const image = e.target.files[0];
      const imageName = image.name;
      new Compressor(image, {
        quality: 0.6,
        success: (res) => {
          formik?.setFieldValue("paymentProof", URL.createObjectURL(res));
          const file = new File([res], imageName);
          formik.setFieldValue("file", file);
        },
        error(err) {
          console.log(err.message);
        },
      });
    } else {
      toastError(t("commons.validationSizeImg"))
    }
  };

  const removeSelectedImage = () => {
    formik.setFieldValue("paymentProof", null);
    formik.setFieldValue("file", null);
    fileRef.current.value = "";
  };

  const toggleLounge = () => {
    setShowLounge(!showLounge);
  };

  const togglePriceService = () => {
    setShowPriceService(!showPriceService);
  };

  const infoTax = (
    id,
    include_ppn,
    include_concession,
    ppn_percentage,
    concession_percentage,
    ppn_price,
    concession_price
  ) => {
    return (
      <>
        <span
          className="material-icons title"
          data-tip
          data-for={id}
        >
          info_outlined
        </span>
        <ReactTooltip id={id} place="top" effect="solid">
          <p className="text-pre-line">
            {(include_ppn && !include_concession && ppn_percentage !== null) ||
            (include_ppn &&
              include_concession &&
              concession_percentage === null &&
              ppn_percentage !== null) ? (
              <span>
                {`${t("product.includes_ppn")} ${ppn_percentage}%, Rp ${numberWithCommas(ppn_price)}`}
              </span>
            ) : !include_ppn &&
              include_concession &&
              concession_percentage !== null ? (
              <span>
                {`${t("product.includes_concession")} ${concession_percentage}%, Rp ${
                    numberWithCommas(concession_price)
                  }`}
              </span>
            ) : include_ppn &&
              include_concession &&
              concession_percentage !== null &&
              ppn_percentage !== null ? (
              <span>
                {`${t(
                    "product.includes_ppn"
                  )} ${ppn_percentage}%, Rp ${numberWithCommas(ppn_price)} & ${t(
                    "product.includes_concession"
                  )} ${concession_percentage}%, Rp ${numberWithCommas(
                    concession_price
                  )}`
                }
              </span>
            ) : (
              ""
            )}
          </p>
        </ReactTooltip>
      </>
    );
  };

  return (
    <>
      <Row>
        <Col md={9}>
          <Card className="rounded">
            <CardHeader title="Booking Resume" />
            <CardBody>
              <CardFlush
                list
                label={t("field.flightType")}
                desc={
                  formik?.values?.flight_type === 1
                    ? t("field.departure")
                    : t("field.arrival")
                }
                size={{ label: "5", desc: "5" }}
              />
              <CardFlush
                list
                label={t("field.airline") + " / No."}
                desc={
                  formik.values?.airplane_name +
                  " / " +
                  formik?.values?.airplane_code +
                  formik.values?.airplane_number
                }
                size={{ label: "5", desc: "5" }}
              />
              <CardFlush
                list
                label={t("field.origin")}
                desc={
                  formik.values?.airport_uniform_from !== "Indonesia"
                    ? formik.values?.airport_name_from +
                      " - " +
                      formik.values?.airport_uniform_from
                    : formik.values?.airport_name_from
                }
                size={{ label: "5", desc: "5" }}
              />
              {
                (data = [
                  {
                    label: t("field.destination"),
                    desc:
                      formik.values?.airport_uniform_to !== "Indonesia"
                        ? formik.values?.airport_name_to +
                          " - " +
                          formik.values?.airport_uniform_to
                        : formik.values?.airport_name_to,
                  },
                  {
                    label:
                      formik?.values?.flight_type === 1
                        ? t("field.departureTime")
                        : t("field.arrivalTime"),
                    desc:
                      moment(formik.values.date).format("DD/MM/YYYY") +
                      ", " +
                      formik.values.time,
                  },
                ].map((item, i) => (
                  <CardFlush
                    list
                    label={item.label}
                    desc={item.desc}
                    size={{ label: "5", desc: "5" }}
                  />
                )))
              }
              <CardFlush
                list
                label={t("field.lounge")}
                desc={formik?.values?.orderExtras?.lounge?.name}
                size={{ label: "5", desc: "5" }}
              />
            </CardBody>
          </Card>
          <Card className="rounded">
            <CardHeader title={t("field.additionalService")} />
            <CardBody>
              <div className="d-flex justify-content-left">
                <div
                  className="rounded-fill"
                  style={{
                    backgroundColor:
                      formik.values.product_type &&
                      formik?.values?.product_list_id
                        ? "#ecf5eb"
                        : "#fff",
                    border:
                      formik.values.product_type &&
                      formik?.values?.product_list_id
                        ? "1px solid #63AE5C"
                        : "1px solid rgb(165, 165, 192)",
                  }}
                >
                  <span
                    className={`material-icons super-title ${
                      formik.values.product_type &&
                      formik?.values?.product_list_id
                        ? "text-primary"
                        : "text-muted"
                    }`}
                  >
                    assistant
                  </span>
                </div>
                <div className="d-flex flex-column ml-3">
                  <div className="text-bold">Joumpa {t("field.assistant")}</div>
                  <div className="normal-title">
                    {formik.values.product_type &&
                    formik?.values?.product_list_id
                      ? formik.values.available_service
                      : t("commons.none")}
                  </div>
                </div>
              </div>
              <div className="my-3" />
              <div className="d-flex justify-content-left">
                <div
                  className="rounded-fill"
                  style={{
                    backgroundColor:
                      formik.values.region ? "#ecf5eb" : "#fff",
                    border:
                      formik.values.region
                        ? "1px solid #63AE5C"
                        : "1px solid rgb(165, 165, 192)",
                  }}
                >
                  <span
                    className={`material-icons super-title ${
                      formik.values.product_type &&
                      formik?.values?.product_list_id
                        ? "text-primary"
                        : "text-muted"
                    }`}
                  >
                    local_taxi
                  </span>
                </div>
                <div className="d-flex flex-column ml-3">
                  <div className="text-bold">{t("field.transportation")}</div>
                  <div className="normal-title">
                    {formik.values.region
                      ? formik.values.region +
                        " - " +
                        formik.values.orderExtras.car.name +
                        " (" +
                        formik.values.total_unit +
                        " unit)"
                      : t("commons.none")}
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card className="rounded">
            <CardHeader title={t("field.detailPrice")} />
              {formik?.values?.lounge_promo_id || formik?.values?.joumpa_promo_id ? (
                <div className="rounded mx-4 card-activated">
                  <div className="d-flex justify-content-between p-3">
                    <div className="d-flex flex-column">
                      <div className="text-bold">
                        Voucher
                      </div>
                      {formik?.values?.lounge_promo_id && 
                      formik?.values?.joumpa_promo_id && (
                        <div className="normal-text mt-2">
                          {`2 voucher ${t("field.applied").toLowerCase()}`}
                        </div>
                      )}
                    </div>
                    <div className="d-flex justify-content-between clickable align-items-center">
                      {formik?.values?.lounge_promo_id && !formik?.values?.joumpa_promo_id
                        ? (
                            <div className="normal-text mr-5">
                              {formik?.values?.lounge_promo_percent
                                ? `${t("commons.discount")} ${
                                    formik?.values?.lounge_promo_percent
                                  }% ${formik?.values?.lounge_promo_name}`
                                : `${t("commons.discount")} ${numberWithCommas(
                                    formik?.values?.lounge_promo_price
                                  )} ${formik?.values?.lounge_promo_name}`}
                            </div>
                          )
                        : !formik?.values?.lounge_promo_id && formik?.values?.joumpa_promo_id
                        ? (
                            <div className="normal-text mr-5">
                              {formik?.values?.joumpa_promo_percent
                                ? `${t("commons.discount")} ${
                                    formik?.values?.joumpa_promo_percent
                                  }% ${formik?.values?.joumpa_promo_name}`
                                : `${t("commons.discount")} ${numberWithCommas(
                                    formik?.values?.joumpa_promo_price
                                  )} ${formik?.values?.joumpa_promo_name}`}
                            </div>
                          )
                        : (
                            <>
                            <div className="normal-text text-ellipsis">
                              {formik?.values?.lounge_promo_name}{" & "}
                            </div>
                            <div className="normal-text text-ellipsis mr-2">
                              {formik?.values?.joumpa_promo_name}
                            </div>
                            </>
                        )
                      }
                      {formik?.values?.lounge_promo_id && 
                        formik?.values?.joumpa_promo_id ? (
                          <span
                            className="material-icons-outlined"
                            onClick={() => setVoucherModal(true)}
                          >
                            keyboard_arrow_right
                          </span>
                        ) : (
                          <span
                            className="material-icons-outlined"
                            onClick={() => handleDeleteVoucher(formik)}
                          >
                            close
                          </span>
                        )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="border rounded mx-4">
                  <div className="d-flex justify-content-between p-3">
                    <div className="text-extra-bold">Voucher</div>
                    <div
                      className="d-flex justify-content-between clickable"
                      onClick={() => setVoucherModal(true)}
                    >
                      <div className="normal-title text-light mr-5">
                        {t("field.selectVoucher")}
                      </div>
                      <span className="material-icons-outlined">
                        navigate_next
                      </span>
                    </div>
                  </div>
                </div>
              )}
            <div className="d-flex card-body justify-content-between py-3">
              <div className="normal-title text-extra-bold">
                {t("field.grandTotal")}
              </div>
              <div className="normal-title text-extra-bold">
                Rp {numberWithCommas(grandTotalPrice)}
              </div>
            </div>
            <div
              className={`card-body bg-light-secondary border-top py-3 pr-4 ${
                !formik.values.product_type ? "rounded-last-card" : ""
              }`}
            >
              <div className="d-flex justify-content-between">
                <div className="normal-title text-extra-bold">
                  {t("field.lounge")}
                </div>
                {formik?.values?.lounge ? (
                  <div
                    onClick={toggleLounge}
                    className="d-flex align-items-center clickable"
                  >
                    <span className="normal-title text-extra-bold mr-2">
                      Rp {numberWithCommas(dataCheckout?.price_service)}
                    </span>
                    <span className="material-icons-round left-icon mr-0">
                      expand_more
                    </span>
                  </div>
                ) : (
                  <div className="normal-title text-extra-bold clickable">
                    Rp 0
                  </div>
                )}
              </div>
              {showLounge && (
                <>
                  {passengersAdult > 0 && (
                    <>
                      <div className="subtitle-text text-medium text-primary mt-2 mb-1">
                        {t("commons.adult")}
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          <div className="d-flex align-items-center normal-text mb-1">
                            {t("field.price")}
                            {dataCheckout?.is_include_ppn && (
                              <>
                                {infoTax(
                                  "taxLoungeAdult",
                                  dataCheckout?.is_include_ppn,
                                  null,
                                  dataCheckout?.ppn_percentage,
                                  null,
                                  dataCheckout?.ppn_adult_price,
                                  null
                                )}
                              </>
                            )}
                          </div>
                          {!dataCheckout?.is_include_ppn && (
                            <div className="normal-text mt-1">
                              {`PPN ${dataCheckout?.ppn_percentage}%`}
                            </div>
                          )}
                        </div>
                        <div className="text-right">
                          <div className="normal-text">
                            {passengersAdult + " "}
                            <span>
                              {" x  Rp " +
                                numberWithCommas(
                                  dataCheckout?.adult_price_original
                                )}
                            </span>
                          </div>
                          {!dataCheckout?.is_include_ppn && (
                            <div className="normal-text mt-1">
                              {"Rp " +
                                numberWithCommas(dataCheckout?.ppn_adult_price)}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {passengersChild > 0 && (
                    <>
                      <div className="subtitle-text text-medium text-primary mt-2 mb-1">
                        {t("commons.child")}
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          <div className="d-flex align-items-center normal-text mb-1">
                            {t("field.price")}
                            {dataCheckout?.is_include_ppn && (
                              <>
                                {infoTax(
                                  "taxLoungeChild",
                                  dataCheckout?.is_include_ppn,
                                  null,
                                  dataCheckout?.ppn_percentage,
                                  null,
                                  dataCheckout?.ppn_child_price,
                                  null
                                )}
                              </>
                            )}
                          </div>
                          {!dataCheckout?.is_include_ppn && (
                            <div className="normal-text mt-1">
                              {`PPN ${dataCheckout?.ppn_percentage}%`}
                            </div>
                          )}
                        </div>
                        <div className="text-right">
                          <div className="normal-text">
                            {passengersChild + " "}
                            <span>
                              {" x  Rp " +
                                numberWithCommas(
                                  dataCheckout?.child_price_original
                                )}
                            </span>
                          </div>
                          {!dataCheckout?.is_include_ppn && (
                            <div className="normal-text mt-1">
                              {"Rp " +
                                numberWithCommas(dataCheckout?.ppn_child_price)}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {passengersInfant > 0 && (
                    <>
                      <div className="subtitle-text text-medium text-primary mt-2 mb-1">
                        {t("commons.infantLabel")}
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          <div className="normal-text mb-1">
                            {t("field.price")}
                            {dataCheckout?.is_include_ppn && (
                              <>
                                {infoTax(
                                  "taxLoungeInfant",
                                  dataCheckout?.is_include_ppn,
                                  null,
                                  dataCheckout?.ppn_percentage,
                                  null,
                                  dataCheckout?.ppn_infant_price,
                                  null
                                )}
                              </>
                            )}
                          </div>
                          {!dataCheckout?.is_include_ppn && (
                            <div className="normal-text mt-1">
                              {`PPN ${dataCheckout?.ppn_percentage}%`}
                            </div>
                          )}
                        </div>
                        <div className="text-right">
                          <div className="normal-text">
                            {passengersInfant + " "}
                            <span>
                              {" x  Rp " +
                                numberWithCommas(
                                  dataCheckout?.infant_price_original
                                )}
                            </span>
                          </div>
                          {!dataCheckout?.is_include_ppn && (
                            <div className="normal-text mt-1">
                              {"Rp " +
                                numberWithCommas(
                                  dataCheckout?.ppn_infant_price
                                )}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {formik?.values?.lounge_promo_id !== null && (
                    <div>
                      <div className="normal-title text-extra-bold my-1">
                        Promo
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="normal-text">
                          {formik?.values?.lounge_promo_percent
                            ? `${formik?.values?.lounge_promo_name} ${formik?.values?.lounge_promo_percent}%`
                            : formik?.values?.lounge_promo_name}
                          <span className="small-vertical-divider"></span>
                          <span className="text-primary">
                            {formik?.values?.lounge_promo_type === "specific"
                              ? t("promo.special")
                              : formik?.values?.lounge_promo_type}
                          </span>
                        </div>
                        <div className="normal-text text-danger">
                          - Rp
                          {numberWithCommas(dataCheckout?.price_cut ?? 0)}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            {formik.values.product_type && formik?.values?.product_list_id ? (
              <>
                <div className="card-body border-top bg-light-secondary rounded-last-card py-3 pr-4">
                  <div className="d-flex justify-content-between">
                    <div className="normal-title text-extra-bold">
                      Joumpa {t("field.assistant")}
                    </div>
                    <div
                      onClick={togglePriceService}
                      className="d-flex align-items-center clickable"
                    >
                      <span className="normal-title text-extra-bold mr-2">
                        Rp {numberWithCommas(joumpaService?.total_price)}
                      </span>
                      <span className="material-icons-round left-icon mr-0">
                        expand_more
                      </span>
                    </div>
                  </div>
                  {showPriceService && (
                    <>
                      {passengersAdult !== 0 && (
                        <>
                          <div className="subtitle-text text-medium text-primary mt-2 mb-1">
                            {t("commons.adult")}
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>
                              <div className="d-flex align-items-center normal-text">
                                {t("field.price")}
                                {(joumpaService?.is_include_ppn ||
                                  joumpaService?.is_include_concession) && (
                                  <>
                                    {infoTax(
                                      "taxAdult",
                                      joumpaService?.is_include_ppn,
                                      joumpaService?.is_include_concession,
                                      joumpaService?.ppn_percentage,
                                      joumpaService?.concession_percentage,
                                      joumpaService?.ppn_adult_price,
                                      joumpaService?.concession_adult_price
                                    )}
                                  </>
                                )}
                              </div>
                              {!joumpaService?.is_include_ppn && (
                                <div className="normal-text mt-1">
                                  {`PPN ${joumpaService?.ppn_percentage}%`}
                                </div>
                              )}
                              {!joumpaService?.is_include_concession && (
                                <div className="normal-text mt-1">
                                  {`Concession ${joumpaService?.concession_percentage}%`}
                                </div>
                              )}
                            </div>
                            <div className="text-right">
                              <div className="normal-text">
                                {passengersAdult + " "}
                                <span>
                                  {" x  Rp " +
                                    numberWithCommas(
                                      joumpaService?.adult_price_original
                                    )}
                                </span>
                              </div>
                              {!joumpaService?.is_include_ppn && (
                                <div className="normal-text mt-1">
                                  {"Rp " +
                                    numberWithCommas(
                                      joumpaService?.ppn_adult_price
                                    )}
                                </div>
                              )}
                              {!joumpaService?.is_include_concession && (
                                <div className="normal-text mt-1">
                                  {"Rp " +
                                    numberWithCommas(
                                      joumpaService?.concessionJoumpaAdultPrice
                                    )}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                      {passengersChild !== 0 && (
                        <>
                          <div className="subtitle-text text-medium text-primary mt-2 mb-1">
                            {t("commons.child")}
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>
                              <div className="d-flex align-items-center normal-text">
                                {t("field.price")}
                                {(joumpaService?.is_include_ppn ||
                                  joumpaService?.is_include_concession) && (
                                  <>
                                    {infoTax(
                                      "taxChild",
                                      joumpaService?.is_include_ppn,
                                      joumpaService?.is_include_concession,
                                      joumpaService?.ppn_percentage,
                                      joumpaService?.concession_percentage,
                                      joumpaService?.ppn_child_price,
                                      joumpaService?.concession_adult_price
                                    )}
                                  </>
                                )}
                              </div>
                              {!joumpaService?.is_include_ppn && (
                                <div className="normal-text mt-1">
                                  {`PPN ${joumpaService?.ppn_percentage}%`}
                                </div>
                              )}
                              {!joumpaService?.is_include_concession && (
                                <div className="normal-text mt-1">
                                  {`Concession ${joumpaService?.concession_percentage}%`}
                                </div>
                              )}
                            </div>
                            <div className="text-right">
                              <div className="normal-text">
                                {passengersChild + " "}
                                <span>
                                  {" x  Rp " +
                                    numberWithCommas(
                                      joumpaService?.child_price_original
                                    )}
                                </span>
                              </div>
                              {!joumpaService?.is_include_ppn && (
                                <div className="normal-text mt-1">
                                  {"Rp " +
                                    numberWithCommas(
                                      joumpaService?.ppn_child_price
                                    )}
                                </div>
                              )}
                              {!joumpaService?.is_include_concession && (
                                <div className="normal-text mt-1">
                                  {"Rp " +
                                    numberWithCommas(
                                      joumpaService?.concession_adult_price
                                    )}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                      {passengersInfant !== 0 && (
                        <>
                          <div className="subtitle-text text-medium text-primary mt-2 mb-1">
                            {t("commons.infantLabel")}
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>
                              <div className="d-flex align-items-center normal-text">
                                {t("field.price")}
                                {(joumpaService?.is_include_ppn ||
                                  joumpaService?.is_include_concession) && (
                                  <>
                                    {infoTax(
                                      "taxInfant",
                                      joumpaService?.is_include_ppn,
                                      joumpaService?.is_include_concession,
                                      joumpaService?.ppn_percentage,
                                      joumpaService?.concession_percentage,
                                      joumpaService?.ppn_infant_price,
                                      joumpaService?.concession_infant_price
                                    )}
                                  </>
                                )}
                              </div>
                              {!joumpaService?.is_include_ppn && (
                                <div className="normal-text mt-1">
                                  {`PPN ${joumpaService?.ppn_percentage}%`}
                                </div>
                              )}
                              {!joumpaService?.is_include_concession && (
                                <div className="normal-text mt-1">
                                  {`Concession ${joumpaService?.concession_percentage}%`}
                                </div>
                              )}
                            </div>
                            <div className="text-right">
                              <div className="normal-text">
                                {passengersInfant + " "}
                                <span>
                                  {" x  Rp " +
                                    numberWithCommas(
                                      joumpaService?.infant_price_original
                                    )}
                                </span>
                              </div>
                              {!joumpaService?.is_include_ppn && (
                                <div className="normal-text mt-1">
                                  {"Rp " +
                                    numberWithCommas(
                                      joumpaService?.ppn_infant_price
                                    )}
                                </div>
                              )}
                              {!joumpaService?.is_include_concession && (
                                <div className="normal-text mt-1">
                                  {"Rp " +
                                    numberWithCommas(
                                      joumpaService?.concession_infant_price
                                    )}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                      <div className="d-flex justify-content-between py-2">
                        <div className="d-flex align-items-center normal-title text-extra-bold">
                          {t("field.transportation")}
                          {formik.values.total_unit > 0 && (
                            <>
                              <span
                                className="material-icons title"
                                data-tip
                                data-for="taxTransports"
                              >
                                info_outlined
                              </span>
                              <ReactTooltip
                                id="taxTransports"
                                place="top"
                                effect="solid"
                              >
                                <p className="text-pre-line">
                                  {`${t("product.includes_ppn")} ${
                                    joumpaService?.ppn_percentage_transport
                                  }%, Rp ${numberWithCommas(
                                    joumpaService?.ppn_transport
                                  )}`}
                                </p>
                              </ReactTooltip>
                            </>
                          )}
                        </div>
                        <div className="normal-title ">
                          {formik.values.total_unit > 0
                            ? formik.values.total_unit +
                              " unit  x Rp" +
                              numberWithCommas(joumpaService?.transport_price)
                            : "Rp 0"}
                        </div>
                      </div>
                      {formik?.values?.joumpa_promo_id !== null && (
                        <div>
                          <div className="normal-title text-extra-bold mb-1">
                            Promo
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="normal-text">
                              {formik?.values?.joumpa_promo_percent
                                ? `${formik?.values?.joumpa_promo_name} ${formik?.values?.joumpa_promo_percent}%`
                                : formik?.values?.joumpa_promo_name}
                              <span className="small-vertical-divider"></span>
                              <span className="text-primary">
                                {formik?.values?.joumpa_promo_type === "specific"
                                  ? t("promo.special")
                                  : formik?.values?.joumpa_promo_type}
                              </span>
                            </div>
                            <div className="normal-text text-danger">
                              - Rp
                              {numberWithCommas(joumpaService?.price_cut ?? 0)}
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </Card>
          {user?.role_code === Role.Central_admin && (
            <Card className="rounded">
              <CardHeader title={t("booking.paymentProof")} />
              <CardBody>
                <div className="d-flex justify-content-between">
                  <div className="text-bold">{t("booking.paymentType")}</div>
                  <Col md={6} className="pr-0">
                    <Select2
                      name="payment_type"
                      errors={formik.errors}
                      options={[
                        { value: "cash", label: `${t("field.cash")}` },
                        { value: "transfer", label: "Transfer" },
                        { value: "deposit", label: "Deposit" },
                      ]}
                      clearable={!!formik.values?.payment_type}
                      touched={formik.touched}
                      value={formik?.values?.payment_type}
                      onChange={(name, value) => {
                        formik.setFieldValue("payment_type", value);
                      }}
                      onBlur={formik.setFieldTouched}
                    />
                  </Col>
                </div>
                <InputImage
                  title={fileProp?.title}
                  subtitle={fileProp?.subtitle}
                  icon="add_photo_alternate"
                  handleOpenFileDialog={openFileDialog}
                  handleChangeImage={imageChange}
                  fileRef={fileRef}
                  errors={formik.errors}
                  selectedImage={formik?.values?.paymentProof}
                  handleRemoveSelectedImg={removeSelectedImage}
                />
              </CardBody>
            </Card>
          )}
          {button()}
        </Col>
      </Row>

      <VoucherModal
        show={voucherModal}
        cancel={() => handleDeleteVoucher(formik)}
        toggle={() => setVoucherModal(!voucherModal)}
        formik={formik}
      />
    </>
  );
};
const mapStateToProps = ({ reg_price }) => {
  return { reg_price };
};

const mapDispatchToProps = () => {
  return {};
};
export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(Checkout)
);

import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
} from "reactstrap";
import { Formik, Form } from "formik";
import { useDispatch } from "react-redux";
import { withTrans } from "../../../i18n/withTrans";
import { toastError } from "../../../components/commons/toast";
import InputPrepend from "../../../components/forms/InputPrepend";
import Input from "../../../components/forms/Input";
import Select2 from "../../../components/forms/Select2";
import ButtonModal from "../../../components/modal/ButtonModal";
import Card from "../../../components/cards/card";
import CardFlush from "../../../components/cards/cardFlush";
import ConfirmationModal from "../../../components/modal/ConfirmModal";

import AirlineService from "../../../store/actions/master_data/airline";

const RescheduleBookingModal = ({
  t,
  show,
  toggle,
  action,
  data,
  data_put,
  error_message
}) => {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const [confirmModal, setConfirmModal] = useState(false);
  const [rescheduleData, setRescheduleData] = useState(null);
  const [togglePreviousFlight, setTogglePreviousFlight] = useState(false);
  const [toggleRescheduleFlight, setToggleRescheduleFlight] = useState(false);
  const [dataAirline, setDataAirline] = useState([]);
  const [searchTextAirline, setSearchTextAirline] = useState([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const initial = {
    id: data?.id,
    unique_transaction_id: data?.unique_transaction_id,
    date: "",
    time: "",
    airplane_code: "",
    airplane_name: "",
    airplane_number: "",
    selectedAirline: {},
  };

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchTextAirline,
      };
      dispatch(AirlineService.get(param, resolve));
    }).then((res) => {
      setDataAirline(
        res?.data?.map((item) => {
          return {
            value: item?.name,
            code: item?.code,
            label: `${item?.code} - ${item?.name}`,
          }
        })
      );
    });
  }, [searchTextAirline]);

  const showConfirmModal = (values) => {
    setConfirmModal(!confirmModal);
    
    if (values) {
      setRescheduleData(values);
    }
  };

  const handleReschedule = () => {
    let values = rescheduleData;

    if (
      (values?.date === "" || values?.date === null) &&
      (values?.time === "" || values?.time === null) &&
      (values?.airplane_code === "" || values?.airplane_code === null) &&
      (values?.airplane_name === "" || values?.airplane_name === null) &&
      (values?.airplane_number === "" || values?.airplane_number === null)
    ) {
      toastError(`${t("commons.empty_data_message")} ${t("field.reschedule")}!`);
      return;
    } else {
      let param = {
        order_id: data?.id,
        airplane_code:
          values?.airplane_code === "" 
            ? data?.airplane_data?.airplane_code
            : values?.airplane_code,
        airplane_name:
          values?.airplane_name === "" 
            ? data?.airplane_data?.airplane_name
            : values?.airplane_name,
        airplane_number:
          values?.airplane_number === ""
            ? data?.airplane_data?.airplane_number
            : values?.airplane_number,
        date: values?.date === "" ? data?.date : values?.date,
        time: values?.time === "" ? data?.time : values?.time
      };

      setLoadingSubmit(true);
      action(param);
    }
  };

  useEffect(() => {
    if (data_put?.data?.status === 200) {
      setLoadingSubmit(false);
      setConfirmModal(false);
    }
  }, [data_put]);

  useEffect(() => {
    if (!error_message) return;

    setLoadingSubmit(false);
  }, [error_message]);

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" Reschedule Booking"}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <Formik
          innerRef={formikRef}
          initialValues={initial}
        >
          {(props) => (
            <Form>
              <ModalBody>
                <Container>
                  <Card className="rounded">
                    <div className="subtitle-text">
                      <div className="text-bold text-primary mt-2 p-1">
                        Booking Information
                      </div>
                      <CardFlush
                        list
                        label="ID Booking"
                        desc={data?.unique_transaction_id}
                      />
                      <CardFlush
                        list
                        label={t("field.lounge")}
                        desc={data?.lounge_data?.name}
                      />
                    </div>
                    <div className="bordered-b p-2 subtitle-text">
                      <div className="my-3 d-flex justify-content-between align-items-center">
                        <div className="text-bold text-primary">
                          Previous Flight Information
                        </div>
                        <div
                          className="clickable"
                          onClick={() => setTogglePreviousFlight(!togglePreviousFlight)}
                        >
                          <span className="material-icons-round left-icon">
                            expand_more
                          </span>
                        </div>
                      </div>
                      {togglePreviousFlight && (
                        <>
                        <div className="row">
                          <div className="col-md-6">
                            <Input
                              name="date"
                              type="date"
                              disabled
                              errors={props.errors}
                              touched={props.touched}
                              value={data?.date}
                              title={data?.type === 2 ? t("field.departureDate") : t("field.arrivalDate")}
                            />
                          </div>
                          <div className="col-md-6">
                            <Input
                              name="time"
                              disabled
                              errors={props.errors}
                              touched={props.touched}
                              value={data?.time}
                              title={data?.type === 2
                                ? t("field.departureTime") 
                                : t("field.arrivalTime")
                              }
                              type="time"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <Input
                              name="airplane_name"
                              type="text"
                              disabled
                              errors={props.errors}
                              touched={props.touched}
                              value={data?.airplane_data?.airplane_name}
                              title={data?.type === 2 
                                ? t("field.deptAirline") 
                                : t("field.arrvAirline")
                              }
                            />
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb-0">
                              <label className="form-label mb-1">
                                {t("field.flightNumber")}
                              </label>
                            </div>
                            <InputPrepend
                              name="airplane_number"
                              label={t("field.flightNumber")}
                              title={data?.airplane_data?.airplane_code}
                              value={data?.airplane_data?.airplane_number}
                              type="text"
                              width="25%"
                              mt="2"
                              disabled
                            />
                          </div>
                        </div>
                        </>
                      )}
                    </div>
                    <div className="p-2 subtitle-text">
                      <div
                        className="my-3 d-flex justify-content-between align-items-center"
                      >
                        <div className="text-bold text-primary my-3">
                          Rescheduled Flight Information
                        </div>
                        <div
                          className="clickable"
                          onClick={() => setToggleRescheduleFlight(!toggleRescheduleFlight)}
                        >
                          <span className="material-icons-round left-icon">
                            expand_more
                          </span>
                        </div>
                      </div>
                      {toggleRescheduleFlight && (
                        <>
                        <div className="row">
                          <div className="col-md-6">
                            <Input
                              name="date"
                              type="date"
                              errors={props.errors}
                              touched={props.touched}
                              {...props.getFieldProps("date")}
                              title={data?.type === 2 ? t("field.departureDate") : t("field.arrivalDate")}
                            />
                          </div>
                          <div className="col-md-6">
                            <Input
                              name="time"
                              errors={props.errors}
                              touched={props.touched}
                              {...props.getFieldProps("time")}
                              title={data?.type === 2
                                ? t("field.departureTime")
                                : t("field.arrivalTime")
                              }
                              type="time"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <Select2
                              title={data?.type === 2
                                ? t("field.deptAirline") 
                                : t("field.arrvAirline")
                              }
                              name="airplane_name"
                              options={dataAirline}
                              errors={props.errors}
                              touched={props.touched}
                              value={props.values?.airplane_name ?? ''}
                              onInputChange={(keyword) => {
                                if (keyword !== "") {
                                  setSearchTextAirline(keyword);
                                }
                              }}
                              onChange={(name, value) => {
                                props.setFieldValue("airplane_name", value);
                                let airplaneCode = dataAirline?.filter(function (el) {
                                  return el.value == value;
                                });
                                props.setFieldValue(
                                  "airplane_code",
                                  airplaneCode[0].code
                                );
                                props.setFieldValue("selectedAirline", {
                                  value: value,
                                  code: airplaneCode[0].code,
                                  label: `${airplaneCode[0].code} - ${value}`,
                                });
                              }}
                              onBlur={props.setFieldTouched}
                            />
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb-0">
                              <label className="form-label mb-1" >
                                {t("field.flightNumber")}
                              </label>
                            </div>
                            <InputPrepend
                              name="airplane_number"
                              label={t("field.flightNumber")}
                              title={props.values.airplane_code}
                              type="text"
                              width="25%"
                              mt="2"
                              touched={props.touched}
                              {...props.getFieldProps("airplane_number")}
                            />
                          </div>
                        </div>
                        </>
                      )}
                    </div>
                  </Card>
                </Container>
              </ModalBody>
              <ModalFooter>
                <ButtonModal
                  toggle={toggle}
                  confirm={() => showConfirmModal(props?.values)}
                  confirmTitle={t("commons.save")}
                  typeConfirm={"Button"}
                  icon
                />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
      <ConfirmationModal
        show={confirmModal}
        toggle={showConfirmModal}
        confirm={handleReschedule}
        messageData={`` + data?.unique_transaction_id}
        icon="info"
        message={`Are you sure reschedule booking `}
        disabledConfirm={loadingSubmit}
      />
    </>
  );
};

const mapStateToProps = ({ reservation_lounge: { data_put, error_message } }) => {
    return { data_put, error_message };
};

const mapDispatchToProps = () => {
    return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(RescheduleBookingModal));
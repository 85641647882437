import {
  GET_BOOKING_PENDING,
  GET_BOOKING_SUCCESS,
  GET_BOOKING_ERROR,
  POST_BOOKING_PENDING,
  POST_BOOKING_SUCCESS,
  POST_BOOKING_ERROR,
  PUT_BOOKING_PENDING,
  PUT_BOOKING_SUCCESS,
  PUT_BOOKING_ERROR,
  DELETE_BOOKING_PENDING,
  DELETE_BOOKING_SUCCESS,
  DELETE_BOOKING_ERROR,
  CALCULATE_PRICE_PENDING,
  CALCULATE_PRICE_SUCCESS,
  CALCULATE_PRICE_ERROR,
} from "../../actions/reservation_lounge";

const initialState = {
  pending: false,
  error: null,
  data_list: null,
  pending_post: false,
  data_post: null,
  pending_put: false,
  data_put: false,
  pending_delete: false,
  data_delete: null,
};

const reservation_lounge = (state = initialState, action) => {
  switch (action.type) {
    case GET_BOOKING_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_BOOKING_SUCCESS:
      return {
        ...state,
        pending: false,
        data_list: action.data,
      };
    case GET_BOOKING_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case POST_BOOKING_PENDING:
      return {
        ...state,
        pending_post: true,
        error_message: null,
      };
    case POST_BOOKING_SUCCESS:
      return {
        ...state,
        pending_post: false,
        data_post: action.data,
        error_message: null,
      };
    case POST_BOOKING_ERROR:
      return {
        ...state,
        pending_post: false,
        error: action.error,
        error_message: action?.error?.data?.message,
      };
    case PUT_BOOKING_PENDING:
      return {
        ...state,
        pending_put: true,
        error_message: null,
      };
    case PUT_BOOKING_SUCCESS:
      return {
        ...state,
        pending_put: false,
        data_put: action.data,
        error_message: null,
      };
    case PUT_BOOKING_ERROR:
      return {
        ...state,
        pending_put: false,
        error: action.error,
        error_message: action?.error?.data?.message,
      };
    case DELETE_BOOKING_PENDING:
      return {
        ...state,
        pending_delete: true,
      };
    case DELETE_BOOKING_SUCCESS:
      return {
        ...state,
        pending_delete: false,
        data_delete: action.data,
      };
    case DELETE_BOOKING_ERROR:
      return {
        ...state,
        pending_delete: false,
        error: action.error,
      };
    case CALCULATE_PRICE_PENDING:
      return {
        ...state,
        pending_CALCULATE: true,
      };
    case CALCULATE_PRICE_SUCCESS:
      return {
        ...state,
        pending_CALCULATE: false,
        data_CALCULATE: action.data,
      };
    case CALCULATE_PRICE_ERROR:
      return {
        ...state,
        pending_CALCULATE: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default reservation_lounge;

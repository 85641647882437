import React, { useState, useRef, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import { Container, Col } from "reactstrap";
import moment from "moment";
import * as Yup from "yup";

import SideMenu from "../../../components/commons/menu/SideMenu";
import Loader from "../../../components/commons/Loader";
import ButtonModal from "../../../components/modal/ButtonModal";
import Button from "../../../components/buttons/Button";
import HeaderMenu from "../../../components/commons/menu/HeaderMenu";
import FlightReservation from "./FlightReservation";
import PassengerReservation from "./PassengerReservation";
import AdtServiceReservation from "./AdtServiceReservation";
import Checkout from "./Checkout";
import ConfirmationModal from "../../../components/modal/ConfirmModal";
import OrderConfirmationModal from "../../../components/modal/ConfirmModal";
import { toastError } from "../../../components/commons/toast";
import { getUser } from "../../../utils/User";
import { withTrans } from "../../../i18n/withTrans";
import { history } from "../../../utils/History";

//Service
import Corp_Reservation from "../../../store/actions/corp_reservation";
import { moreThanInfantAge } from "../../../utils/Helper";
import { EMAIL_FORMAT_REGEX, EMAIL_WHITESPACE_REGEX, NAME_FORMAT_REGEX } from "../../../utils/Constants";

const Index = ({ pending, t, error_message }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const currentUser = getUser();
  const position = currentUser?.user?.role_name;
  const role = currentUser?.user?.role_code;
  const minDate =
    role === "admin_pusat"
      ? moment().add(1, "days").format("YYYY-MM-DD")
      : (role === "contact_center" || role === "lead_contact_center")
      ? null
      : moment().subtract(1, 'days').format("YYYY-MM-DD");

  const [show, setShow] = useState("flight-reservation");
  const [next, setNext] = useState("passenger-reservation");
  const [back, setBack] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sequence, setSequence] = useState(1);
  const [corporateType, setCorporateType] = useState("");
  const [cancelModal, setCancelModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [dataFormik, setDataFormik] = useState({});
  const [errorAvailabilityPrice, setErrorAvailabilityPrice] = useState(null);

  const showCancelModal = () => {
    setCancelModal(!cancelModal);
  };
  const handleCancel = () => {
    history.push("/corp_reservation");
  };

  const showConfirmModal = () => {
    setConfirmModal(!confirmModal);
  };

  const handleChangeForm = (tab) => {
    document.documentElement.scrollTop = 0;
    switch (tab) {
      case "flight-reservation":
        setShow("flight-reservation");
        setBack(null);
        setNext("passenger-reservation");
        setSequence(1);
        break;
      case "passenger-reservation":
        setShow("passenger-reservation");
        setBack("flight-reservation");
        setNext("adt-service-reservation");
        setSequence(2);
        break;
      case "adt-service-reservation":
        setShow("adt-service-reservation");
        setBack("passenger-reservation");
        setNext("checkout");
        setSequence(3);
        formikRef.current.setFieldTouched("transportation", false);
        formikRef.current.setFieldTouched("car_type", false);
        break;
      case "checkout":
        setShow("checkout");
        setBack("adt-service-reservation");
        setNext(null);
        setSequence(4);
        break;
    }
  };

  const findAvailabilityPrice = (tab) => {
    let values = formikRef?.current?.values;
    let airport_selected =
      values.flight_type === 0
        ?  values.airport_id_to
        :  values.flight_type === 1
        ?  values.airport_id_from
        :  values.airport_id_transfer;

    new Promise((resolve) => {
      let param = {
        corporate_id: values?.corporate_id,
        airport_service_id: airport_selected,
        flight_type: values?.flight_type_code,
        service_date:  values?.date,
        product_name:  values?.product_type,
        is_mob: values?.corporateType === "MOB" ? true : false,
      };
      if (
        values?.corporate_id &&
        airport_selected &&
        values?.flight_type_code &&
        values?.date &&
        values?.product_type
      ) {
        dispatch(Corp_Reservation.findAvailPrice(param, resolve));
      }
    }).then((res) => {
      if (res) {
        handleChangeForm(tab);
      }
    })
  };

  const handleNextClick = (tab) => {
    let values = formikRef.current.values;

    formikRef.current.validateForm().then(() => {
      if (tab === "passenger-reservation") {
        if (formikRef?.current?.values?.corporate_id === "") {
          toastError(
            `${t("commons.empty_data_message")} ${t("field.corporate")}!`
          );
          return;
        }
        if (values.flight_type < 0) {
          toastError(
            `${t("commons.empty_data_message")} ${t("field.flightType")}!`
          );
          return;
        }
        if (
          values.airport_id_from === "" ||
          values.airport_id_from === null
        ) {
          toastError(
            `${t("commons.empty_data_message")} ${t("field.origin")}!`
          );
          return;
        }
        if (
          values.airport_id_to === "" ||
          values.airport_id_to === null
        ) {
          toastError(
            `${t("commons.empty_data_message")} ${t("field.destination")}!`
          );
          return;
        }
        if (
          values.airplane_name === "" ||
          values.airplane_name === null
        ) {
          toastError(
            `${t("commons.empty_data_message")} ${t("field.airline")}!`
          );
          return;
        }
        if (
          values.airplane_number === "" ||
          values.airplane_number === null
        ) {
          toastError(
            `${t("commons.empty_data_message")} ${t("field.flightNumber")}!`
          );
          return;
        }
        if (
          values.date === "" ||
          values.date === null
        ) {
          toastError(
            `${t("commons.empty_data_message")} ${t("field.date")}!`
          );
          return;
        } else if (
          values.date !== "" &&
          values.date !== null &&
          values?.date < minDate
        ) {
          toastError(
            `${t("commons.orderDateHandle")} ${moment(minDate).format("DD/MM/YYYY")}!`
          );
          return;
        }
        if (
          values.time === "" ||
          values.time === null
        ) {
          toastError(
            `${t("commons.empty_data_message")} ${t("commons.time")}!`
          );
          return;
        }

        if (values.flight_type === 2) {
          let timeArrival = moment(
            `${values?.date}, ${values?.time}`, 
            "YYYY-MM-DD HH:mm"
          )?.diff(moment().startOf("day"), "minutes");

          let timeDeparture = moment(
            `${values?.date_transfer_to}, ${values?.time_transfer_to}`,
            "YYYY-MM-DD HH:mm"
          )?.diff(moment().startOf("day"), "minutes");

          if (timeArrival > timeDeparture) {
            toastError(t("commons.transfer_message"));
            return;
          }

          if (values.airport_id_transfer === "") {
            toastError(`${t("commons.empty_data_message")} Transfer!`);
            return;
          }
          if (values.airplane_name_transfer_to === "") {
            toastError(
              `${t("commons.empty_data_message")} ${t(
                "field.airline"
              )} !`
            );
            return;
          }
          if (
            values.airplane_number_transfer_to === ""
          ) {
            toastError(
              `${t("commons.empty_data_message")} ${t(
                "field.flightNumber"
              )} !`
            );
            return;
          }
          if (values.date_transfer_to === "") {
            toastError(
              `${t("commons.empty_data_message")} ${t(
                "field.departureDate"
              )} !`
            );
            return;
          } else if (
            values.date_transfer_to !== "" &&
            values.date_transfer_to !== null &&
            values?.date_transfer_to < minDate
          ) {
            toastError(
              `${t("commons.orderDateHandle")} ${moment(minDate).format("DD/MM/YYYY")}!`
            );
            return;
          }
          if (values.time_transfer_to === "") {
            toastError(
              `${t("commons.empty_data_message")} ${t(
                "field.departureTime"
              )} !`
            );
            return;
          }
        }
        if (
          values?.product_type === null ||
          values?.product_type === 0
        ) {
          toastError(
            `${t("commons.empty_data_message")} ${t("field.product")}!`
          );
          return;
        }

        if (formikRef.current.isValid) {
          findAvailabilityPrice(tab);
        }
      } else if (tab === "adt-service-reservation") {
        if (errorAvailabilityPrice !== null) {
          toastError(errorAvailabilityPrice);
          return;
        } else {
          let passengerNotValid = false;

          values?.passenger_list?.forEach((item) => {
            const yearDiff = moment().diff(
              item?.passenger_date_of_birth,
              "year"
            );

            if (item?.name === "" || item?.name === undefined) {
              toastError(
                `${t("commons.empty_data_message")} ${t("field.name")} ${t(
                  "field.passenger"
                )}!`
              );
              passengerNotValid = true;
            } else if (
              item?.name &&
              !NAME_FORMAT_REGEX.test(item?.name)
            ) {
              toastError(
                `${t("field.passengerName")} ${t("commons.wrong_name_format")}!`
              );
              passengerNotValid = true;
            } else if (
              item?.passenger_nationality === "" ||
              item?.passenger_nationality === undefined
            ) {
              toastError(
                `${t("commons.empty_data_message")} ${t(
                  "field.nationality"
                )} ${t("field.passenger")}!`
              );
              passengerNotValid = true;
            } else if (
              item?.title === "" || 
              item?.title === undefined
            ) {
              toastError(
                `${t("commons.empty_data_message")} ${t("field.title")} ${t(
                  "field.passenger"
                )}!`
              );
              passengerNotValid = true;
            } else if (
              item?.title !== "" &&
              item?.title !== "Mr." &&
              item?.title !== "Mrs." &&
              item?.title !== "Ms." &&
              item?.title !== "Infant" &&
              item?.title !== "Child"
            ) {
              toastError(`${t("commons.invalidTitlePassenger")}!`);
              passengerNotValid = true;
            } else if (
              item?.title === "Infant" &&
              (item?.passenger_date_of_birth === "" ||
              item?.passenger_date_of_birth === undefined)
            ) {
              toastError(`${t("commons.complete_birth_date_message")}!`);
              passengerNotValid = true;
            } else if (
              item?.title === "Infant" &&
              item?.passenger_date_of_birth &&
              moreThanInfantAge(item?.passenger_date_of_birth)
            ) {
              toastError(`${t("commons.birth_date_less_than_message")}!`);
              passengerNotValid = true;
            } else if (
              item?.title !== "Infant" && item?.title !== "Child" &&
              (item?.email === "" || item?.email === undefined)
            ) {
              toastError(
                `${t("commons.empty_data_message")} Email ${t(
                  "field.passenger"
                )}!`
              );
              passengerNotValid = true;
            } else if (
              item?.title !== "Infant" && item?.title !== "Child" &&
              (item?.phoneNumber === "" || item?.phoneNumber === undefined)
            ) {
              toastError(
                `${t("commons.empty_data_message")} ${t("field.phone")} ${t(
                  "field.passenger"
                )}!`
              );
              passengerNotValid = true;
            } else if (
              item?.email && (EMAIL_WHITESPACE_REGEX.test(item?.email))
            ) {
              item.email = item?.email.replace(/\s+/g, "")
            } else if (
              item?.email &&
              !EMAIL_FORMAT_REGEX.test(item?.email)
            ) {
              toastError(`${t("commons.wrong_email_format")} ${t("field.passenger")}!`);
              passengerNotValid = true;
            }
          });

          if (passengerNotValid) {
            return;
          }

          if (
            values.corporateType === "Group" &&
            !values.corporate_type_group_id
          ) {
            toastError(`${t("commons.empty_data_message")} ${t("field.cooperationType")}`);
            return;
          }
          if (
            !values?.paging_name &&
            !values?.paging_logo
          ) {
            toastError(
              `${t("commons.empty_data_message")} ${t(
                "field.pagingInfo"
              )}!`
            );
            return;
          }  
          if (values?.passenger_list.length < 1) {
            toastError(
              `${t("commons.empty_data_message")} ${t(
                "field.passenger"
              )}!`
            );
            return;
          }
          if (
            !(values?.bookers_title &&
              values?.bookers_name &&
              values?.bookers_nationality &&
              values?.bookers_email)
          ) {
            toastError(
              `${t("commons.empty_data_message")} ${t(
                "field.bookersInfo"
              )}!`
            );
            return;
          } else if (
            values?.bookers_name &&
            !NAME_FORMAT_REGEX.test(values?.bookers_name)
          ) {
            toastError(
              `${t("field.booker")} ${t("commons.wrong_name_format")}!`
            );
            return;
          } else if (EMAIL_WHITESPACE_REGEX.test(values?.bookers_email)) {
            values.bookers_email = values?.bookers_email.replace(/\s+/g, "")
          } else if (
            values?.bookers_email &&
            !EMAIL_FORMAT_REGEX.test(values?.bookers_email)
          ) {
            toastError(`${t("commons.wrong_email_format")} ${t("field.booker")}!`);
            return;
          } else if (
            values?.bookers !== "admin" &&
            (values?.bookers_phone === "" ||
            values?.bookers_phone.includes("undefined") ||
            values?.bookers_phone === values?.bookers_country_code)
          ) {
            toastError(
              `${t("commons.empty_data_message")} ${t(
                "field.bookersInfo"
              )}!`
            );
            return;
          }

          if (formikRef.current.isValid) {
            handleChangeForm(tab);
          }
        }
      } else if (tab === "checkout") {
        const countAdult = values?.passenger_list?.filter(
          (item) => item?.title === "Mr." || item?.title === "Mrs." || item?.title === "Ms."
        ).length;

        const countChild = values?.passenger_list?.filter(
          (item) => item?.title === "Child"
        ).length;

        const countInfant = values?.passenger_list?.filter(
          (item) => item?.title === "Infant"
        ).length;
        if (values?.lounge) {
          if (values?.lounge_adult_number > countAdult) {
            toastError(`${t("booking.handleAdultLounge")}!`);
            return;
          }
          if (values?.lounge_child_number > countChild) {
            toastError(`${t("booking.handleChildLounge")}!`);
            return;
          }
          if (values?.lounge_infant_number > countInfant) {
            toastError(`${t("booking.handleInfantLounge")}!`);
            return;
          }
        } else {
          formikRef?.current?.setFieldValue("lounge_adult_number", 0);
          formikRef?.current?.setFieldValue("lounge_child_number", 0);
          formikRef?.current?.setFieldValue("lounge_infant_number", 0);
        }
        if (values?.car_type) {
          if (!(values?.total_unit)) {
            toastError(
              `${t("commons.empty_data_message")} ${t("field.transportation")}!`
            );
            return;
          }
        } else {
          formikRef?.current?.setFieldValue("total_unit", 0);
        }

        if (
          (
            values?.additional_service_description || 
            values?.additional_service_concession_percentage ||
            values?.additional_service_ppn_percentage ||
            values?.additional_service_price
          ) &&
          !(
            values?.additional_service_description !== "" && 
            (
              values?.additional_service_concession_percentage !== null && 
              values?.additional_service_concession_percentage !== ""
            ) &&
            (
              values?.additional_service_ppn_percentage !== null && 
              values?.additional_service_ppn_percentage !== ""
            ) &&
            values?.additional_service_price > 0
          )
        ) {
          toastError(
            `${t("commons.empty_data_message")} ${t("field.additionalService")} Others!`
          );
          return;
        }

        if (formikRef.current.isValid) {
          handleChangeForm(tab);
        }
        formikRef.current.setFieldValue("promo_id", null);
      }
    });
  };

  const validation = {
    "flight-reservation": Yup.lazy((value) => {
      if (value.flight_type === -1) {
        return Yup.object().shape({
          corporate_id: Yup.string().required(t("commons.required")).nullable(),
          airport_id_from: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airport_id_to: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airplane_name: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airplane_number: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          time: Yup.string().required(t("commons.required")).nullable(),
          date: Yup.string().required(t("commons.required")).nullable(),
          // product_id: Yup.string().required(t("commons.required")).nullable(),
        });
      }
      if (value.flight_type === 0) {
        return Yup.object().shape({
          corporate_id: Yup.string().required(t("commons.required")).nullable(),
          airport_id_from: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airport_id_to: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airplane_name: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airplane_number: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          time: Yup.string().required(t("commons.required")).nullable(),
          date: Yup.string().required(t("commons.required")).nullable(),
          // product_id: Yup.string().required(t("commons.required")).nullable(),
        });
      }
      if (value.flight_type === 1) {
        return Yup.object().shape({
          corporate_id: Yup.string().required(t("commons.required")).nullable(),
          airport_id_from: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airport_id_to: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airplane_name: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airplane_number: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          time: Yup.string().required(t("commons.required")).nullable(),
          date: Yup.string().required(t("commons.required")).nullable(),
          // product_id: Yup.string().required(t("commons.required")).nullable(),
        });
      }
      if (value.flight_type === 2) {
        return Yup.object().shape({
          corporate_id: Yup.string().required(t("commons.required")).nullable(),
          airport_id_from: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airport_id_to: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airport_id_transfer: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airplane_name: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airplane_name_transfer_to: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airplane_number: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          airplane_number_transfer_to: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          time: Yup.string().required(t("commons.required")).nullable(),
          time_transfer_to: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          date: Yup.string().required(t("commons.required")).nullable(),
          date_transfer_to: Yup.string()
            .required(t("commons.required"))
            .nullable(),
        });
      }
      return Yup.mixed().notRequired();
    }),
    "passenger-reservation": Yup.object().shape({
      // price_list: Yup.string().when("corporateType", {
      //   is: "Group",
      //   then: (d) => d.required(t("commons.required")).nullable(),
      // }),
      corporate_type_group_id: Yup.string().when("corporateType", {
        is: "Group",
        then: (d) => d.required(t("commons.required")).nullable(),
      }),
    }),
    "adt-service-reservation": Yup.lazy((value) => {
      if (value.transportation !== undefined) {
        return Yup.object().shape({
          transportation: Yup.string()
            .required(t("commons.required"))
            .nullable(),
          car_type: Yup.string().when(["transportation"], {
            is: (transportation) =>
              transportation !== null && transportation !== "",
            then: (d) => d.required(t("commons.required")).nullable(),
          }),
        });
      }
      return Yup.mixed().notRequired();
    }),
  };

  const initialValues = {
    airport_list: [],
    airport_list_indo: [],
    airplane_name: "",
    airplane_number: "",
    airplane_name_transfer_to: "",
    airplane_number_transfer_to: "",
    airport_id_from: "",
    airport_id_to: "",
    airport_id_transfer: null,
    assistant_id: null,
    available_service: "",
    corporate_id:
      position === "Corporate" ? currentUser?.user?.corporate_id : "",
    corporate_type_id: "",
    customer_id: "",
    car_type: "",
    date: "",
    date_transfer_to: "",
    dataPrice: 0,
    dataPriceMOB: 0,
    dataPriceMOBIndividual: 0,
    dataPriceFIT: 0,
    dataPriceGroup: [],
    flight_type: -1,
    isOverMOB: false,
    lounge: "",
    lounge_adult_number: 0,
    lounge_child_number: 0,
    lounge_infant_number: 0,
    total_lounge_adult: 0,
    total_lounge_child: 0,
    total_lounge_infant: 0,
    total_lounges: 0,
    list_details: [],
    orderExtras: {
      lounge: { name: "", price: "", id: "" },
      transport: { name: "", price: "", id: "" },
      car: { name: "", price: "", id: "" },
    },
    order_extra_list: [],
    passenger_list: [],
    passenger_notes: "",
    price_list: "",
    product_id: "",
    product_type: 0,
    promo_id: null,
    time: "",
    time_transfer_to: "",
    transportation: "",
    region: "",
    type: "",
    groupPrice: 0,
    grandTotalPrice: 0,
    // loungePrice: 0,
    transportPrice: 0,
    selectedAirportArrival: {},
    selectedAirportDeparture: {},
    selectedAirline: {},
    selectedAirlineTransfer: {},
    imagePagingInfo: {
      title: t("field.uploadPagingInfo"),
      subtitle: "File",
    },
    additional_service_description: "",
    additional_service_concession_percentage: null,
    additional_service_ppn_percentage: null,
    additional_service_price: null,
    additional_service_dpp: null,
    additional_service_is_include_concession: null,
    additional_service_is_include_ppn: null
  };

  const button = (param = null, data = null) => {
    if (data) {
      setDataFormik(data);
    }
    if (param) setCorporateType(param);
    return (
      <div style={{ float: "right" }}>
        {next && (
          <ButtonModal
            cancelTitle={back ? t("commons.back") : t("commons.cancel")}
            confirmTitle={t("commons.next")}
            typeConfirm={"Button"}
            toggle={
              back ? () => handleChangeForm(back) : () => showCancelModal()
            }
            confirm={next ? () => handleNextClick(next) : null}
          />
        )}
        {next === null && (
          formikRef?.current?.values?.corporateType !== "MOB" && (
            formikRef?.current?.values?.total_service_price === 0 ||
            (formikRef?.current?.values?.lounge !== "" &&
              formikRef?.current?.values?.total_lounge_price === 0) ||
            (formikRef?.current?.values?.car_type !== "" &&
              formikRef?.current?.values?.total_transport_price === 0)
          ) ? (
            <ButtonModal
              cancelTitle={back ? t("commons.back") : t("commons.cancel")}
              confirmTitle={t("commons.save")}
              typeConfirm={"Button"}
              toggle={back ? () => handleChangeForm(back) : ""}
              confirm={() => showConfirmModal()}
            />
          ) : (
            <ButtonModal
              cancelTitle={back ? t("commons.back") : t("commons.cancel")}
              confirmTitle={t("commons.save")}
              typeConfirm={"Submit"}
              toggle={back ? () => handleChangeForm(back) : ""}
              disabledConfirm={loading}
            />
          )
        )}
      </div>
    );
  };

  const handleOrder = () => {
    let isNotValid = false;

    if (dataFormik?.passenger_list?.length < 1) {
      isNotValid = true;
      toastError(
        `${t("commons.empty_data_message")} ${t(
          "field.passenger"
        )}!`
      );
      return;
    }
    if (dataFormik?.date <= moment().format("YYYY-MM-DD")) {
      if (dataFormik.assistant_list === undefined
        || dataFormik.assistant_list === null) {
        isNotValid = true;
        toastError(
          `${t("commons.empty_data_message")} ${t(
            "field.assistant"
          )}!`
        );
        return;
      }
    }

    let formData = new FormData();
    formData.append("type", dataFormik?.flight_type + 1);
    formData.append("corporate_id", dataFormik?.corporate_id);
    formData.append("corporate_price_id", dataFormik?.corporate_price_id);
    formData.append("corporate_type_id", dataFormik?.corporate_type_id);
    formData.append("corporate_type_group_id", dataFormik?.corporate_type_group_id);
    formData.append("corporate_type_group_property_id", dataFormik?.corporate_type_group_property_id);
    formData.append("product_list_id", dataFormik?.product_list_id);
    formData.append("price_period_id", dataFormik?.price_period_id);
    formData.append("airplane_name", dataFormik?.airplane_name);
    formData.append("airplane_number", dataFormik?.airplane_number);
    formData.append("date", moment(dataFormik?.date)?.format("YYYY-MM-DD"));
    formData.append("time", dataFormik?.time);
    if(dataFormik?.airplane_name_transfer_to) {
      formData.append("airplane_name_transfer_to", dataFormik?.airplane_name_transfer_to);
    }
    if(dataFormik?.airplane_number_transfer_to) {
      formData.append("airplane_number_transfer_to", dataFormik?.airplane_number_transfer_to);
    }
    if(dataFormik?.airport_id_from !== "" && dataFormik?.airport_id_from !== null) {
      formData.append("airport_id_from", dataFormik?.airport_id_from);
    }
    if(dataFormik?.airport_id_to !== "" && dataFormik?.airport_id_to !== null) {
      formData.append("airport_id_to", dataFormik?.airport_id_to);
    }
    if(dataFormik?.airport_id_transfer !== "" && dataFormik?.airport_id_transfer !== null) {
      formData.append("airport_id_transfer", dataFormik?.airport_id_transfer);
    }
    if(dataFormik?.date_transfer_to !== "" && dataFormik?.date_transfer_to !== null) {
      formData.append("date_transfer_to", moment(dataFormik?.date_transfer_to)?.format("YYYY-MM-DD"));
    }
    if(dataFormik?.time_transfer_to !== "" && dataFormik?.time_transfer_to !== null) {
      formData.append("time_transfer_to", dataFormik?.time_transfer_to);
    }
    dataFormik?.passenger_list.forEach((key, x) => {
      formData.append(`passenger_list[${[x]}].email`, key.email ? key.email : "");
      formData.append(`passenger_list[${[x]}].name`, key.name ? key.name : "");
      if (
        key.phoneNumber !== "" &&
        key.phoneNumber !== undefined &&
        key.phoneNumber !== null &&
        !key?.phoneNumber.includes("undefined") &&
        key?.phoneNumber !== key?.countryCode
      ) {
        const phone = key?.phoneNumber?.replace(key?.countryCode, "");
        formData.append(
          `passenger_list[${[x]}].phoneNumber`,
          phone.charAt(0) == 0
            ? phone?.substring(1)
            : phone
        );
        formData.append(
          `passenger_list[${[x]}].countryCode`,
          key.countryCode ? `+${key.countryCode}` : ""
        );
        formData.append(
          `passenger_list[${[x]}].countryId`,
          key.countryId ? key.countryId : ""
        );
      }
      formData.append(`passenger_list[${[x]}].title`, key.title ? key.title : "");
      formData.append(`passenger_list[${[x]}].passenger_nationality`, key.passenger_nationality ? key.passenger_nationality : "");
      formData.append(`passenger_list[${[x]}].passenger_date_of_birth`, key.passenger_date_of_birth ? key.passenger_date_of_birth : "");
    })
    formData.append("bookers_title", dataFormik?.bookers_title);
    formData.append("bookers_name", dataFormik?.bookers_name);
    formData.append("bookers_nationality", dataFormik?.bookers_nationality);
    formData.append("bookers_email", dataFormik?.bookers_email);
    if (
      dataFormik?.bookers_phone !== "" &&
      dataFormik?.bookers_phone !== undefined &&
      dataFormik?.bookers_phone !== null &&
      !dataFormik?.bookers_phone.includes("undefined") &&
      dataFormik?.bookers_phone !== dataFormik?.bookers_country_code
    ) {
      const bookerPhone = dataFormik?.bookers_phone?.replace(dataFormik?.bookers_country_code, "");
      formData.append(
        'bookers_phone',
        bookerPhone.charAt(0) == 0
          ? bookerPhone?.substring(1)
          : bookerPhone
      );
      formData.append(
        `bookers_country_code`,
        `+${dataFormik?.bookers_country_code}`
      );
      formData.append(
        `bookers_country_id`,
        dataFormik?.bookers_country_id
      );
    }
    if(dataFormik?.passenger_notes !== "") {
      formData.append("passenger_notes", dataFormik?.passenger_notes);
    }
    if(dataFormik?.paging_name !== "" && dataFormik?.paging_name !== undefined) {
      formData.append("paging_name", dataFormik?.paging_name);
    }
    if(dataFormik?.paging_logo !== null && dataFormik?.paging_logo !== undefined) {
      formData.append("paging_logo", dataFormik?.paging_logo);
    }
    if(dataFormik?.include_logo_joumpa == 1) {
      formData.append("show_joumpa_logo", true);
    }

    if(dataFormik?.assistant_list !== null && dataFormik?.assistant_list !== undefined) {
      dataFormik.assistant_list.forEach((key, x) => {
        formData.append(`assistant_list[${[x]}].assistant_id`, key.assistant_id ? key.assistant_id : "");
        formData.append(`assistant_list[${[x]}].is_main`, key.is_main ? key.is_main : "");
      })
    }

    if (dataFormik?.corporate_lounge_id !== '' && dataFormik?.corporate_lounge_id !== undefined) {
      formData.append("corporate_lounge_id", dataFormik?.corporate_lounge_id);
    }
    if (dataFormik?.lounge_id !== "" && dataFormik?.lounge_id !== undefined) {
      formData.append("lounge_id", dataFormik?.lounge_id);
    }

    if (dataFormik?.lounge_adult_number === "") {
      formData.append("lounge_adult_number", 0);
    } else {
      formData.append("lounge_adult_number", dataFormik?.lounge_adult_number);
    }

    if (dataFormik?.lounge_child_number === "") {
      formData.append("lounge_child_number", 0);
    } else {
      formData.append("lounge_child_number", dataFormik?.lounge_child_number);
    }

    if (dataFormik?.lounge_infant_number === "") {
      formData.append("lounge_infant_number", 0);
    } else {
      formData.append("lounge_infant_number", dataFormik?.lounge_infant_number);
    }

    if (dataFormik?.region !== "") {
      formData.append("region", dataFormik?.region);
    }
    if (dataFormik?.total_unit !== "" && dataFormik?.total_unit !== undefined) {
      formData.append("transport_number", dataFormik?.total_unit);
    }
    if (dataFormik?.orderExtras?.car?.id) {
      formData.append("transport_price_id", dataFormik?.orderExtras?.car?.id);
    }
    if(dataFormik?.promo_id !== null) {
      formData.append("promo_id", dataFormik?.promo_id);
    }
    if(dataFormik?.additional_service_description !== "") {
      formData.append("additional_service_description", dataFormik?.additional_service_description);
    }
    if(dataFormik?.additional_service_concession_percentage !== null) {
      formData.append("additional_service_concession_percentage", parseFloat(dataFormik?.additional_service_concession_percentage));
    }
    if(dataFormik?.additional_service_is_include_concession !== undefined) {
      formData.append(
        "additional_service_is_include_concession", 
        dataFormik?.additional_service_is_include_concession == 1 ? true : false
      );
    }
    if(dataFormik?.additional_service_is_include_ppn !== undefined) {
      formData.append(
        "additional_service_is_include_ppn", 
        dataFormik?.additional_service_is_include_ppn == 1 ? true : false
      );
    }
    if(dataFormik?.additional_service_ppn_percentage !== null) {
      formData.append("additional_service_ppn_percentage", parseFloat(dataFormik?.additional_service_ppn_percentage));
    }
    if(dataFormik?.additional_service_price !== null && dataFormik?.additional_service_price !== 0) {
      formData.append("additional_service_price", dataFormik?.additional_service_price);
    }
    if(dataFormik?.additional_service_dpp !== null && dataFormik?.additional_service_dpp !== 0) {
      formData.append("additional_service_dpp", dataFormik?.additional_service_dpp);
    }

    if (!isNotValid) {
      new Promise((resolve) => {
        setLoading(true);
        const callback = () => {
          history.push("/corp_reservation");
        };
        dispatch(Corp_Reservation.post(formData, resolve, callback));
      }).then((res) => {
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    if (error_message) {
      setLoading(false);
    }
  }, [error_message]);

  return (
    <div className="menu-container">
      {pending && <Loader loading={pending} />}
      <div className="grid-header">
        <div className="side-menu-no-border">
          <div className="treatment-title menu-title text-extra-bold">
            <div className="treatment-title-icon ">
              {t("field.newReservation")}
            </div>
          </div>
          <SideMenu title={t("field.flight")} checked={sequence > 1} checkbox />
          <SideMenu
            title={t("field.passenger")}
            checked={sequence > 2}
            checkbox
          />
          <SideMenu
            title={t("field.additionalService")}
            checked={sequence > 3}
            checkbox
          />
          <SideMenu title="Check Out" checked={false} checkbox />
        </div>
        <div className="side-menu-detail">
          <Container>
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              // validationSchema={validation[show]}
              onSubmit={(values) => {
                if (next === null) {
                  handleOrder();
                }
                // setStatus();
                // await delay(3000);
                // setSubmitting(false);
              }}
            >
              {(props) => (
                <Form>
                  {show === "flight-reservation" && (
                    <>
                      <HeaderMenu
                        children={
                          <>
                            <Button
                              variant="secondary"
                              rounded
                              leftIcon="close"
                              title={t("commons.cancel")}
                              onClick={() => showCancelModal()}
                            />
                          </>
                        }
                      />
                      <FlightReservation
                        formik={props}
                        button={button}
                      />
                    </>
                  )}
                  {show === "passenger-reservation" && (
                    <>
                      <HeaderMenu
                        children={
                          <>
                            <Button
                              variant="secondary"
                              rounded
                              leftIcon="close"
                              title="Batal"
                              onClick={() => showCancelModal()}
                            />
                          </>
                        }
                      />
                      <PassengerReservation
                        formik={props}
                        idCorporate={corporateType}
                        setErrorAvailabilityPrice={(value) => setErrorAvailabilityPrice(value)}
                        button={button}
                      />
                    </>
                  )}
                  {show === "adt-service-reservation" && (
                    <>
                      <HeaderMenu
                        children={
                          <>
                            <Button
                              variant="secondary"
                              rounded
                              leftIcon="close"
                              title="Batal"
                              onClick={() => showCancelModal()}
                            />
                          </>
                        }
                      />
                      <AdtServiceReservation formik={props} button={button} />
                    </>
                  )}
                  {show === "checkout" && (
                    <>
                      <HeaderMenu
                        children={
                          <>
                            <Button
                              variant="secondary"
                              rounded
                              leftIcon="close"
                              title="Batal"
                              onClick={() => showCancelModal()}
                            />
                          </>
                        }
                      />
                      <Checkout formik={props} tab={sequence} button={button} />
                    </>
                  )}
                </Form>
              )}
            </Formik>
            <ConfirmationModal
              show={cancelModal}
              toggle={showCancelModal}
              confirm={handleCancel}
              icon="info"
              isDelete
              isCancel={t("commons.back")}
              isConfirm={t("commons.yesCancel")}
            >
              <Col md={8}>
                <div className="text-normal">
                  {t("commons.areYouSureCancel")}{" "}
                  {t("commons.add").toLowerCase()}
                  <strong> {t("field.reservation")}</strong> ?
                </div>
              </Col>
            </ConfirmationModal>
            <OrderConfirmationModal
              show={confirmModal}
              toggle={showConfirmModal}
              confirm={handleOrder}
              icon="info"
              message={t("commons.confirmOrder")}
              disabledConfirm={loading}
            />
          </Container>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ corp_reserv: { pending, data, error_message }, corporate }) => {
  return { pending, data, error_message,  corporate };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(Index));

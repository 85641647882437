import React from "react";
import { Formik, Form } from "formik";
import { withTrans } from "../../i18n/withTrans";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { connect, useDispatch } from "react-redux";
import Select2 from "../../components/forms/Select2";
import ButtonModal from "../../components/modal/ButtonModal";
import DynamicButton from "../../components/forms/DynamicButton";
import DatePicker from "../../components/forms/DateRangePicker";

function ModalFilter({
    t,
    show,
    toggle,
    filter,
    pending,
    isDelete,
    onApplyFilter,
}) {
    const handleReset = (formik) => {
        formik.setFieldValue("start_date", "");
        formik.setFieldValue("end_date", "");
        formik.setFieldValue("type", null);
        onApplyFilter({ start_date: "", end_date: "", type: null });
        toggle();
    };

    return (
        <Modal isOpen={show} modalTransition={{ timeout: 200 }}>
            <ModalHeader className="text-extra-bold pt-5">
                {" "}
                Filter{" "}
                <span className="close clickable" onClick={toggle}>
                    &times;
                </span>
            </ModalHeader>
            <ModalBody style={{ margin: "0px 20px" }}>
                <DynamicButton titleLeft={t("field.chooseFilter")} iconLeft="info" />
                <Formik
                    initialValues={{
                        start_date: filter?.start_date ?? "",
                        end_date: filter?.end_date ?? "",
                        type: filter?.type ?? "",
                    }}
                    onSubmit={(values) => {
                        onApplyFilter({
                            start_date: values.start_date,
                            end_date: values.end_date,
                            type: values.type,
                        });
                        toggle();
                    }}
                >
                    {(props) => (
                        <Form style={{ marginTop: 30 }}>
                            <DatePicker
                            title={t("field.date")}
                            name="start_date"
                            handlechange={(startDate, endDate) => {
                                props.setFieldValue("start_date", startDate);
                                props.setFieldValue("end_date", endDate);
                            }}
                            format="DD MMMM YYYY"
                            startDate={filter ? filter?.start_date : props.values?.start_date}
                            endDate={filter ? filter?.end_date : props.values?.end_date}
                            className="mx-auto"
                            errors={props.errors}
                            touched={props.touched}
                            />
                            <Select2
                                clearable="true"
                                title={`${t('commons.type')}`}
                                name="type"
                                options={[
                                    {
                                        value: 'Individual',
                                        label: 'Individual',
                                    },
                                    {
                                        value: 'Group',
                                        label: 'Group (Charter, Hajj, Umrah)',
                                    },
                                    {
                                        value: 'Other',
                                        label: `Group ${t('masterData.others')}`,
                                    },
                                    {
                                        value: 'Lounge',
                                        label: `${t('field.reservation')} Lounge`,
                                    },
                                ]}
                                errors={props.errors}
                                touched={props.touched}
                                value={props.values?.type}
                                placeholder={t('commons.type')}
                                onChange={(name, value) => {
                                    props.setFieldValue("type", value);
                                }}
                                onBlur={props.setFieldTouched}
                            />
                            <div style={{ marginTop: 50, marginBottom: 30 }}>
                                <ButtonModal
                                    toggle={toggle}
                                    reset={() => handleReset(props)}
                                    deleted={isDelete}
                                    confirmTitle={t("field.apply")}
                                    disabledConfirm={pending}
                                />
                            </div>

                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    )
}

const mapStateToProps = ({ masterDataRegion }) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default withTrans(
    connect(mapStateToProps, mapDispatchToProps)(ModalFilter)
);
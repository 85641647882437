import { useState, useRef, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import { Container, Col } from "reactstrap";

import Button from "../../../components/buttons/Button";
import Loader from "../../../components/commons/Loader";
import ButtonModal from "../../../components/modal/ButtonModal";
import SideMenu from "../../../components/commons/menu/SideMenu";
import HeaderMenu from "../../../components/commons/menu/HeaderMenu";
import ConfirmationModal from "../../../components/modal/ConfirmModal";
import Checkout from "./Checkout";
import FlightReservation from "./FlightReservation";
import PassengerReservation from "./PassengerReservation";
import AdtServiceReservation from "./AdtServiceReservation";
import { history } from "../../../utils/History";
import { withTrans } from "../../../i18n/withTrans";
import { toastError } from "../../../components/commons/toast";
import { getErrorMessage, moreThanInfantAge } from "../../../utils/Helper";
import { EMAIL_FORMAT_REGEX, EMAIL_WHITESPACE_REGEX, NAME_FORMAT_REGEX, payment_gateway, Role, titlePassenger } from "../../../utils/Constants";
import { getUser } from "../../../utils/User";

//Service
import ReservationLounge from "../../../store/actions/reservation_lounge";

const Index = ({ match, pending, t, error_message }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const currentUser = getUser();
  const lang = localStorage.getItem('joumpa_language');

  const [show, setShow] = useState("flight-reservation");
  const [next, setNext] = useState("passenger-reservation");
  const [back, setBack] = useState(null);
  const [sequence, setSequence] = useState(1);
  const [cancelModal, setCancelModal] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const showCancelModal = () => {
    setCancelModal(!cancelModal);
  };

  const handleCancel = () => {
    history.push("/lounge-reservation");
  };

  const handleChangeForm = (tab) => {
    document.documentElement.scrollTop = 0;
    switch (tab) {
      case "flight-reservation":
        setShow("flight-reservation");
        setBack(null);
        setNext("passenger-reservation");
        setSequence(1);
        break;
      case "passenger-reservation":
        setShow("passenger-reservation");
        setBack("flight-reservation");
        setNext("adt-service-reservation");
        setSequence(2);
        break;
      case "adt-service-reservation":
        setShow("adt-service-reservation");
        setBack("passenger-reservation");
        setNext("checkout");
        setSequence(3);
        break;
      case "checkout":
        setShow("checkout");
        setBack("adt-service-reservation");
        setNext(null);
        setSequence(4);
        break;
    }
  };

  const handleNextClick = (tab) => {
    let values = formikRef?.current?.values;

    const showEmptyError = (message) => {
      toastError(`${t("commons.empty_data_message")} ${message}`);
    };

    const checkEmpty = (field, fieldName, customMsg) => {
      if (values[field] === undefined || values[field] === null || values[field] === '') {
        showEmptyError(`${customMsg ?? t("field." + fieldName)}!`);
        return true;
      }
      return false;
    };

    const checkedFlightTabValidity = () => {
      if (
        checkEmpty('flight_type', 'flightType') ||
        checkEmpty('airport_id_from', 'origin') ||
        checkEmpty('airport_id_to', 'destination') ||
        checkEmpty('airplane_name', 'airline') ||
        checkEmpty('airplane_number', 'flightNumber') ||
        checkEmpty('date', 'date') ||
        checkEmpty('time', null, `${t("commons.time")}!`) ||
        checkEmpty('lounge', 'lounge')
      ) {
        return false;
      }

      return true;
    }

    const checkPassengerValidity = (passenger) => {
      if (!passenger.name || !passenger.passenger_nationality || !passenger.title) {
        showEmptyError(`${t("field.passenger")}!`);
        return true;
      }
      if (passenger.title !== "Infant" && passenger.title !== "Child") {
        if (!passenger.email || !passenger.phoneNumber) {
          showEmptyError(`${t("field.passenger")}!`);
          return true;
        }
      }
      if (passenger.title === "Infant" && !passenger.passenger_date_of_birth){
        toastError(`${t("commons.complete_birth_date_message")}!`);
        return true;
      }
      if (
        passenger.title === "Infant" &&
        passenger.passenger_date_of_birth &&
        moreThanInfantAge(passenger?.passenger_date_of_birth)
      ){
        toastError(`${t("commons.birth_date_less_than_message")}!`);
        return true;
      }
      if (passenger.title && !titlePassenger.includes(passenger.title)) {
        toastError(`${t("commons.invalidTitlePassenger")}!`);
        return true;
      }
      if (
        passenger?.name &&
        !NAME_FORMAT_REGEX.test(passenger?.name)
      ) {
        toastError(
          `${t("field.passengerName")} ${t("commons.wrong_name_format")}!`
        );
        return true;
      }
      if (
        passenger.email &&
        !EMAIL_FORMAT_REGEX.test( passenger.email)
      ) {
        toastError(`${t("commons.wrong_email_format")} ${t("field.passenger")}!`);
        return true;
      }

      return false;
    };

    const checkedPassengerTabValidity = () => {
      if (!values?.passenger_list.length) {
        showEmptyError(`${t("field.passenger")}!`);
        return false;
      }

      if (
        checkEmpty('bookers_title', 'bookersInfo') ||
        checkEmpty('bookers_name', 'bookersInfo') ||
        checkEmpty('bookers_nationality', 'bookersInfo') ||
        checkEmpty('bookers_email', 'bookersInfo')
      ) {
        return false;
      }

      if (
        values?.bookers_name &&
        !(NAME_FORMAT_REGEX.test(values?.bookers_name))
      ) {
        toastError(
          `${t("field.booker")} ${t("commons.wrong_name_format")}!`
        );
        return false;
      }

      if (
        values?.bookers_email &&
        EMAIL_WHITESPACE_REGEX.test(values?.bookers_email)
      ) {
        values.bookers_email = values?.bookers_email.replace(/\s+/g, "")
      }

      if (
        values?.bookers_email &&
        !EMAIL_FORMAT_REGEX.test(values?.bookers_email)
      ) {
        toastError(`${t("commons.wrong_email_format")} ${t("field.booker")}!`);
        return false;
      }

      if (
        values?.bookers_phone === "" ||
        values?.bookers_phone?.includes("undefined") ||
        values?.bookers_phone === values?.bookers_country_code
      ) {
        showEmptyError(`${t("field.bookersInfo")}!`);
        return false;
      }

      let passengerNotValid = false;
      values?.passenger_list?.forEach((passenger) => {
        if (
          passenger.email &&
          EMAIL_WHITESPACE_REGEX.test(passenger.email)
        ) {
          passenger.email = passenger.email.replace(/\s+/g, "")
        }
        if (checkPassengerValidity(passenger)) {
          passengerNotValid = true;
        }
      });

      if (passengerNotValid) return false;

      return true;
    }

    const checkedAdtServiceTabValidity = () => {
      if (values?.region) {
        if (!values?.car_type || !values?.total_unit) {
          showEmptyError(`${t("field.transportation")}!`);
          return false;
        }
      } else {
        formikRef?.current?.setFieldValue("total_unit", 0);
      }

      return true;
    }

    if (tab === "passenger-reservation") {
      if (checkedFlightTabValidity()) {
        handleChangeForm(tab);
      }
    } else if (tab === "adt-service-reservation") {
      if (checkedFlightTabValidity() && checkedPassengerTabValidity()) {
        handleChangeForm(tab);
      }
    } else if (tab === "checkout") {
      if (checkedFlightTabValidity() && checkedPassengerTabValidity() && checkedAdtServiceTabValidity()) {
        handleChangeForm(tab);
      }
      
      formikRef.current.setFieldValue("lounge_promo_id", null);
      formikRef.current.setFieldValue("applied_promo_lounge", null);
      formikRef.current.setFieldValue("joumpa_promo_id", null);
      formikRef.current.setFieldValue("applied_promo_joumpa", null);
    }
  };

  const handleChangeTab = (tab, number) => {
    if (number > sequence) {
      handleNextClick(tab);
    } else {
      handleChangeForm(tab);
    }
  };

  const initialValues = {
    id: "",
    car_type: "",
    airplane_name: "",
    airplane_number: "",
    airport_id_from: "",
    airport_id_to: "",
    available_service: "",
    customer_id: "",
    date: "",
    payment_type: "",
    file: null,
    dataPrice: 0,
    dataPriceGroup: [],
    flight_type: 0,
    lounge: "",
    orderExtras: {
      lounge: { name: "", price: "", id: "" },
      transport: { name: "", price: "", id: "" },
      car: { name: "", price: "", id: "" },
    },
    order_extra_list: [],
    passenger_list: [],
    passenger_notes: "",
    product_id: null,
    product_list_id: null,
    product_type: 0,
    lounge_promo_id: null,
    joumpa_promo_id: null,
    time: "",
    transportation: "",
    region: "",
    type: null,
    groupPrice: 0,
    grandTotalPrice: 0,
    transportPrice: 0,
    isPriceNotSet: true,
    midtrans_token_id: null,
    isGoShow: false,
    passenger_as_booker: null,
    bookers_title: "",
    bookers_name: "",
    bookers_nationality: "",
    bookers_email: "",
    bookers_phone: "",
    selectedAirport: {},
    selectedAirportDomestic: {},
    selectedAirline: {},
  };

  const handleSubmit = (values) => {
    let isAdminPusat = currentUser.user.role_code === Role.Central_admin;
    let isNotValid = false;
    let formData = new FormData();

    values.type = values.flight_type;
    values.customer_id = null;

    const showError = (message) => {
      toastError(`${t("commons.empty_data_message")} ${message}`);
    };

    if (isAdminPusat) {
      if (values?.payment_type && !values?.file) {
        showError(`${t("booking.paymentProof")}!`);
        isNotValid = true;
        return;
      }
  
      if (!values?.payment_type && values?.file) {
        showError(`${t("booking.paymentType")}!`);
        isNotValid = true;
        return;
      }
    }

    const appendFormData = (key, value) => {
      if (value !== null && value !== undefined && value !== "") {
        formData.append(key, value);
      }
    };

    appendFormData("add_on_joumpa", values?.product_type ? true : false);
    appendFormData("airplane_name", values.airplane_name);
    appendFormData("airplane_number", values.airplane_number);
    appendFormData("airport_id_from", values.airport_id_from);
    appendFormData("airport_id_to", values.airport_id_to);
    appendFormData("bookers_title", values.bookers_title);
    appendFormData("bookers_name", values.bookers_name);
    appendFormData("bookers_nationality", values.bookers_nationality);
    appendFormData("bookers_email", values.bookers_email);

    if (
      values.bookers_phone &&
      !values.bookers_phone.includes("undefined") &&
      values.bookers_phone !== values.bookers_country_code
    ) {
      const formattedBookerPhone =
        values.bookers_phone.replace(values.bookers_country_code, "");
      const bookerPhone = formattedBookerPhone.charAt(0) == 0
        ? formattedBookerPhone?.substring(1)
        : formattedBookerPhone;

      appendFormData("bookers_phone", bookerPhone);
      appendFormData(`bookers_country_code`, `+${values.bookers_country_code}`);
      appendFormData(`bookers_country_id`, values.bookers_country_id);
    }

    appendFormData("customer_id", values.customer_id);
    appendFormData("date", values.date);
    appendFormData("file", values.file);
    appendFormData("lounge_id", values.lounge);
    appendFormData("lounge_promo_id", values.lounge_promo_id);

    values.passenger_list.forEach((key, x) => {
      appendFormData(`passenger_list[${x}].email`, key.email);
      appendFormData(`passenger_list[${x}].name`, key.name);

      if (
        key.phoneNumber &&
        !key.phoneNumber.includes("undefined") &&
        key.phoneNumber !== key.countryCode
      ) {
        const formattedPhone = key?.phoneNumber?.replace(key?.countryCode, "");
        const phone = formattedPhone.charAt(0) == 0
          ? formattedPhone?.substring(1)
          : formattedPhone;

        appendFormData(`passenger_list[${x}].phoneNumber`, phone);
        appendFormData(
          `passenger_list[${x}].countryCode`,
          `+${key.countryCode}`
        );
        appendFormData(`passenger_list[${x}].countryId`, key.countryId);
      }

      appendFormData(`passenger_list[${x}].title`, key.title);
      appendFormData(
        `passenger_list[${x}].passenger_nationality`,
        key.passenger_nationality
      );
      appendFormData(
        `passenger_list[${x}].passenger_date_of_birth`,
        key.passenger_date_of_birth
      );
    });

    appendFormData("passenger_notes", values.passenger_notes);
    appendFormData("payment_type", isAdminPusat ? values.payment_type : payment_gateway);
    appendFormData("period_id", values.period_id);
    appendFormData("price_id", values.price_id);
    appendFormData("group_price_id", values.group_price_id);
    appendFormData("product_list_id", values.product_list_id);
    appendFormData("product_name", values.product_type);
    appendFormData("joumpa_promo_id", values.joumpa_promo_id);
    appendFormData("region", values.region);
    appendFormData("type", values.flight_type + 1);
    appendFormData("time", values.time);
    appendFormData("transport_number", values.total_unit);
    appendFormData("transport_price_id", values.orderExtras.car?.id);

    if (isNotValid) {
      return;
    }

    new Promise((resolve, reject) => {
      setLoadingSubmit(true);
      dispatch(ReservationLounge.post(formData, resolve, reject));
    }).then((res) => {
      if (!isAdminPusat && res.data && res.data.redirect_payment_url) {
        window.open(
          `${res.data.redirect_payment_url}`,
          `_blank`
        );
      }
      history.push("/lounge-reservation");
      setLoadingSubmit(false);
    })
    .catch((err) => {
      toastError(
        err?.data
          ? getErrorMessage(lang === 'en-US' ? 'en' : lang, err?.data)
          : err?.message
      );
      setLoadingSubmit(false);
    });
  };

  useEffect(() => {
    if (error_message) {
      setLoadingSubmit(false);
    }
  }, [error_message]);

  const button = (param = null, data = null) => {
    return (
      <div className="float-right">
        {next ? (
          <ButtonModal
            cancelTitle={back ? t("commons.back") : t("commons.cancel")}
            confirmTitle={t("commons.next")}
            typeConfirm="Button"
            toggle={
              back ? () => handleChangeForm(back) : () => showCancelModal()
            }
            confirm={next ? () => handleNextClick(next) : null}
          />
        ) : (
          <ButtonModal
            cancelTitle={back ? t("commons.back") : t("commons.cancel")}
            confirmTitle={t("commons.save")}
            typeConfirm="Submit"
            toggle={back ? () => handleChangeForm(back) : ""}
            disabledConfirm={loadingSubmit}
          />
        )}
      </div>
    );
  };

  return (
    <div className="menu-container">
      {pending && <Loader loading={pending} />}
      <div className="grid-header">
        <div className="side-menu-no-border">
          <div className="treatment-title menu-title text-extra-bold">
            <div className="treatment-title-icon ">
              {t("field.newReservation")}
            </div>
          </div>
          <SideMenu
            title={t("field.flight")}
            toggle={() => handleChangeTab("flight-reservation")}
            checked={sequence > 1}
            checkbox
          />
          <SideMenu
            title={t("field.passenger")}
            toggle={() => handleChangeTab("passenger-reservation", 2)}
            checked={sequence > 2}
            checkbox
          />
          <SideMenu
            title={t("field.additionalService")}
            toggle={() => handleChangeTab("adt-service-reservation", 3)}
            checked={sequence > 3}
            checkbox
          />
          <SideMenu
            title="Check Out"
            toggle={() => handleChangeTab("checkout", 4)}
            checked={sequence > 4}
            checkbox
          />
        </div>
        <div className="side-menu-detail">
          <Container>
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              onSubmit={(values) => {
                if (next === null) {
                  handleSubmit(values);
                }
              }}
            >
              {(props) => (
                <Form>
                  {show === "flight-reservation" && (
                    <>
                      <HeaderMenu
                        children={
                          <>
                            <Button
                              variant="secondary"
                              rounded
                              leftIcon="close"
                              title={t("commons.cancel")}
                              onClick={() => showCancelModal()}
                            />
                          </>
                        }
                      />
                      <FlightReservation formik={props} button={button} />
                    </>
                  )}
                  {show === "passenger-reservation" && (
                    <>
                      <HeaderMenu
                        children={
                          <>
                            <Button
                              variant="secondary"
                              rounded
                              leftIcon="close"
                              title={t("commons.cancel")}
                              onClick={() => showCancelModal()}
                            />
                          </>
                        }
                      />
                      <PassengerReservation formik={props} button={button} />
                    </>
                  )}
                  {show === "adt-service-reservation" && (
                    <>
                      <HeaderMenu
                        children={
                          <>
                            <Button
                              variant="secondary"
                              rounded
                              leftIcon="close"
                              title={t("commons.cancel")}
                              onClick={() => showCancelModal()}
                            />
                          </>
                        }
                      />
                      <AdtServiceReservation formik={props} button={button} />
                    </>
                  )}
                  {show === "checkout" && (
                    <>
                      <HeaderMenu
                        children={
                          <>
                            <Button
                              variant="secondary"
                              rounded
                              leftIcon="close"
                              title={t("commons.cancel")}
                              onClick={() => showCancelModal()}
                            />
                          </>
                        }
                      />
                      <Checkout formik={props} button={button} user={currentUser.user} />
                    </>
                  )}
                </Form>
              )}
            </Formik>
            <ConfirmationModal
              show={cancelModal}
              toggle={showCancelModal}
              confirm={handleCancel}
              icon="info"
              isDelete
              isCancel={t("commons.back")}
              isConfirm={t("commons.yesCancel")}
            >
              <Col md={8}>
                <div className="normal-title">
                  {t("commons.areYouSureCancel")} <strong>{t("field.newReservation")}</strong> ?
                </div>
              </Col>
            </ConfirmationModal>
          </Container>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ reservation_lounge: { error_message } }) => {
  return { error_message };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(Index));

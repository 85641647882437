import {
    GET_LOUNGE_PENDING,
    GET_LOUNGE_SUCCESS,
    GET_LOUNGE_ERROR,
    POST_LOUNGE_PENDING,
    POST_LOUNGE_SUCCESS,
    POST_LOUNGE_ERROR,
    PUT_LOUNGE_PENDING,
    PUT_LOUNGE_SUCCESS,
    PUT_LOUNGE_ERROR,
    DELETE_LOUNGE_PENDING,
    DELETE_LOUNGE_SUCCESS,
    DELETE_LOUNGE_ERROR,
} from "../../../actions/master_data/lounge";

const initialState = {
    pending: false,
    error: null,
    data_list: null,
    pending_post: false,
    data_post: null,
    pending_add: false,
    data_add: null,
    pending_put: false,
    data_put: false,
    pending_delete: false,
    data_delete: null
};

const lounge = (state = initialState, action) => {
    switch (action.type) {
        case GET_LOUNGE_PENDING:
            return {
                ...state,
                pending: true,
            };
        case GET_LOUNGE_SUCCESS:
            return {
                ...state,
                pending: false,
                data_list: action.data,
            };
        case GET_LOUNGE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        case POST_LOUNGE_PENDING:
            return {
                ...state,
                pending_post: true,
                error_message: null,
            };
        case POST_LOUNGE_SUCCESS:
            return {
                ...state,
                pending_post: false,
                data_post: action.data,
                error_message: null,
            };
        case POST_LOUNGE_ERROR:
            return {
                ...state,
                pending_post: false,
                error: action.error,
                error_message: action?.error?.data?.message,
            };
        case PUT_LOUNGE_PENDING:
            return {
                ...state,
                pending_put: true,
                error_message: null,
            };
        case PUT_LOUNGE_SUCCESS:
            return {
                ...state,
                pending_put: false,
                data_put: action.data,
                error_message: null,
            };
        case PUT_LOUNGE_ERROR:
            return {
                ...state,
                pending_put: false,
                error: action.error,
                error_message: action?.error?.data?.message,
            };
        case DELETE_LOUNGE_PENDING:
            return {
                ...state,
                pending_delete: true,
                error_message: null,
            };
        case DELETE_LOUNGE_SUCCESS:
            return {
                ...state,
                pending_delete: false,
                data_delete: action.data,
                error_message: null,
            };
        case DELETE_LOUNGE_ERROR:
            return {
                ...state,
                pending_delete: false,
                error: action.error,
                error_message: action?.error?.data?.message,
            };
        default:
            return state;
    }
};

export default lounge;

import { Divider } from "@mui/material";
import ReactTooltip from "react-tooltip";
import "../../cards/card.scss";

const LabelPlus = ({ data, idRow }) => {
  let labelArray = [];

  return (
    <>
      {data?.forEach((item, index) => {
        if (data?.length > 2 && index > 1 && index < data?.length - 1) {
          labelArray.push(
            <div key={index}>
              <div className="my-1">
                <span className="text-center mb-2">{item?.name}</span>
              </div>
              <Divider
                color="white"
                orientation="horizontal"
              />
            </div>
          );
        } else if (data?.length > 2 && index > 1 && index === data?.length - 1) {
          labelArray.push(
            <div className="my-1" key={index}>
              <span className="text-center mb-2">{item?.name}</span>
            </div>
          );
        }
      })}
      <div className="d-flex flex-wrap">
        {data?.map((item, index) =>
          index === 0 ? (
            <label key={index} className="rounded-label mr-2 p-1 px-3">
              {item?.name}
            </label>
          ) : null
        )}
        {data?.length > 1 && (
          <div className="d-flex align-items-center">
            <label className="rounded-label mr-2 p-1 px-3">
              {data[1]?.name}
            </label>
            {data?.length > 2 && (
              <>
                <a
                  className="green-label text-extra-bold"
                  data-tip="React-tooltip"
                  data-for={`tooltip-${idRow}`}
                >
                  + {data?.length - 2}
                </a>
                <ReactTooltip
                  place="bottom"
                  type="dark"
                  effect="float"
                  key={`tooltip-${idRow}`}
                  id={`tooltip-${idRow}`}
                >
                  {labelArray}
                </ReactTooltip>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default LabelPlus;

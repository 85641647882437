import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Modal, ModalHeader, ModalBody, Container, ModalFooter } from "reactstrap";
import { Divider } from "@mui/material";
import moment from "moment";

import { withTrans } from "../../../../i18n/withTrans";
import { numberWithCommas, promoLoungeType } from "../../../../utils/Constants";
import Button from "../../../../components/buttons/Button";
import ButtonModal from "../../../../components/modal/ButtonModal";
import Loader from "../../../../components/commons/Loader";
import SearchButton from "../../../../components/buttons/SearchButton";
import Promo from "../../../../store/actions/promo";
import PromoLounge from "../../../../store/actions/promo_lounge";

const VoucherModal = ({
  formik,
  show,
  toggle,
  cancel,
  t,
  pendingVoucherJoumpa,
  pendingVoucherLounge,
}) => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState();
  const [voucher, setVoucher] = useState([]);
  const [voucherLounge, setVoucherLounge] = useState([]);
  const [appliedVoucher, setAppliedVoucher] = useState(false);
  const [appliedVoucherLounge, setAppliedVoucherLounge] = useState(false);

  const handleSearch = (value) => {
    setSearchText(value.length > 0 ? value : "");
  };

  function differents(item) {
    var start_date = moment()?.format("YYYY-MM-DD");
    var end_date = moment(item?.period_end);
    let period = moment.duration(end_date.diff(start_date)).asDays();
    return period;
  }

  const handleGetVoucherLounge = () => {
    new Promise((resolve) => {
      let param = {
        length: 99999,
        search_text: searchText,
        airport_id: formik?.values?.flight_type === 1
          ? formik?.values?.airport_id_from
          : formik?.values?.airport_id_to,
        valid: true,
        service_date: formik?.values?.date,
        lounge_id: formik?.values?.lounge,
      };
      dispatch(PromoLounge.get(param, resolve));
    }).then((res) => {
      let dataVoucherLounge = res?.data;

      if (formik?.values?.lounge_promo_id) {
        const voucherIncludeSelectedPromo = dataVoucherLounge?.filter(
          (promo) => promo?.id === formik?.values?.lounge_promo_id
        );
        if (!voucherIncludeSelectedPromo?.length)
          dataVoucherLounge?.push(formik?.values?.applied_promo_lounge);
      }

      setVoucherLounge(dataVoucherLounge);
    });
  };

  const handleGetVoucherAssistant = () => {
    new Promise((resolve) => {
      let param = {
        length: 99999,
        search_text: searchText,
        airport_id: formik?.values?.flight_type === 1
          ? formik?.values?.airport_id_from
          : formik?.values?.airport_id_to,
        valid: true,
        service_date: formik?.values?.date,
        product_id: formik?.values?.product_id,
        is_cross_selling: false,
      };
      if (!formik?.values?.product_id) return;
      dispatch(Promo.get(param, resolve));
    }).then((res) => {
      let dataVoucherJoumpa = res?.data?.filter((item) => item?.type !== "cross-selling");

      if (formik?.values?.joumpa_promo_id) {
        const voucherIncludeSelectedPromo = dataVoucherJoumpa?.filter(
          (promo) => promo?.id === formik?.values?.joumpa_promo_id
        );
        if (!voucherIncludeSelectedPromo?.length)
          dataVoucherJoumpa?.push(formik?.values?.applied_promo_joumpa);
      }
  
      setVoucher(dataVoucherJoumpa);
    });
  };

  useEffect(() => {
    handleGetVoucherLounge();
    if (formik.values.product_type && formik?.values?.product_list_id) {
      handleGetVoucherAssistant();
    }
  }, [searchText]);

  useEffect(() => {
    setAppliedVoucherLounge(formik?.values?.applied_promo_lounge);
  }, [formik?.values?.applied_promo_lounge]);

  useEffect(() => {
    setAppliedVoucher(formik?.values?.applied_promo_joumpa);
  }, [formik?.values?.applied_promo_joumpa]);

  const handleAddVoucherJoumpa = (item) => {
    if (!item) return;

    formik?.setFieldValue("applied_promo_joumpa", appliedVoucher);
    formik.setFieldValue("joumpa_promo_id", item?.id);
    formik.setFieldValue("joumpa_promo_name", item?.name);
    formik.setFieldValue("joumpa_promo_type", item?.type);
    formik.setFieldValue("joumpa_promo_percent", item?.discount_percent);
    formik.setFieldValue("joumpa_promo_price", item?.discount_price);
    toggle();

    if (item?.type !== promoLoungeType.Specific) handleRemovePromoSpecialJoumpa();
  };

  const handleAddVoucherLounge = (item) => {
    if (!item) return;

    formik?.setFieldValue("applied_promo_lounge", appliedVoucherLounge);
    formik.setFieldValue("lounge_promo_id", item?.id);
    formik.setFieldValue("lounge_promo_name", item?.name);
    formik.setFieldValue("lounge_promo_type", item?.type);
    formik.setFieldValue("lounge_promo_percent", item?.discount_percent);
    formik.setFieldValue("lounge_promo_price", item?.discount_price);
    toggle();

    if (item?.type !== promoLoungeType.Specific) handleRemovePromoSpecialLounge();
  };

  const handleRemovePromoSpecialJoumpa = () => {
    setVoucher(
      voucher?.filter((item) => item?.type !== promoLoungeType.Specific)
    );
  }

  const handleRemovePromoSpecialLounge = () => {
    setVoucherLounge(
      voucherLounge?.filter((item) => item?.type !== promoLoungeType.Specific)
    );
  }

  const handleCancel  = () => {
    setAppliedVoucherLounge(formik?.values?.applied_promo_lounge);
    setAppliedVoucher(formik?.values?.applied_promo_joumpa);
    toggle();
  }

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {"Voucher"}{" "}
          <span className="close clickable" onClick={handleCancel}>
            &times;
          </span>
        </ModalHeader>
        <ModalBody className="modal-voucher-scrollable">
          <Container>
            <SearchButton
              placeholder={t("commons.enterPromoKeyword")}
              toggle={(value) => handleSearch(value)}
              showStyle={true}
              width={{ width: "400px" }}
            />
            <div className="mt-4">
              {pendingVoucherLounge ? (
                <Loader cardContent loading={pendingVoucherLounge} />
              ) : voucherLounge?.length ? (
                <div className="mb-4">
                  <h5 className="title text-primary">Promo Lounge</h5>
                  {voucherLounge?.map((item, index) => (
                    <div className="my-3">
                      <div className="d-flex justify-content-between">
                        <div className="title-voucher mr-3">
                          <div className="text-extra-bold pb-1">
                            {`${t("commons.discount")} ${
                              item?.discount_price !== 0
                                ? "Rp" +
                                  numberWithCommas(`${item?.discount_price}`)
                                : item.discount_percent + "%"
                            } ${item.name}`}
                          </div>
                          <div className="normal-title">
                            <span className="text-primary">
                              {item?.type === promoLoungeType.Blast
                                ? item?.type_label
                                : t("promo.special")}
                            </span>
                            <span className="small-vertical-divider"></span>
                            <span>
                              {moment(item?.period_end).format("YYYY-MM-DD") !==
                              moment().format("YYYY-MM-DD")
                                ? `${t("field.expired")} ${differents(
                                    item
                                  )} ${t("field.day")}`
                                : `${t("field.expired")} ${t("field.today")}`}
                            </span>
                          </div>
                        </div>
                        <div className="button-voucher">
                          <Button
                            title={
                              appliedVoucherLounge?.id === item?.id
                                ? t("field.applied")
                                : t("field.apply")
                            }
                            rounded={true}
                            variant={
                              appliedVoucherLounge?.id === item?.id
                                ? "secondary"
                                : "primary"
                            }
                            confirmTitle={t("commons.save")}
                            onClick={() => {
                              setAppliedVoucherLounge(
                                appliedVoucherLounge?.id !== item?.id ? item : null
                              );
                            }}
                            icon
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <Divider orientation="horizontal" />
                </div>
              ) : (
                <></>
              )}
              {pendingVoucherJoumpa ? (
                <Loader cardContent loading={pendingVoucherJoumpa} />
              ) : voucher?.length ? (
                <>
                  <h5 className="title text-primary">
                    Promo Joumpa {t("field.assistant")}
                  </h5>
                  {voucher?.map((item, index) => (
                    <div className="my-3">
                      <div className="d-flex justify-content-between">
                        <div className="title-voucher mr-3">
                          <div className="text-extra-bold pb-1">
                            {`${t("commons.discount")} ${
                              item?.discount_price !== 0
                                ? "Rp" +
                                  numberWithCommas(`${item?.discount_price}`)
                                : item.discount_percent + "%"
                            } ${item.name}`}
                          </div>
                          <div className="normal-title">
                            <span className="text-primary">
                              {item?.type === promoLoungeType.Blast
                                ? item?.type_label
                                : t("promo.special")}
                            </span>
                            <span className="small-vertical-divider"></span>
                            <span>
                              {moment(item?.period_end).format("YYYY-MM-DD") !==
                              moment().format("YYYY-MM-DD")
                                ? `${t("field.expired")} ${differents(
                                    item
                                  )} ${t("field.day")}`
                                : `${t("field.expired")} ${t("field.today")}`}
                            </span>
                          </div>
                        </div>
                        <div className="button-voucher">
                          <Button
                            title={
                              appliedVoucher?.id === item?.id
                                ? t("field.applied")
                                : t("field.apply")
                            }
                            rounded={true}
                            variant={
                              appliedVoucher?.id === item?.id
                                ? "secondary"
                                : "primary"
                            }
                            confirmTitle={t("commons.save")}
                            onClick={() => {
                              setAppliedVoucher(
                                appliedVoucher?.id !== item?.id ? item : null
                              );
                            }}
                            icon
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <Divider orientation="horizontal" />
                </>
              ) : (
                <></>
              )}
              {!pendingVoucherLounge && !voucherLounge?.length && !pendingVoucherJoumpa && !voucher?.length ? (
                <div className="text-center">{t("commons.dataNotFound")}</div>
              ) : <></>}
            </div>
          </Container>
        </ModalBody>
        <ModalFooter className="modal-voucher-sticky-footer">
          <div className="d-flex justify-content-end">
            <ButtonModal
              confirmTitle={t("commons.save")}
              confirm={() => {
                handleAddVoucherLounge(appliedVoucherLounge);
                handleAddVoucherJoumpa(appliedVoucher);
                toggle();
              }}
              toggle={handleCancel}
            />
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

const mapStateToProps = ({
  promo: { pending: pendingVoucherJoumpa },
  promo_lounge: { pending_get: pendingVoucherLounge },
}) => {
  return { pendingVoucherJoumpa, pendingVoucherLounge };
};

const mapDispatchToProps = () => {
  return {};
};
export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(VoucherModal)
);

import { actionPending, actionSuccess, actionError } from "../actionTypes";

import { toastSuccess, toastError } from "../../../components/commons/toast";

import API from "../API";
import { handleResponse } from "../HandleRespone";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_BOOKING_PENDING = "GET_BOOKING_PENDING";
export const GET_BOOKING_SUCCESS = "GET_BOOKING_SUCCESS";
export const GET_BOOKING_ERROR = "GET_BOOKING_ERROR";
export const POST_BOOKING_PENDING = "POST_BOOKING_PENDING";
export const POST_BOOKING_SUCCESS = "POST_BOOKING_SUCCESS";
export const POST_BOOKING_ERROR = "POST_BOOKING_ERROR";
export const PUT_BOOKING_PENDING = "PUT_BOOKING_PENDING";
export const PUT_BOOKING_SUCCESS = "PUT_BOOKING_SUCCESS";
export const PUT_BOOKING_ERROR = "PUT_BOOKING_ERROR";
export const DELETE_BOOKING_PENDING = "DELETE_BOOKING_PENDING";
export const DELETE_BOOKING_SUCCESS = "DELETE_BOOKING_SUCCESS";
export const DELETE_BOOKING_ERROR = "DELETE_BOOKING_ERROR";
export const CALCULATE_PRICE_PENDING =
  "CALCULATE_PRICE_PENDING";
export const CALCULATE_PRICE_SUCCESS =
  "CALCULATE_PRICE_SUCCESS";
export const CALCULATE_PRICE_ERROR =
  "CALCULATE_PRICE_ERROR";

// URL: URL_{URL}
const ORDER_URL = `v1/order/lounge/admin`;
const GET_ORDER_URL = `v1/order/lounge`;
const CANCEL_ORDER_URL = `v1/order/lounge/cancel`;
const DELETE_PAYMENT_URL = `v1/order/lounge/payment_proof/delete`;
const UPLOAD_PAYMENT_URL = "v1/order/lounge/payment_proof/upload";
const EDIT_PASSENGER_URL = `v1/order/lounge/passenger/edit`;
const EDIT_BOOKER_URL = 'v1/order/lounge/bookers/edit';
const RESCHEDULE_URL = `v1/order/lounge/schedule/edit`;
const DONE_ORDER_URL = `v1/order/lounge/complete`;

const lang = localStorage.getItem("joumpa_language");

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_BOOKING_PENDING));
  API.get(GET_ORDER_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_BOOKING_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
          passenger_type:
            lang === "id"
              ? item?.passenger_type_label
              : item?.passenger_type_label_eng,
          type_label:
            lang === "id"
              ? item?.type_label
              : item?.type_label_eng,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_BOOKING_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const post = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(POST_BOOKING_PENDING));
  API.post(ORDER_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_BOOKING_SUCCESS, res));
      toastSuccess(
        lang === "id" ? "Berhasil Tambah Reservasi Lounge" : "Successfully Add Reservation Lounge"
      );
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_BOOKING_ERROR, err?.response || err));
      if (reject) {
        reject(err?.response || err);
        return;
      }

      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const calculate_price = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(CALCULATE_PRICE_PENDING));
  API.post(ORDER_URL + "/calculate-price", param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(CALCULATE_PRICE_SUCCESS, res));

      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(CALCULATE_PRICE_ERROR));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const del = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_BOOKING_PENDING));
  API.post(CANCEL_ORDER_URL + `?cancel_reason=${param.cancel_reason}&order_id=${param.order_id}`)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_BOOKING_SUCCESS, res));
      toastSuccess(
        lang === "id"
          ? `Reservasi Lounge telah dibatalkan`
          : "Lounge Reservation has been cancelled"
      );
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_BOOKING_ERROR, err.response));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const upload_payment_proof = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(POST_BOOKING_PENDING));
  API.put(UPLOAD_PAYMENT_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_BOOKING_SUCCESS, res));
      toastSuccess(
        lang === "id"
          ? "Berhasil Upload Bukti Pembayaran"
          : "Successfully Uploaded Payment Proof"
      );
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_BOOKING_ERROR));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const delPayment = (param, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_BOOKING_PENDING));
  API.delete(DELETE_PAYMENT_URL + `?order_id=${param.id}`)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_BOOKING_SUCCESS, res));
      toastSuccess(
        lang === "id"
          ? `Data pembayaran berhasil dihapus`
          : "Payment data has been deleted"
      );
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_BOOKING_ERROR));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const editBooker = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(PUT_BOOKING_PENDING));
  API.put(EDIT_BOOKER_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_BOOKING_SUCCESS, res));
      toastSuccess(
        lang === "id"
          ? "Berhasil Ubah Data Pemesan"
          : "Successfully Changed Booker Data"
      );
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_BOOKING_ERROR));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const editPassenger = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(PUT_BOOKING_PENDING));
  API.put(EDIT_PASSENGER_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_BOOKING_SUCCESS, res));
      toastSuccess(
        lang === "id"
          ? "Berhasil Ubah Data Penumpang"
          : "Successfully Changed Passenger Data"
      );
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_BOOKING_ERROR));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const rescheduleBooking = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(PUT_BOOKING_PENDING));
  API.put(RESCHEDULE_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_BOOKING_SUCCESS, res));
      toastSuccess(
        lang === "id"
          ? "Berhasil Ubah Schedule Booking"
          : "Successfully Updated Booking Schedule"
      );
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_BOOKING_ERROR, err.response));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const done = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(POST_BOOKING_PENDING));
  API.post(DONE_ORDER_URL + `?order_id=${param.order_id}`)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_BOOKING_SUCCESS, res));
      toastSuccess(
        lang === "id"
          ? `Reservasi Lounge telah diselesaikan`
          : "Lounge Reservation has been completed"
      );
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_BOOKING_ERROR, err.response));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const reservation_lounge = {
  get,
  post,
  calculate_price,
  del,
  upload_payment_proof,
  delPayment,
  editBooker,
  editPassenger,
  rescheduleBooking,
  done
};
export default reservation_lounge;

import { history } from "../../../utils/History";
import axios from "axios";
import { actionPending, actionSuccess, actionError } from "../actionTypes";

import { toastSuccess, toastError } from "../../../components/commons/toast";
import { getUser } from "../../../utils/User";
import { Role, RoleIash, RoleName } from "../../../utils/Constants";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const POST_AUTH_LOGIN_PENDING = "POST_AUTH_LOGIN_PENDING";
export const POST_AUTH_LOGIN_SUCCESS = "POST_AUTH_LOGIN_SUCCESS";
export const POST_AUTH_LOGIN_ERROR = "POST_AUTH_LOGIN_ERROR";
export const POST_AUTH_FORGOT_PENDING = "POST_AUTH_FORGOT_PENDING";
export const POST_AUTH_FORGOT_SUCCESS = "POST_AUTH_FORGOT_SUCCESS";
export const POST_AUTH_FORGOT_ERROR = "POST_AUTH_FORGOT_ERROR";

// URL: URL_{URL}
const AUTH_LOGIN_URL = `${process.env.REACT_APP_API_URL}v1/authentications/login-web`;
const AUTH_FORGOT_PASSWORD_URL = `${process.env.REACT_APP_API_URL}v1/authentications/forgot-password`;
const AUTH_RESET_PASSWORD_URL = `${process.env.REACT_APP_API_URL}v1/authentications/reset-password`;
const AUTH_FILL_PASSWORD_URL = `${process.env.REACT_APP_API_URL}v1/authentications/fill-password`;
const lang = localStorage.getItem('joumpa_language')

const auth_login = (param) => (dispatch) => {
  dispatch(actionPending(POST_AUTH_LOGIN_PENDING));
  axios
    .post(AUTH_LOGIN_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_AUTH_LOGIN_SUCCESS, res));
      localStorage.setItem("user_joumpa", JSON.stringify(res.data.data));
      localStorage.setItem(
        "just_login",
        RoleName[res.data.data.user.role_code]
      );
      localStorage.setItem("joumpa_language", 'en');

      let isAssistant = res.data.data.user.role_code === Role.Assistant;
      let isCustomer = res.data.data.user.role_code === Role.isCustomer;
      let isCorporate = res.data.data.user.role_code === Role.Corporate;
      let isSuperAdmin = res.data.data.user.role_code === Role.Superadmin;
      let isFinance = res.data.data.user.role_code === Role.Finance;
      let isSales = res.data.data.user.role_code === Role.Sales;
      let isSalesBandara = res.data.data.user.role_code === Role.Sales_bandara;
      let isFinanceBandara = res.data.data.user.role_code === Role.Finance_bandara;
      let isAdminIash = Object.values(RoleIash).includes(res.data.data.user.role_code);

      if (isAssistant) {
        (window.location.href = "/booking");
        // toastError("Silahkan login menggunakan Aplikasi Joumpa");
        // localStorage.removeItem("user_joumpa");
        // localStorage.removeItem("persist:root");
        // history.push(`/`);
      } else if (isCustomer) {
        toastError("Silahkan login menggunakan Aplikasi Joumpa");
        localStorage.removeItem("user_joumpa");
        localStorage.removeItem("persist:root");
        history.push(`/`);
      } else if (isCorporate) {
        (window.location.href = "/corp_reservation");
      } else if (isFinance || isSales || isFinanceBandara || isSalesBandara) {
        (window.location.href = "/dashboard");
      } else if (isAdminIash) {
        (window.location.href = "/lounge-reservation");
      } else {
        isSuperAdmin
          ? (window.location.href = "/dashboard-bigio")
          : (window.location.href = "/dashboard");
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_AUTH_LOGIN_ERROR));
      if (err?.response) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const logout = () => {
  if (getUser() && getUser() !== null) {
    localStorage.removeItem("user_joumpa");
    localStorage.removeItem("persist:root");
    history.push("/login");
  }
};

const forgotPassword = (param) => (dispatch) => {
  dispatch(actionPending(POST_AUTH_FORGOT_PENDING));
  axios
    .post(AUTH_FORGOT_PASSWORD_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      history.push("/");
      toastSuccess(
        "Silakan periksa email Anda untuk melakukan reset kata sandi"
      );
    })
    .catch((err) => {
      dispatch(actionError(POST_AUTH_FORGOT_ERROR));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const resetPassword = (param) => (dispatch) => {
  dispatch(actionPending(POST_AUTH_FORGOT_PENDING));
  axios
    .patch(AUTH_RESET_PASSWORD_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      history.push("/");
      toastSuccess(`Reset kata sandi berhasil, silahkan login kembali`);
    })
    .catch((err) => {
      dispatch(actionError(POST_AUTH_FORGOT_ERROR));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const fillPassword = (param, resolve, reject) => (dispatch) => {
  dispatch(actionPending(POST_AUTH_FORGOT_PENDING));
  axios
    .post(AUTH_FILL_PASSWORD_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_AUTH_FORGOT_SUCCESS, res));
      if (resolve) {
        let data = res.data.data;
        resolve({
          data: data,
        });
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_AUTH_FORGOT_ERROR, err?.response));
      if (reject) {
        reject(err?.response);
      }
    });
};

const Auth = {
  auth_login,
  logout,
  forgotPassword,
  resetPassword,
  fillPassword,
};
export default Auth;

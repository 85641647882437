import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux";
import moment from "moment";

import ModalFilter from "./ModalFilter";
import Table from "../../../components/table";
import TableStatus from "../../../components/table/status";
import Button from "../../../components/buttons/Button";
import { withTrans } from "../../../i18n/withTrans";

import ReportService from "../../../store/actions/report/";

const ReportProduction = ({ t, user, airports, products }) => {
  const dispatch = useDispatch();
  const [dataReport, setdataReport] = useState([]);
  const [totalData, setTotalData] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [haveAirport, setHaveAirport] = useState(
    user.role_code === "admin_bandara"
      ? true
      : user.role_code === "supervisor"
      ? true
      : user.role_code === "sales_bandara"
      ? true
      : user.role_code === "finance_bandara"
      ? true
      : false
  );
  const [paramFilter, setParamFilter] = useState({
    end_date: moment().format("YYYY-MM-DD"),
    start_date: moment().format("YYYY-MM-DD"),
    booking_end_date: moment().format("YYYY-MM-DD"),
    booking_start_date: moment().format("YYYY-MM-DD"),
    airport_id:
      user.role_code === "admin_bandara" ||
      user.role_code === "supervisor"
        ? user.airport_id
        : "",
    product_id: "",
    payment_status: "",
    payment_type: "",
    booking_type: "",
  });

  const handleFilterModal = () => {
    setShowModalFilter(true);
  };

  const onApplyFilter = (param) => {
    setParamFilter({
      start_date: param.start_date,
      end_date: param.end_date,
      booking_start_date: param.booking_start_date,
      booking_end_date: param.booking_end_date,
      airport_id: param.airport_id,
      payment_type: param.payment_type,
      payment_status: param.payment_status,
      product_id: param.product_id,
      booking_type: param.booking_type,
    });
  };

  const handleRefresh = () => {
    new Promise((resolve) => {
      const param = {
        ...paramFilter,
        page: page === 0 ? 1 : page ?? 1,
        length: 10,
      };
      dispatch(ReportService.getProduction(param, resolve));
    }).then((res) => {
      setdataReport(res?.data);
      setTotalData(res?.totalCount);
      setMaxPage(Math.ceil(res?.totalCount / 10));
    });
  };

  useEffect(() => {
    handleRefresh();
  }, [page, paramFilter]);

  useEffect(() => {
    setPage((prev) => (prev === null ? null : 1));
  }, [paramFilter]);

  const handleDownloadReport = () => {
    new Promise((resolve) => {
      setLoadingDownload(true);
      dispatch(ReportService.downloadProduction(paramFilter, resolve))
    }).then((res) => {
      setLoadingDownload(false);
    });
  };

  const numberWithCommas = (x) => {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return 0;
    }
  };

  
  const column = [
    {
      Header: "NO",
      accessor: "no",
    },
    {
      Header: t("report.bookingDate").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.booking_date ? moment(original?.booking_date)?.format("DD/MM/YYYY, HH:mm") : '-';
      },
    },
    {
      Header: t("report.serviceTime").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <>
            {original?.date && original?.time
              ? `${moment(original?.date)?.format("DD/MM/YYYY")} ${
                  original?.time
                }`
              : "-"}
          </>
        );
      },
    },
    {
      Header: "ID BOOKING",
      accessor: "booking_id",
    },
    {
      Header: t("report.agent").toUpperCase(),
      accessor: "agent",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return <>{original?.agent ?? "-"}</>;
      },
    },
    {
      Header: t("field.airport").toUpperCase(),
      accessor: "airport",
    },
    {
      Header: `${t("field.airport").toUpperCase()} ${t("field.cross_selling").toUpperCase()}`,
      textAlign: 'center',
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.airport_cross_selling ?? "-";
      },
    },
    {
      Header: t("field.product").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.product ? original?.product : "-";
      },
    },
    {
      Header: t("booking.paymentType").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.payment_type ?? "-";
      },
    },
    {
      Header: t("field.bookingType").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const booking_type = 
          original?.booking_type !== null ? 
            original?.booking_type === 'mobile'
              ? 'Mobile App'
              : original?.booking_type === 'web-admin'
              ? 'Web Admin'
              : original?.booking_type === 'web-public'
              ? 'Web Public'
              : 'API'
            : '-'
        return booking_type;
      },
    },
    {
      Header: "PAX",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const total_pax = parseInt(original?.adult_pax) + parseInt(original?.child_pax) + parseInt(original?.infant_pax);
        return total_pax !== undefined
          ? total_pax
          : "-";
      },
    },
    {
      Header: "Promo",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <>
            {"Rp " +
              (original?.promo
                ? numberWithCommas(original?.promo)
                : 0)}
          </>
        );
      },
    },
    {
      Header: "GRAND TOTAL",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <>
            {"Rp " +
              (original?.grand_total
                ? numberWithCommas(original?.grand_total)
                : 0)}
          </>
        );
      },
    },
    {
      Header: `${t("field.payment").toUpperCase()}`,
      Cell: ({ row }) => {
        const { original } = row;
        let { is_paid } = original;
        return (
          <TableStatus
            status={
              is_paid === false
                ? t("status.unpaid")
                : original?.corporate_id === null
                  ? t("status.paid")
                  : t("status.invoicePayment")
            }
            color={
              is_paid === false
                ? "danger"
                : "primary"
            }
          />
        );
      },
      minWidth: 110,
    },
  ];

  return (
    <>
      <div className="d-flex mb-4 justify-content-end">
        <Button
          title="Filter"
          size="sm"
          rounded={true}
          variant="button-light btn-lg"
          onClick={handleFilterModal}
        />
        <div className="nav-separator mr-3 ml-3" />
        <Button
          title={(
            <>
              {loadingDownload && (
                <span className="spinner-border spinner-border-sm mr-2"></span>
              )}
              {t("report.downloadReport")}
            </>
          )}
          leftIcon={!loadingDownload ? "download" : null}
          rounded={true}
          variant="button-light btn-lg"
          onClick={handleDownloadReport}
        />
      </div>
      <Table
        columns={column.filter((item) => item.show !== false)}
        data={dataReport ?? []}
        pagination={{ page, totalData, maxPage, toggle: setPage }}
      />
      
      <ModalFilter
        user={user}
        haveAirport={haveAirport}
        show={showModalFilter}
        toggle={() => setShowModalFilter(false)}
        filter={paramFilter}
        airport={airports}
        product={products}
        toggleFilter={() => {
          setParamFilter({});
          handleRefresh();
        }}
        onApplyFilter={onApplyFilter}
      />
    </>
  )
}

export default withTrans(ReportProduction)
import React, { useRef, useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
} from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import ButtonModal from "../../../components/modal/ButtonModal";
import { toastError, toastSuccess } from "../../../components/commons/toast";
import { withTrans } from "../../../i18n/withTrans";
import { getUser } from "../../../utils/User";

import Airport from "./tab/Airport";
import Lounge from "./tab/Lounge";
import DetailLounge from "./tab/DetailLounge";
import Shifts from "./tab/Shifts";
import DetailShift from "./tab/DetailShift";
import Transportation from "./tab/Transportation";
import Zone from "./tab/Zone";
import { EMAIL_FORMAT_REGEX } from "../../../utils/Constants";

const MasterDataModal = ({ t, show, toggle, action, data }) => {
  const currentUser = getUser();
  const formikRef = useRef();
  const [step, setStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [dataDetailZone, setDataDetailZone] = useState(null);
  const [dataDetailLounge, setDataDetailLounge] = useState(null);
  const [dataDetailShift, setDataDetailShift] = useState(null);
  const [indexZone, setIndexZone] = useState(null);
  const [indexLounge, setIndexLounge] = useState(null);
  const [indexCancelLounge, setIndexCancelLounge] = useState(null);
  const [indexShift, setIndexShift] = useState(null);
  const [indexCancelShift, setIndexCancelShift] = useState(null);
  const [indexCancel, setIndexCancel] = useState(null);
  const title =
    (data ? t("commons.edit") : t("commons.add")) + " " + t("field.airport");
  const initial = {
    id: data?.id ?? "",
    name: data?.name ?? "",
    code: data?.code ?? "",
    city: data?.city ?? "",
    phone: data?.phone ?? "",
    email: data?.email ?? "",
    ppn_percentage: data?.ppn_percentage ?? "",
    concession_percentage: data?.concession_percentage ?? "",
    lounges: data?.lounges ?? [],
    shifts: data?.shifts ?? [],
    transports: data?.transports?.map(item => ({
      ...item,
      region_list: item?.region_list?.split(',')
    })) ?? [],
  };

  const validation = {
    0: Yup.object().shape({
      // name: Yup.string().required(t("commons.required")).nullable(),
      // city: Yup.string().required(t("commons.required")).nullable(),
      // code: Yup.string().required(t("commons.required")).nullable(),
      // email: Yup.string()
      //   .email("Email yang Anda masukkan tidak valid")
      //   .required(t("commons.required"))
      //   .nullable(),
    }),
    1: Yup.object().shape({}),
    2: Yup.object().shape({}),
    zone: Yup.object().shape({}),
  };

  const steps = [
    t("field.airport"),
    "Shift",
    t("field.lounge"),
    t("field.transportation"),
  ];

  const handleStep = (step) => {
    if (step > 0) {
      formikRef.current.validateForm().then(() => {
        if (formikRef.current.isValid) {
          setStep(step);
        }
      });
    } else {
      setStep(step);
    }
  };

  const handleAddLounge = (formik, index) => {
    if (index !== null && index !== undefined) {
      setIndexLounge(index);
      setIndexCancelLounge(index);
    } else {
      let lounges = formik?.values?.lounges;
      formik?.setFieldValue("lounges", [
        ...lounges,
        {
          name: "",
          price_adult: "",
          price_child: "",
          price_infant: "",
        },
      ]);
      setIndexLounge(formik?.values?.lounges?.length);
      setIndexCancelLounge(null);
    }
    setStep("detailLounge");
  };

  const handleCancelLounge = (formik) => {
    let i = formik?.values?.lounges?.length - 1;
    if (
      (indexCancelLounge === null || indexCancelLounge === undefined) &&
      formik?.values?.lounges
    ) {
      let lounges = formik?.values?.lounges;
      lounges.splice(i, 1);
      formik?.setFieldValue("lounges", lounges);
    }
    setStep(2);
  };

  const handleDeleteLounge = (props) => {
    let lounges = props?.values?.lounges;
    lounges.splice(indexLounge, 1);
    props?.setFieldValue("lounges", lounges);
    setStep(2);
  };

  const handleConfirmLounge = (formik) => {
    let lounges = formik?.values?.lounges;

    if (
      lounges[indexLounge]?.name &&
      lounges[indexLounge]?.route_type
    ) {
      setStep(2);
    } else {
      toastError(t("commons.empty_data_message"));
    }
  };

  const handleAddShift = (formik, index) => {
    if (index !== null && index !== undefined) {
      setIndexShift(index);
      setIndexCancelShift(index);
    } else {
      let shifts = formik?.values?.shifts;
      formik?.setFieldValue("shifts", [
        ...shifts,
        {
          name: "",
        },
      ]);
      setIndexShift(formik?.values?.shifts?.length);
      setIndexCancelShift(null);
    }
    setStep("detailShift");
  };

  const handleCancelShift = (formik) => {
    let i = formik?.values?.shifts?.length - 1;
    if (
      (indexCancelShift === null || indexCancelShift === undefined) &&
      formik?.values?.shifts
    ) {
      let shifts = formik?.values?.shifts;
      shifts.splice(i, 1);
      formik?.setFieldValue("shifts", shifts);
    }
    setStep(1);
  };

  const handleDeleteShift = (props) => {
    let shifts = props?.values?.shifts;
    shifts.splice(indexShift, 1);
    props?.setFieldValue("shifts", shifts);
    setStep(1);
  };

  const handleConfirmShift = (formik) => {
    let shifts = formik?.values?.shifts;

    if (
      shifts[indexShift]?.name &&
      shifts[indexShift]?.start_time &&
      shifts[indexShift]?.end_time 
    ) {
      setStep(1);
    } else {
      toastError(t("commons.empty_data_message"));
    }
  };

  const handleAddZone = (formik, index) => {
    if (index !== null && index !== undefined) {
      setIndexZone(index);
      setIndexCancel(index);
    } else {
      let transports = formik?.values?.transports;
      formik?.setFieldValue("transports", [
        ...transports,
        {
          zone_name: "",
          region_list: "",
          prices: [{ car_name: "", price: "", capacity: "" }],
        },
      ]);
      setIndexZone(formik?.values?.transports?.length);
      setIndexCancel(null);
    }
    setStep("zone");
  };

  const handleCancelZone = (formik) => {
    let i = formik?.values?.transports?.length - 1;
    if (
      (indexCancel === null || indexCancel === undefined) &&
      formik?.values?.transports[i].prices
    ) {
      let transports = formik?.values?.transports;
      transports.splice(i, 1);
      formik?.setFieldValue("transports", transports);
    }
    setStep(3);
  };

  const handleDeleteZone = (props) => {
    let transports = props?.values?.transports;
    transports.splice(indexZone, 1);
    props?.setFieldValue("transports", transports);
    setStep(3);
  };

  const handleConfirmZone = (formik) => {
    let transports = formik.values.transports;
    let prices = formik.values.transports[indexZone].prices;

    if (
      transports[indexZone].zone_name &&
      transports[indexZone].region_list &&
      prices[prices.length - 1].car_name &&
      prices[prices.length - 1].price &&
      prices[prices.length - 1].capacity
    ) {
      setStep(3);
    } else if (!transports[indexZone].zone_name || !transports[indexZone].region_list) {
      toastError(t("commons.empty_data_message"));
    } else if (
      !prices[prices.length - 1].car_name ||
      !prices[prices.length - 1].price ||
      !prices[prices.length - 1].capacity
    ) {
      toastError(t("commons.delete_last_data_message"));
    } else {
      toastError(t("commons.empty_data_message"));
    }
  };

  const handleNextClick = (step) => {
    if (step === 0) {
      if (
        formikRef.current.values.name === "" ||
        formikRef.current.values.city === "" ||
        formikRef.current.values.code === "" ||
        formikRef.current.values.phone === ""||
        formikRef.current.values.email === "" ||
        formikRef.current.values.ppn_percentage === "" ||
        formikRef.current.values.concession_percentage === "" 
      ) {
        toastError(t("commons.empty_data_message"));
        setStep(0);
      } else if (
        formikRef?.current?.values?.email && 
        !EMAIL_FORMAT_REGEX.test(
          formikRef?.current?.values?.email
        )
      ) {
        toastError(`${t("commons.wrong_email_format")}!`);
        setStep(0);
      } else {
        setStep(1);
      }
    } 
    else {
      setStep(step < 3 ? step + 1 : 3);
    }
  };

  const handleClose = () => {
    setStep(0);
    toggle();
  };

  const handleBack = (step) => {
    if (step === 0) {
      if (toggle) {
        setStep(0);
        toggle();
      }
    } else {
      setStep(step - 1);
    }
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {title}{" "}
          <span className="close clickable" onClick={() => handleClose()}>
            &times;
          </span>
        </ModalHeader>
        <Formik
          innerRef={formikRef}
          initialValues={initial}
          validationSchema={validation[step.toString()]}
          onSubmit={ async (values) => {
            values?.transports?.map((item) => {
              const region_list = Array.isArray(item?.region_list);
              if (region_list) {
                return [
                  item.region_list = item?.region_list?.join(', ')
                ]
              }
            });

            let lounges = [];
            values?.lounges?.map((item) => {
              lounges?.push({
                id: item?.id,
                name: item?.name,
                route_type: item?.route_type,
                price_adult: Number(item?.price_adult),
                price_child: Number(item?.price_child),
                price_infant: Number(item?.price_infant),
              })
            });

            values.lounges = lounges;
            values.is_service = true;

            action(values);
            await delay(3000);
            setStep(0);
          }}
        >
          {(props, index) => (
            <Form>
              <ModalBody>
                <Container>
                  {(step !== "zone" && step !== "detailShift" && step !== "detailLounge") && (
                    <div className="mb-3">
                      <Stepper nonLinear activeStep={step} alternativeLabel>
                        {steps.map((label, index) => (
                          <Step key={label} completed={completed[index]}>
                            <StepButton
                              color="inherit"
                              onClick={() => handleStep(index)}
                            >
                              {label}
                            </StepButton>
                          </Step>
                        ))}
                      </Stepper>
                    </div>
                  )}
                  {step === 0 && <Airport formik={props} />}
                  {step === 1 && (
                    <Shifts 
                      formik={props}
                      add={() => handleAddShift(props)}
                      toggle={handleAddShift} 
                    />
                  )}
                  {step === "detailShift" && (
                    <DetailShift
                      formik={props}
                      idx={indexShift}
                      data={dataDetailShift}
                    />
                  )}
                  {step === 2 && (
                    <Lounge
                      formik={props}
                      add={() => handleAddLounge(props)}
                      toggle={handleAddLounge}
                    />
                  )}
                  {step === "detailLounge" && (
                    <DetailLounge
                      formik={props}
                      idx={indexLounge}
                      data={dataDetailLounge}
                    />
                  )}
                  {step === 3 && (
                    <Transportation
                      formik={props}
                      add={() => handleAddZone(props)}
                      toggle={handleAddZone}
                    />
                  )}
                  {step === "zone" && (
                    <Zone
                      formik={props}
                      idx={indexZone}
                      data={dataDetailZone}
                    />
                  )}
                </Container>
                {step === "detailShift" && (
                  <div style={{ margin: "50px 20px 0 20px" }}>
                    {indexShift === null ? (
                      <ButtonModal
                        toggle={() => handleCancelShift(props, index)}
                        confirm={() => handleConfirmShift(props)}
                        confirmTitle={t("commons.save") + " Shift"}
                        cancelTitle={t("commons.cancel")}
                        typeConfirm="Button"
                        disabledConfirm={props.isSubmitting}
                      />
                    ) : (
                      <ButtonModal
                        reset={() => handleDeleteShift(props)}
                        toggle={() => handleCancelShift(props, index)}
                        confirm={() => handleConfirmShift(props)}
                        resetTitle={t("commons.delete")}
                        confirmTitle={t("commons.save") + " Shift"}
                        cancelTitle={t("commons.cancel")}
                        typeConfirm="Button"
                        disabledConfirm={props.isSubmitting}
                      />
                    )}
                  </div>
                )}
                {step === "detailLounge" && (
                  <div style={{ margin: "50px 20px 0 20px" }}>
                    {currentUser?.user.role_code !== "admin_pusat" ? (
                      <ButtonModal
                        toggle={() => handleCancelLounge(props, index)}
                        hideConfirm
                        cancelTitle={t("commons.cancel")}
                        typeConfirm="Button"
                        disabledConfirm={props.isSubmitting}
                      />
                    ) : (
                      <ButtonModal
                        reset={() => handleDeleteLounge(props)}
                        toggle={() => handleCancelLounge(props, index)}
                        confirm={() => handleConfirmLounge(props)}
                        resetTitle={t("commons.delete")}
                        confirmTitle={t("commons.save") + " " + t("field.lounge")}
                        cancelTitle={t("commons.cancel")}
                        typeConfirm="Button"
                        disabledConfirm={props.isSubmitting}
                      />
                    )}
                  </div>
                )}
                {step === "zone" && (
                  <div style={{ margin: "50px 20px 0 20px" }}>
                    {currentUser?.user.role_code !== "admin_pusat" ? (
                      <ButtonModal
                        toggle={() => handleCancelZone(props, index)}
                        hideConfirm
                        cancelTitle={t("commons.cancel")}
                        typeConfirm="Button"
                        disabledConfirm={props.isSubmitting}
                      />
                    ) : (
                      <ButtonModal
                        reset={() => handleDeleteZone(props)}
                        toggle={() => handleCancelZone(props, index)}
                        confirm={() => handleConfirmZone(props)}
                        resetTitle={t("commons.delete")}
                        confirmTitle={t("commons.save") + " " + t("field.zone")}
                        cancelTitle={t("commons.cancel")}
                        typeConfirm="Button"
                        disabledConfirm={props.isSubmitting}
                      />
                    )}
                  </div>
                )}
              </ModalBody>
              <ModalFooter>
                {(step === 0 || step === 1 || step === 2) && (
                  <ButtonModal
                    toggle={() => handleBack(step)}
                    confirm={() => handleNextClick(step)}
                    confirmTitle={t("commons.next")}
                    cancelTitle={
                      step === 0 ? t("commons.cancel") : t("commons.back")
                    }
                    typeConfirm={"Button"}
                  />
                )}
                {step === 3 && (
                  <ButtonModal
                    toggle={() => handleBack(step)}
                    cancelTitle={t("commons.back")}
                    confirmTitle={t("commons.save")}
                    typeConfirm={"Submit"}
                    disabledConfirm={props.isSubmitting}
                  />
                )}
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default withTrans(MasterDataModal);

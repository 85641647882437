import React, { useState } from "react";
import { Formik, Form } from "formik";
import { withTrans } from "../../../i18n/withTrans";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Select2 from "../../../components/forms/Select2";
import ButtonModal from "../../../components/modal/ButtonModal";
import DynamicButton from "../../../components/forms/DynamicButton";
import { flightTypeList, routeTypeList } from "../../../utils/Constants";

function ModalFilter({
  t,
  show,
  toggle,
  airport,
  filter,
  toggleFilter,
  isDelete,
  onApplyFilter,
}) {
  const handleReset = (formik) => {
    formik.setFieldValue("route_type", "");
    formik.setFieldValue("flight_type", "");
    formik.setFieldValue("airport_id", "");
    toggleFilter({
      airport_id: "",
      route_type: "",
      flight_type: ""
    });
    toggle();
  };

  return (
    <>
      <Modal isOpen={show} modalTransition={{ timeout: 200 }}>
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          Filter{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <ModalBody style={{ margin: "0px 20px" }}>
          <Formik
            initialValues={{
              airport_id: filter?.airport_id ?? "",
              route_type: filter?.route_type ?? "",
              flight_type: filter?.flight_type ?? "",
            }}
            onSubmit={(values) => {
              onApplyFilter(
                {
                  airport_id: values.airport_id,
                  route_type: values.route_type,
                  flight_type: values.flight_type
                }
              );
              toggle();
            }}
          >
            {(props) => (
              <Form>
                <Select2
                  title={t("field.airport")}
                  name="airport_id"
                  options={[
                    {
                      value: "",
                      label: `${t("commons.all")} ${t("field.airport")}`,
                    },
                    ...airport,
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.airport_id}
                  onChange={(name, value) => {
                    props.setFieldValue("airport_id", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <Select2
                  title={t("field.routeType")}
                  name="route_type"
                  options={[
                    {
                      value: "",
                      label: `${t("commons.all")} ${t("field.routeType")}`,
                    },
                    ...routeTypeList(t)
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.route_type}
                  onChange={(name, value) => {
                    props.setFieldValue("route_type", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <Select2
                  title={t("field.flightType")}
                  name="flight_type"
                  options={[
                    {
                      value: "",
                      label: `${t("commons.all")} ${t("field.flightType")}`,
                    },
                    ...flightTypeList(t)
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.flight_type}
                  onChange={(name, value) => {
                    props.setFieldValue("flight_type", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <div style={{ marginTop: 50, marginBottom: 30 }}>
                  <ButtonModal
                    toggle={toggle}
                    reset={() => handleReset(props)}
                    deleted={isDelete}
                    confirmTitle={t("field.apply")}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
}

export default withTrans(ModalFilter);

import React from "react";
import { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import ReportAgent from "./reportAgent"
import ReportAPI from "./reportAPI"
import ReportAssistant from "./reportAssistant"
import ReportCommission from "./reportCommission"
import ReportCrossSelling from "./reportCrossSelling"
import ReportPax from "./reportPax"
import ReportProduction from "./reportProduction"
import ReportSales from "./reportSales"
import ReportCommissionLounge from "./reportCommissionLounge"
import HeaderMenu from "../../components/commons/menu/HeaderMenu";
import Loader from "../../components/commons/Loader";
import { withTrans } from "../../i18n/withTrans";
import { getUser } from "../../utils/User";

// Service
import AirportService from "../../store/actions/master_data/airport";
import CorporateService from "../../store/actions/master_data/corporate";
import ProductService from "../../store/actions/master_data/product";
import { Role } from "../../utils/Constants";

const Report = ({ t, pending }) => {
  const dispatch = useDispatch();
  const currentUser = getUser();
  const [dataProduct, setDataProduct] = useState([]);
  const [dataCorporate, setDataCorporate] = useState([]);
  const [activeTab, setActiveTab] = useState(
    currentUser?.user.role_code === "superadmin" ? "5" : "0"
  );
  const [dataAirport, setDataAirport] = useState([]);

  const handleAirport = () => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 9999,
        is_service: true,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      setDataAirport(res?.data);
    });
  };

  const handleProduct = () => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 9999,
      };
      dispatch(ProductService.get(param, resolve));
    }).then((res) => {
      setDataProduct(res?.data);
    });
  };

  const handleCorporate = () => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 9999,
      };
      dispatch(CorporateService.get(param, resolve));
    }).then((res) => {
      setDataCorporate(res?.data);
    });
  };

  useEffect(() => {
    handleAirport();
    handleProduct();
    handleCorporate();
  }, []);

  const airport_list = dataAirport?.map((airport) => ({
    value: airport?.id,
    label: `${airport?.code}-${airport?.city}-${airport?.name} ${
      airport?.uniform === "Indonesia" ? "" : "-" + airport?.uniform
    }`,
  }));

  const product_list = dataProduct?.map((product) => ({
    value: product?.id,
    label: `${product?.name_eng}`,
  }));

  const corporate_list = dataCorporate?.map((corporate) => ({
    value: corporate?.id,
    label: `${corporate?.name}`,
  }));

  return (
    <>
      {pending && <Loader fullScreen loading={pending} />}
      <HeaderMenu title={t("menuItem.report")}>
        <div className="tab-menu" style={{ maxWidth: '75vw' }}>
          {currentUser.user.role_code !== Role.Superadmin ? (
            <Tabs
              variant="scrollable"
              scrollButtons="auto"
              value={activeTab}
              textColor="primary"
              indicatorColor="primary"
              onChange={(event, newValue) => {
                setActiveTab(newValue);
              }}
              >
                <Tab label={t("report.agent")} value="0" />
                <Tab label={t("field.sales")} value="1" />
                <Tab label={t("field.assistant")} value="2" />
                <Tab label="PAX" value="3" />
                <Tab label={t("report.production")} value="4" />
                <Tab label="Cross Selling" value="6" />
                {currentUser?.user?.role_code === Role.Central_admin && (
                  <Tab label={t("field.commission")} value="5" />
                )}
                {currentUser?.user?.role_code === Role.Central_admin && (
                  <Tab label={`${t("field.commission")} Lounge`} value="8" />
                )}
                {currentUser?.user?.role_code === Role.Central_admin && (
                  <Tab label="API" value="7" />
                )}
            </Tabs>
          ): (
            <Tabs
              value={activeTab}
              textColor="primary"
              indicatorColor="primary"
              onChange={(event, newValue) => {
                setActiveTab(newValue);
              }}
              >
                <Tab label={t(t("field.commission"))} value="5" />
                <Tab label={`${t("field.commission")} Lounge`} value="8" />
            </Tabs>
          )}
        </div>
      </HeaderMenu>
      <hr />
      <div className="content-container-side">
        {activeTab === "0"
          ? <ReportAgent
              user={currentUser?.user}
              airports={airport_list}
              products={product_list}
              corporates={corporate_list}
            />
          : activeTab === "1"
          ? <ReportSales
              user={currentUser?.user}
              airports={airport_list}
              products={product_list}
              corporates={corporate_list}
            />
          : activeTab === "2"
          ? <ReportAssistant />
          : activeTab === "3"
          ? <ReportPax
              user={currentUser?.user}
              airports={airport_list}
              products={product_list}
              corporates={corporate_list}
            />
          : activeTab === "4"
          ? <ReportProduction
              user={currentUser?.user}
              airports={airport_list}
              products={product_list}
            />
          : activeTab === "5"
          ? <ReportCommission
              user={currentUser?.user}
              airports={airport_list}
              products={product_list}
              corporates={corporate_list}
            />
          : activeTab === "6"
          ? <ReportCrossSelling
              user={currentUser?.user}
              airports={airport_list}
              products={product_list}
            />
          : activeTab === "7"
          ? <ReportAPI
              user={currentUser?.user}
              airports={airport_list}
              products={product_list}
            />
          : <ReportCommissionLounge
              user={currentUser?.user}
              airports={airport_list}
              products={product_list}
            />
          }
      </div>
    </>
  );
};

const mapStateToProps = ({ report: { pending, data } }) => {
  return { pending, data };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(Report)
);

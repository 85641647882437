import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux";
import moment from "moment";

import HeaderMenu from "../../components/commons/menu/HeaderMenu";
import Table from "../../components/table";
import TableStatus from "../../components/table/status";
import Button from "../../components/buttons/Button";
import { withTrans } from "../../i18n/withTrans";
import { numberWithCommas } from "../../utils/Constants";
import ModalFilter from "./ModalFilter";

import ReportService from "../../store/actions/report/";
import AirportService from "../../store/actions/master_data/airport";
import MasterLoungeService from "../../store/actions/master_data/lounge";

const ReportLounge = ({ t }) => {
  const dispatch = useDispatch();
  const [dataAirport, setDataAirport] = useState([]);
  const [dataLounges, setDataLounges] = useState([]);
  const [searchLounge, setSearchLounge] = useState("");
  const [dataReport, setdataReport] = useState([]);
  const [totalData, setTotalData] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [paramFilter, setParamFilter] = useState({
    end_date: moment().format("YYYY-MM-DD"),
    start_date: moment().format("YYYY-MM-DD"),
    booking_end_date: moment().format("YYYY-MM-DD"),
    booking_start_date: moment().format("YYYY-MM-DD"),
    airport_id: "",
    lounge_id: "",
    booking_type: "",
  });

  const handleFilterModal = () => {
    setShowModalFilter(true);
  };

  const onApplyFilter = (param) => {
    setParamFilter({
      start_date: param.start_date,
      end_date: param.end_date,
      booking_start_date: param.booking_start_date,
      booking_end_date: param.booking_end_date,
      airport_id: param.airport_id,
      lounge_id: param.lounge_id,
      booking_type: param.booking_type,
    });
  };

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 9999,
        is_service: true,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      setDataAirport(
        res?.data?.map((airport) => ({
          value: airport?.id,
          label: `${airport?.code}-${airport?.city}-${airport?.name} ${
            airport?.uniform === "Indonesia" ? "" : "-" + airport?.uniform
          }`,
        }))
      );
    });
  }, []);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchLounge,
      };
      dispatch(MasterLoungeService.get(param, resolve));
    }).then((res) => {
      if (res) {
        setDataLounges(
          res?.data?.map((item) => ({
            label: item?.name,
            value: item?.id
          }))
        )
      }
    });
  }, [searchLounge]);

  const handleRefresh = () => {
    new Promise((resolve) => {
      const param = {
        ...paramFilter,
        page: page === 0 ? 1 : page ?? 1,
        length: 10,
      };
      dispatch(ReportService.getReportLounge(param, resolve));
    }).then((res) => {
      setdataReport(res?.data);
      setTotalData(res?.totalCount);
      setMaxPage(Math.ceil(res?.totalCount / 10));
    });
  };

  useEffect(() => {
    handleRefresh();
  }, [page, paramFilter]);

  useEffect(() => {
    setPage((prev) => (prev === null ? null : 1));
  }, [paramFilter]);

  const handleDownloadReport = () => {
    new Promise((resolve) => {
      setLoadingDownload(true);
      dispatch(ReportService.downloadLounge(paramFilter, resolve))
    }).then((res) => {
      setLoadingDownload(false);
    });
  };
  
  const column = [
    {
      Header: "NO",
      accessor: "no",
    },
    {
      Header: t("report.bookingDate").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.booking_date ? moment(original?.booking_date)?.format("DD/MM/YYYY, HH:mm") : '-';
      },
    },
    {
      Header: t("report.serviceTime").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <>
            {original?.date && original?.time
              ? `${moment(original?.date)?.format("DD/MM/YYYY")} ${
                  original?.time
                }`
              : "-"}
          </>
        );
      },
    },
    {
      Header: "ID BOOKING",
      accessor: "booking_id",
    },
    {
      Header: t("field.lounge").toUpperCase(),
      accessor: "lounge_name",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return <>{original?.lounge_name ?? "-"}</>;
      },
    },
    {
      Header: t("field.airport").toUpperCase(),
      accessor: "airport",
    },
    {
      Header: t("field.bookingType").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const booking_type = 
          original?.booking_type !== null ? 
            original?.booking_type === 'mobile'
              ? 'Mobile App'
              : original?.booking_type === 'web-public'
              ? 'Web Public'
              : 'Web Admin'
            : '-'
        return booking_type;
      },
    },
    {
      Header: "PAX",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const total_pax = parseInt(original?.adult_pax) + parseInt(original?.child_pax) + parseInt(original?.infant_pax);
        return total_pax !== undefined
          ? total_pax
          : "-";
      },
    },
    {
      Header: "PROMO",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <>
            {"Rp " +
              (original?.promo ? numberWithCommas(original?.promo) : 0)}
          </>
        );
      },
      minWidth: 120,
    },
    {
      Header: "GRAND TOTAL",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <>
            {"Rp " +
              (original?.grand_total
                ? numberWithCommas(original?.grand_total)
                : 0)}
          </>
        );
      },
      minWidth: 150,
    },
  ];

  return (
    <>
      <HeaderMenu title={t("menuItem.report")}>
        <Button
          title="Filter"
          size="sm"
          rounded={true}
          variant="button-light btn-lg"
          onClick={handleFilterModal}
        />
        <div className="nav-separator mr-3 ml-3" />
        <Button
          title={(
            <>
              {loadingDownload && (
                <span className="spinner-border spinner-border-sm mr-2"></span>
              )}
              {t("report.downloadReport")}
            </>
          )}
          leftIcon={!loadingDownload ? "download" : null}
          rounded={true}
          variant="button-light btn-lg"
          onClick={handleDownloadReport}
        />
      </HeaderMenu>
      <div className="content-container-side">
        <div className="overflow">
          <Table
            columns={column.filter((item) => item.show !== false)}
            data={dataReport ?? []}
            pagination={{ page, totalData, maxPage, toggle: setPage }}
          />
        </div>
      </div>
      
      <ModalFilter
        show={showModalFilter}
        toggle={() => setShowModalFilter(false)}
        setSearchLounge={(value) => setSearchLounge(value)}
        filter={paramFilter}
        airport={dataAirport}
        lounges={dataLounges}
        toggleFilter={() => {
          setParamFilter({});
          handleRefresh();
        }}
        onApplyFilter={onApplyFilter}
      />
    </>
  )
}

export default withTrans(ReportLounge)